import { SVGProps } from 'react';

const IconError404 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    enableBackground="new 0 0 48 48"
    viewBox="0 0 48 48"
    id="404-error"
  >
    <path
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      d="M43,48H5c-2.757,0-5-2.243-5-5V5c0-2.757,2.243-5,5-5h38c2.757,0,5,2.243,5,5v38C48,45.757,45.757,48,43,48z M5,2
		C3.346,2,2,3.346,2,5v38c0,1.654,1.346,3,3,3h38c1.654,0,3-1.346,3-3V5c0-1.654-1.346-3-3-3H5z"
    ></path>
    <path
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      d="M47 10H1c-.552 0-1-.447-1-1s.448-1 1-1h46c.552 0 1 .447 1 1S47.552 10 47 10zM6 6C5.87 6 5.74 5.97 5.62 5.92 5.5 5.87 5.39 5.8 5.29 5.71 5.2 5.609 5.13 5.5 5.08 5.38 5.03 5.26 5 5.13 5 5s.03-.26.08-.38C5.13 4.5 5.2 4.39 5.29 4.29 5.39 4.2 5.5 4.13 5.62 4.08c.37-.16.81-.07 1.09.21C6.8 4.39 6.87 4.5 6.92 4.62 6.97 4.74 7 4.87 7 5S6.97 5.26 6.92 5.38C6.87 5.5 6.8 5.609 6.71 5.71 6.61 5.8 6.5 5.87 6.38 5.92S6.13 6 6 6zM10 6C9.87 6 9.74 5.97 9.62 5.92 9.5 5.87 9.39 5.8 9.29 5.71 9.2 5.609 9.13 5.5 9.08 5.38 9.03 5.26 9 5.13 9 5s.03-.26.08-.38C9.13 4.5 9.2 4.39 9.29 4.29 9.39 4.2 9.5 4.13 9.62 4.08c.37-.16.81-.07 1.09.21.09.1.16.21.21.33C10.97 4.74 11 4.87 11 5s-.03.26-.08.38c-.05.12-.12.229-.21.33-.1.09-.21.16-.33.21S10.13 6 10 6zM36 35H12c-.552 0-1-.447-1-1s.448-1 1-1h24c.552 0 1 .447 1 1S36.552 35 36 35zM33 39H15c-.552 0-1-.447-1-1s.448-1 1-1h18c.552 0 1 .447 1 1S33.552 39 33 39zM25 31h-2c-1.654 0-3-1.346-3-3v-8c0-1.654 1.346-3 3-3h2c1.654 0 3 1.346 3 3v8C28 29.654 26.654 31 25 31zM23 19c-.551 0-1 .448-1 1v8c0 .552.449 1 1 1h2c.551 0 1-.448 1-1v-8c0-.552-.449-1-1-1H23zM17 27h-6c-.328 0-.635-.161-.822-.431-.187-.27-.229-.613-.115-.921l3-8c.195-.517.771-.775 1.288-.585.517.194.779.771.585 1.288L12.443 25H17c.552 0 1 .447 1 1S17.552 27 17 27z"
    ></path>
    <path
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      d="M16 31c-.552 0-1-.447-1-1v-8c0-.553.448-1 1-1s1 .447 1 1v8C17 30.553 16.552 31 16 31zM37 27h-6c-.328 0-.635-.161-.822-.431-.187-.27-.229-.613-.115-.921l3-8c.195-.517.771-.775 1.288-.585.517.194.779.771.585 1.288L32.443 25H37c.552 0 1 .447 1 1S37.552 27 37 27z"
    ></path>
    <path
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      d="M36,31c-0.552,0-1-0.447-1-1v-8c0-0.553,0.448-1,1-1s1,0.447,1,1v8C37,30.553,36.552,31,36,31z"
    ></path>
  </svg>
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     width={192}
  //     height={192}
  //     viewBox="0 0 24 24"
  //     id="error-404"
  //   >
  //     <path
  //       stroke={props.stroke ? props.stroke : '#4F4F4F'}
  //       d="M11.75 11h.5c.965 0 1.75-.785 1.75-1.75v-3.5C14 4.785 13.215 4 12.25 4h-.5C10.785 4 10 4.785 10 5.75v3.5c0 .965.785 1.75 1.75 1.75zm-.25-5.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v3.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-3.5zm3.5 1.5v-2.5a.75.75 0 0 1 1.5 0v2.5c0 .138.112.25.25.25h.75V4.75a.75.75 0 0 1 1.5 0v5.5a.75.75 0 0 1-1.5 0V9h-.75C15.785 9 15 8.215 15 7.25zm-10 0v-2.5a.75.75 0 0 1 1.5 0v2.5c0 .138.112.25.25.25h.75V4.75a.75.75 0 0 1 1.5 0v5.5a.75.75 0 0 1-1.5 0V9h-.75C5.785 9 5 8.215 5 7.25zM21.25 1H2.75A2.752 2.752 0 0 0 0 3.75v10.5A2.752 2.752 0 0 0 2.75 17h6v.25C8.75 18.215 7.965 19 7 19v.025c-1.674.13-3 1.518-3 3.225 0 .414.336.75.75.75h14.5a.75.75 0 0 0 .75-.75c0-1.706-1.326-3.095-3-3.225V19c-.965 0-1.75-.785-1.75-1.75V17h6A2.752 2.752 0 0 0 24 14.25V3.75A2.752 2.752 0 0 0 21.25 1zM2.75 2.5h18.5c.689 0 1.25.561 1.25 1.25v8.75h-21V3.75c0-.689.561-1.25 1.25-1.25zm15.581 19H5.669c.281-.591.884-1 1.581-1h9.5c.697 0 1.3.409 1.581 1zM14.27 19H9.73a3.22 3.22 0 0 0 .52-1.75V17h3.5v.25c0 .645.194 1.244.52 1.75zm6.98-3.5H2.75c-.689 0-1.25-.561-1.25-1.25V14h21v.25c0 .689-.561 1.25-1.25 1.25z"
  //     ></path>
  //   </svg>
);
export default IconError404;
