import { SVGProps } from 'react';

const IconTheme = (props: SVGProps<SVGSVGElement>) => (
  <svg
    height="24"
    width="24"
    version="1.1"
    id="_x32_"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    fill={props.fill}
    {...props}
  >
    <g>
      <path
        d="M133.977,245.743c4.662,1.718,9.756,2.564,15.006,2.315c7.873-0.355,15.159-3.118,21.05-7.512
		c5.9-4.4,10.456-10.425,13.043-17.428c1.73-4.668,2.564-9.759,2.319-15.007c-0.355-7.873-3.119-15.162-7.513-21.05
		c-4.398-5.894-10.432-10.457-17.431-13.052c-4.662-1.718-9.757-2.558-15-2.315c-7.877,0.361-15.162,3.118-21.056,7.513
		c-5.894,4.406-10.454,10.432-13.043,17.434c-1.73,4.662-2.565,9.76-2.319,15c0.358,7.88,3.118,15.162,7.516,21.057
		C120.944,238.585,126.981,243.154,133.977,245.743z M127.056,197.617c2.368-3.883,5.854-7.064,9.986-9.037
		c2.755-1.307,5.798-2.117,9.084-2.266c4.92-0.224,9.458,1.033,13.351,3.411c3.884,2.365,7.061,5.85,9.034,9.984
		c1.317,2.751,2.12,5.788,2.269,9.081c0.224,4.923-1.036,9.455-3.405,13.351c-2.372,3.89-5.856,7.058-9.99,9.038
		c-2.754,1.313-5.794,2.116-9.078,2.265c-4.926,0.224-9.464-1.045-13.36-3.404c-3.881-2.378-7.058-5.857-9.031-9.99
		c-1.316-2.752-2.119-5.795-2.268-9.075C123.427,206.045,124.688,201.508,127.056,197.617z"
      />
      <path
        d="M297.863,404.415c0.766,16.575,14.829,29.397,31.414,28.625c16.575-0.759,29.396-14.826,28.628-31.407
		c-0.766-16.582-14.83-29.403-31.408-28.631C309.916,373.767,297.094,387.828,297.863,404.415z"
      />
      <path
        d="M240.495,392.16c-14.873,0.691-26.375,13.308-25.687,28.183c0.688,14.875,13.307,26.378,28.18,25.687
		c14.872-0.691,26.375-13.307,25.687-28.177C267.987,402.977,255.367,391.476,240.495,392.16z"
      />
      <path
        d="M158.858,406.301c13.248-0.61,23.49-11.845,22.877-25.096c-0.612-13.245-11.847-23.49-25.096-22.873
		c-13.251,0.616-23.49,11.85-22.88,25.096C134.373,396.679,145.607,406.918,158.858,406.301z"
      />
      <path
        d="M100.317,314.906c0.479,10.363,9.265,18.367,19.631,17.888c10.357-0.473,18.377-9.261,17.898-19.624
		c-0.479-10.363-9.274-18.368-19.634-17.894C107.848,295.76,99.837,304.536,100.317,314.906z"
      />
      <path
        d="M281.761,259.872l-0.2-0.622l-30.75-21.686l-0.647,0.019c-15.48,0.342-31.808,10.052-42.604,25.369
		c-10.239,14.527-15.147,29.534-19.896,44.049c-3.24,9.896-6.588,20.141-11.623,30.224c-4.167,8.353-6.656,11.466-6.663,11.478
		l-2.549,3.025l2.813,1.189c13.083,5.521,31.846,4.021,51.471-4.089c20.617-8.515,38.758-22.843,51.075-40.308
		C282.99,293.202,286.653,274.567,281.761,259.872z M254.194,314.595c-0.564,0.454-1.161,0.946-1.777,1.506
		c-6.881,6.187-14.699,4.954-8.446-6.573c6.264-11.527-1.995-13.855-9.93-5.77c-9.952,10.139-13.239,3.541-10.995-1.631
		c2.238-5.16,8.947-12.698,4.559-16.4c-2.645-2.228-6.937,2.116-13.678,8.134c-3.858,3.454-11.782,3.554-6.909-10.114
		c2.363-5.154,5.138-10.182,8.608-15.112c7.529-10.686,18.981-17.913,29.926-18.909l26.335,18.56
		c2.744,10.644-0.221,23.851-7.758,34.55C261.227,306.932,257.888,310.879,254.194,314.595z"
      />
      <path
        d="M268.634,229.883l14.185,9.99c4.36,3.088,10.451,3.318,16.821,0.666c6.37-2.664,12.476-7.985,16.886-14.72
		l19.973-30.516l-43.905-30.965l-22.033,29.066c-4.871,6.417-7.824,13.955-8.192,20.851
		C262.002,221.151,264.273,226.802,268.634,229.883z"
      />
      <path
        d="M437.779,35.316c8.269-12.635,8.325-27.063,0.146-32.838l-0.108-0.062c-8.182-5.77-21.747-0.872-30.869,11.166
		L300.43,154.011l43.162,30.43L437.779,35.316z"
      />
      <path
        d="M486.476,270.708l-0.093-2.21c-0.754-16.319-3.79-30.809-9.022-43.071
		c-4.901-11.514-11.688-21.006-20.179-28.22c-5.256-4.481-11.11-8.017-17.399-10.5c-7.009-2.764-14.368-4.17-21.875-4.17
		c-0.902,0-1.792,0.019-2.689,0.062c-7.186,0.336-14.06,1.948-20.43,4.798c-6.551,2.95-12.131,7.008-16.582,12.069
		c-9.405,10.699-22.086,18.367-36.175,22.301l-9.508,14.521c-2.847,4.351-6.245,8.185-9.918,11.584
		c0.918-0.025,1.836-0.025,2.76-0.068c27.274-1.264,52.868-13.314,70.218-33.057c2.207-2.508,5.21-4.668,8.683-6.236
		c3.666-1.643,7.82-2.608,12.028-2.794l1.618-0.031c5.266,0,10.392,1.126,15.24,3.348c6.016,2.776,11.304,7.114,15.719,12.903
		c3.38,4.444,6.215,9.703,8.428,15.635c3.398,9.088,5.408,19.849,5.972,31.998l0.078,2.03c1.366,41.708-9.268,82.42-30.75,117.724
		c-21.056,34.562-51.231,61.992-87.266,79.314c-24.906,11.969-51.717,18.704-79.688,20.004c-3.262,0.149-6.508,0.224-9.735,0.224
		c-38.058,0-75.334-10.463-107.796-30.268c-34.513-21.056-61.946-51.393-79.336-87.742c-11.988-25.034-18.722-51.934-20.02-79.937
		c-2.094-45.56,10.643-89.46,36.834-126.96c25.155-36,62.068-64.146,103.934-79.252c2.312-0.834,5.141-1.357,7.955-1.475
		c0.516-0.025,1.039-0.038,1.565-0.038c3.283,0,6.601,0.486,9.585,1.407c3.622,1.096,6.847,2.838,9.33,5.041
		c1.734,1.544,3.125,3.293,4.258,5.353c1.702,3.156,2.633,6.803,2.848,11.16c0.142,3.124-0.109,6.541-0.757,10.158
		c-0.407,2.334-0.914,4.78-1.488,7.544c-0.554,2.67-1.136,5.478-1.665,8.44c-0.884,5.004-1.855,11.696-1.512,19.134
		c0.934,20.253,7.805,39.517,19.874,55.706c1.254,1.68,2.577,3.292,3.924,4.886c0.719-9.124,4.12-18.399,9.628-26.726
		c-0.93-1.631-1.976-3.187-2.788-4.892c-4.506-9.411-7.036-19.525-7.525-30.044c-0.187-3.996,0.19-8.446,1.189-14.017
		c0.426-2.44,0.949-4.973,1.565-7.93c0.532-2.57,1.086-5.259,1.587-8.06c0.94-5.291,1.304-10.425,1.08-15.28
		c-0.376-8.639-2.769-16.712-6.924-23.346c-3.759-6.056-9.146-11.191-15.573-14.845c-3.796-2.178-7.976-3.865-12.421-5.022
		c-4.624-1.195-9.435-1.805-14.3-1.805c-0.853,0-1.702,0.019-2.54,0.056c-5.262,0.256-10.226,1.208-14.751,2.839
		C134.696,79.894,94.915,110.237,66.625,150.7c-29.166,41.69-43.336,90.549-40.98,141.295
		c2.126,46.158,17.792,90.082,45.309,127.022c26.851,36.013,63.241,63.256,105.229,78.773c25.525,9.43,52.336,14.21,79.685,14.21
		c3.6,0,7.214-0.081,10.848-0.255c31.087-1.438,60.913-8.926,88.644-22.258c26.736-12.846,50.478-30.598,70.567-52.762
		C466.998,391.463,488.499,332.501,486.476,270.708z"
      />
    </g>
  </svg>
);

export default IconTheme;
