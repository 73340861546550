import { Dispatch, InputHTMLAttributes, useState } from 'react';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../contexts/ThemeProvider';
import { IconSearch } from '../../icons';
import { IconCalendar } from '../../icons/IconCalendar';
import { COLORS } from '../../imports/constants';
import Button from '../button/Button';
import Modal from '../layouts/Modal';

interface SearchbarHeader extends InputHTMLAttributes<HTMLInputElement> {
  handleFilter?: () => void;
  placeholder?: string;
  onChange: (event: any) => void;
  isOpen?: boolean;
  setIsOpen?: any;
  dateRange?: any;
  setDateRange?: any;
  setFilterType?: any;
  setSortingType?: any;
}

interface CalendarModalProps {
  dateRange: any;
  setDateRange: any;
  showCalendar: boolean;
  setShowCalendar: Dispatch<any>;
  setFilterType?: any;
  setSortingType?: any;
}

const CalendarModal = ({
  dateRange,
  setDateRange,
  showCalendar,
  setShowCalendar,
  setFilterType,
  setSortingType,
}: CalendarModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={showCalendar}
      onClose={() => {
        setShowCalendar(false);
      }}
      className={'mx-auto max-w-[90vw]'}
    >
      <div className="mx-auto flex flex-col items-center justify-center px-[25px] pb-[32px] pt-[47px]">
        <Calendar selectRange={true} value={dateRange} onChange={setDateRange} />
        <Button
          disabled={!dateRange}
          className="mt-[25px] w-full "
          type="secondary"
          action={() => {
            setShowCalendar(false);
            setFilterType('dateRange');
            setSortingType('dataRange');
          }}
        >
          {t('filter.filter_date')}
        </Button>
        <Button
          className="mt-[20px] w-full"
          action={() => {
            setDateRange('');
            setShowCalendar(false);
          }}
        >
          {t('filter.clear_filter')}
        </Button>
      </div>
    </Modal>
  );
};

export default function Searchbar(props: SearchbarHeader) {
  const [isFocused, setIsFocused] = useState(false);
  const { isThemeDark } = useTheme();
  const [showCalendar, setShowCalendar] = useState(false);

  const iconStroke = isThemeDark() ? COLORS.white : COLORS.black;
  const iconStrokeNotFocused = isThemeDark() ? COLORS.grey400 : COLORS.grey500;

  const {
    handleFilter,
    placeholder,
    onChange,
    isOpen,
    setIsOpen,
    dateRange,
    setDateRange,
    setSortingType,
    setFilterType,
  } = props;
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div
      className={`flex h-[43px] w-full items-center justify-between space-x-[6px] rounded-[10px] border border-black px-5 text-body-medium-18 transition-all duration-200 dark:border-none ${
        isOpen ? ' max-w-full' : 'max-w-[60px]'
      } ${
        isFocused
          ? 'bg-grey-300 text-black dark:bg-grey-500 dark:text-white'
          : 'bg-grey-200 text-grey-400 dark:bg-grey-600 dark:text-grey-400'
      }`}
      onClick={setIsOpen ? () => setIsOpen(true) : undefined}
    >
      <CalendarModal
        setSortingType={setSortingType}
        setFilterType={setFilterType}
        dateRange={dateRange}
        setDateRange={setDateRange}
        showCalendar={showCalendar}
        setShowCalendar={setShowCalendar}
      />
      <IconSearch width={22} height={22} stroke={isFocused ? iconStroke : iconStrokeNotFocused} />
      {isOpen && (
        <>
          <input
            placeholder={placeholder}
            onChange={onChange}
            className="w-full bg-[transparent] outline-none placeholder:text-grey-500 dark:placeholder:text-grey-400"
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {setIsOpen ? (
            <div>
              <IconCalendar
                width={22}
                height={22}
                stroke={isFocused ? iconStroke : iconStrokeNotFocused}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowCalendar(true);
                }}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
