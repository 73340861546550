import { createSlice } from '@reduxjs/toolkit';
import { Providers } from '../../imports/types';
export type UserState = {
  uid: string;
  name: string;
  email: string;
  address: string;
  wallet: {
    address: string;
    privateKey: string;
    mnemonic: string;
  };
  createdAt: string | null;
  role: 'guest' | 'consumer' | 'unverified';
  isEmailVerified: boolean;
  anonymous: boolean;
  redeem: {
    id: string;
    key: string;
  } | null;
  termsAndConditions: {
    marketing: boolean;
    termsAndCondition1: boolean;
    termsAndCondition2: boolean;
    messages?: boolean;
  };
  backup?: boolean;
  companyOptions: { [key: string]: { [key: string]: boolean } };
  needAction: string;
  share?: { driveFolderId: string; driveDocId: string; s: string; i: number };
  accessToken: string;
  ssoWallet?: string;
  // logOutAt?: number;
  lastNewNftCheck?: number;
  lastNonceUsed?: string;
  fcm: string;
  disabled: boolean;
  tutorials: { [key: string]: boolean };
  new?: boolean;
  provider?: Providers;
  welcomeTutorial: { step: number; page: string };
  completeTutorial: { step: number; page: string };
  tutorial: {
    active: boolean;
    name: string;
    step: number;
    page: string;
  };
};

const initialState: UserState = {
  uid: '',
  name: '',
  email: '',
  address: '',
  wallet: {
    address: '',
    privateKey: '',
    mnemonic: '',
  },
  createdAt: null,
  role: 'guest',
  isEmailVerified: false,
  anonymous: false,
  redeem: null,
  termsAndConditions: {
    marketing: false,
    termsAndCondition1: false,
    termsAndCondition2: false,
  },
  backup: false,
  companyOptions: {},
  needAction: 'unsetted',
  accessToken: '',
  fcm: '',
  disabled: false,
  tutorials: { home: false, nft: false, explore: false, profile: false },
  welcomeTutorial: { step: 0, page: '/home' },
  completeTutorial: { step: 0, page: '' },
  tutorial: {
    active: false,
    name: '',
    step: 0,
    page: '',
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signIn: (state, action) => {
      const { payload } = action;
      const { profile } = payload;

      state = {
        ...state,
        ...profile,
      };

      return state;
    },
    emailVerified: (state, action) => {
      const { payload } = action;
      const { role } = payload;

      state = {
        ...state,
        role,
        isEmailVerified: true,
      };

      return state;
    },
    pendingRedeem: (state, action) => {
      const { payload } = action;
      const { reset, id, key } = payload;

      if (reset) {
        state = {
          ...state,
          redeem: null,
        };
      } else {
        state = {
          ...state,
          redeem: { id, key },
        };
      }

      return state;
    },
    updateUserData: (state, action) => {
      const { payload } = action;
      const { profile } = payload;
      state = {
        ...state,
        ...profile,
      };

      return state;
    },
    setFCM: (state, action) => {
      const { payload } = action;
      const { fcm } = payload;
      state = {
        ...state,
        fcm,
      };

      return state;
    },
    setLastNonceUsed: (state, action) => {
      const { payload } = action;
      const { nonceValue } = payload;

      state = {
        ...state,
        lastNonceUsed: nonceValue,
      };

      return state;
    },
    logOut: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  signIn,
  logOut,
  emailVerified,
  pendingRedeem,
  updateUserData,
  setFCM,
  setLastNonceUsed,
} = userSlice.actions;
export default userSlice.reducer;
