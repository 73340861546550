import { LegacyRef, TextareaHTMLAttributes } from 'react';

interface Textarea extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  placeholder: string;
  formRef?: LegacyRef<HTMLInputElement>;
  inputRef?: any;
  error?: boolean;
  onCancel?: () => void;
  onChange?: any;
  disabled?: boolean;
  referenceName?: any;
  classNameDiv?: string;
  classNameBox?: string;
  classLabel?: string;
}

const Textarea = ({
  label,
  formRef,
  error,
  onCancel,
  onChange,
  disabled,
  placeholder,
  inputRef,
  referenceName,
  classNameDiv,
  classNameBox,
  classLabel,
  ...textareaProps
}: Textarea) => {
  return (
    <div
      className={`h-[150px] w-full rounded-[10px] border border-grey-600 bg-white px-[14px] transition-all duration-200 dark:bg-grey-600 ${classNameDiv} ${
        error ? 'shadow-inputError' : ''
      }`}
    >
      <label className={`mt-[8px] flex flex-col text-body-regular-12 ${classLabel} `}>
        {label}
        <textarea
          {...textareaProps}
          className={`h-[110px] resize-none overflow-auto bg-white text-body-medium-18 outline-none dark:bg-grey-600 ${classNameBox}`}
          placeholder={placeholder}
          ref={inputRef}
          name={referenceName}
          onChange={onChange}
        />
      </label>
    </div>
  );
};

export default Textarea;
