import { SVGProps } from 'react';
const IconDone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={49}
    height={48}
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1443_9930)">
      <path
        d="M41 13.5L20 34.5L9.5 24"
        stroke={props.stroke || '#118900'}
        strokeWidth={props.strokeWidth || 1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1443_9930">
        <rect width={48} height={48} fill={props.color || 'white'} transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
export default IconDone;
