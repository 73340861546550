import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Device } from '@capacitor/device';
import axios from 'axios';
import { ethers } from 'ethers';
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  linkWithCredential,
  OAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCredential,
  signOut,
  User,
} from 'firebase/auth';
import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  query,
  setDoc,
  startAt,
  updateDoc,
  where,
} from 'firebase/firestore';
import { t } from 'i18next';
import { ContractNft, ContractsNft, PublicCompany, UsersNft } from '../contexts/FirestoreContext';
import { APP_VERSION } from '../imports/constants';
import { auth, db } from '../imports/firebase';
import { Contract, Nft, Providers, Receipt } from '../imports/types';
import {
  convertOldWalletToNewWallet,
  createUserAuth,
  decryptWallet,
  encryptWallet,
  loginUser,
} from '../imports/utils/auth';
import { hashPassword } from '../imports/utils/crypto';
import {
  createShares,
  uploadFileToGoogleDrive,
  uploadFolderToGoogleDrive,
} from '../imports/utils/google';
import { findContractById, getFullNft } from '../imports/utils/nfts';
import { UserState } from '../redux/user/user.slice';

export type SignupFormProps = {
  fullName: string;
  email: string;
  password: string;
  // question: string;
  //answer: string;
  wallet: ethers.Wallet;
  termsAndCondition1: boolean;
  termsAndCondition2: boolean;
  marketing: boolean;
  underage: boolean;
  provider: Providers;
};

export const result = ({ value, error }: { value?: any; error?: any }) => ({ value, error });

export const signIn = async ({
  email,
  password,
  fcm,
}: {
  email: string;
  password: string;
  fcm: string;
}) => {
  try {
    const userCredentials = await loginUser(auth, email, password);
    const { user } = userCredentials;

    // Leave it without await, don't ask why
    // saveDevice({ token: await getIdToken(user), fcm, uid: user.uid });
    saveDevice({ fcm, uid: user.uid });

    const docRef = doc(db, 'users', user.uid);
    const docSnapshot = await getDoc(docRef);

    const { emailVerified } = user;
    let profile;

    if (docSnapshot.exists()) {
      profile = docSnapshot.data();
    }

    let wallet;

    try {
      wallet = decryptWallet(profile?.wallet, password);
    } catch (err) {
      if (profile?.wallet == null) throw new Error('No wallet');
      const newWallet = await convertOldWalletToNewWallet(profile.wallet, password);
      await setDoc(
        doc(db, 'users', user.uid),
        {
          wallet: newWallet,
        },
        { merge: true }
      );
    }

    return result({
      value: {
        profile: {
          ...profile,
          wallet: {
            address: wallet?.address,
            privateKey: wallet?.privateKey,
            mnemonic: wallet?.mnemonic.phrase,
          },
          isEmailVerified: emailVerified,
          provider: 'email',
        },
      },
    });
  } catch (error) {
    // TODO: why throw error instead of result()?
    throw error;
  }
};

export const updateUser = async (data: { [key: string]: any }, profile: any) => {
  try {
    const docRef = doc(db, 'users', profile.uid);

    await updateDoc(docRef, data);

    return result({
      value: {
        profile: {
          ...profile,
          ...data,
        },
      },
    });
  } catch (error) {
    return result({ error });
  }
};
/**
 * @description updates user's nft
 * @param refId `${nft?.contractId}-${nft?.tokenId}`
 * @param data
 * @param uid
 * @returns
 */
export const updateUserNft = async (
  refId: string,
  data: {
    [key: string]: any;
  },
  uid: string
) => {
  try {
    const docRef = doc(db, 'users', uid, 'nfts', refId);

    await setDoc(docRef, data, { merge: true });
  } catch (error) {
    return result({ error });
  }
};

export const updateNft = async (localNft: any, uid: string /* , nft: UsersNft */) => {
  try {
    const { _id, ...newNft } = localNft;
    const docRef = doc(db, 'users', uid, 'nfts', newNft.id);

    await setDoc(docRef, newNft, { merge: true });

    return result({
      value: {
        nft: {
          ...newNft,
        },
      },
    });
  } catch (error) {
    return result({ error });
  }
};

export const addNft = async (nft: any, id: any, uid: any) => {
  try {
    const ref = doc(collection(db, 'users', uid, 'nfts'), id);

    await setDoc(ref, nft, { merge: true });
  } catch (error) {
    return result({ error });
  }
};

export const addContract = async (contract: any, id: any) => {
  try {
    const ref = doc(collection(db, 'contracts'), id);

    await setDoc(ref, contract, { merge: true });
  } catch (error) {
    return result({ error });
  }
};

export const addNftToContract = async (nft: any, contractId: string) => {
  try {
    const ref = doc(
      collection(db, 'contracts', contractId, 'nfts'),
      nft.name.replaceAll(' ', '') + nft.id
    );

    await setDoc(ref, nft, { merge: true });
  } catch (error) {
    return result({ error });
  }
};

export const addCompany = async (company: any, companyId: string) => {
  try {
    const ref = doc(collection(db, 'publicCompany'), companyId);
    await setDoc(ref, company, { merge: true });
  } catch (error) {
    return result({ error });
  }
};

export const signUp = async ({
  fullName,
  email,
  password,
  // question,
  // answer,
  wallet,
  termsAndCondition1,
  termsAndCondition2,
  marketing,
  underage,
  provider,
}: SignupFormProps) => {
  try {
    const userCredentials = await createUserAuth(auth, email, password);
    const userMainWallet = encryptWallet(wallet, password);
    // const backupWallet = encryptWallet(wallet, answer);

    const { user } = userCredentials;

    await setDoc(doc(db, 'users', user.uid), {
      uid: user.uid,
      name: fullName,
      wallet: userMainWallet,
      address: wallet.address.toLocaleLowerCase(),
      createdAt: Date.now(),
      email: email.trim().toLowerCase(),
      termsAndConditions: {
        termsAndCondition1,
        termsAndCondition2,
        marketing,
      },
      underage,
      // new: false,
    });

    // await addDoc(collection(db, 'users', user.uid, 'backup'), {
    //   backupWallet,
    //   question,
    // });

    return result({ value: 'user registered' });
  } catch (error) {
    console.error(error);
    return result({ error });
  }
};

export const convertAuthProvider = ({
  email,
  password,
  fullName,
  mnemonic,
  termsAndCondition1,
  termsAndCondition2,
  marketing,
}: {
  email: string;
  password: string;
  fullName: string;
  mnemonic: string;
  termsAndCondition1: boolean;
  termsAndCondition2: boolean;
  marketing: boolean;
  needAction: string;
}) => {
  const hashedPassword = hashPassword(password, 'sha1');
  const hashedWalletPassword = hashPassword(password, 'sha256');
  const credential = EmailAuthProvider.credential(email, hashedPassword);

  if (auth?.currentUser) {
    return linkWithCredential(auth.currentUser, credential)
      .then(async (usercred) => {
        const { user } = usercred;

        try {
          // const wallet = ethers.Wallet.createRandom();
          // const { privateKey } = useAppSelector(({ user }) => user.wallet);

          const wallet = ethers.Wallet.fromMnemonic(mnemonic);

          const encryptedWallet = encryptWallet(wallet, hashedWalletPassword);

          const userData = {
            uid: user.uid,
            name: fullName,
            wallet: encryptedWallet,
            address: wallet.address.toLocaleLowerCase(),
            convertedOn: Date.now(),
            email,
            anonymous: false,
            termsAndConditions: {
              termsAndCondition1,
              termsAndCondition2,
              marketing,
            },
            needAction: '',
          };

          await updateDoc(doc(db, 'users', user.uid), {
            ...userData,
          });

          await sendEmailVerification(user);

          return result({
            value: {
              ...userData,
              wallet: {
                address: wallet?.address,
                privateKey: wallet?.privateKey,
                mnemonic: wallet?.mnemonic.phrase,
              },
              message: 'success',
            },
          });
        } catch (error) {
          return result({ error });
        }
      })
      .catch((error) => {
        console.error('Error upgrading anonymous account', error);
        return result({ error });
      });
  }

  return result({ error: 'authentication.convertion.no_auth' });
};

export const logOut = () =>
  signOut(auth)
    .then(() => result({ value: 'logged out' }))
    .catch((error) => result({ error }));

export const getUserDoc = async (uid: string) => {
  const docRef = doc(db, 'users', uid);
  const docSnapshot = await getDoc(docRef);

  let data;

  if (docSnapshot.exists()) {
    data = docSnapshot.data();
  }

  return result({ value: data });
};

// TODO: to be tested
export const resetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    //toast.success(i18n.t("reset_password.request_sent"), TOAST_CONFIG);

    return result({ value: 'success' });
    //   toast.success(i18n.t("signup.email_alert"), TOAST_CONFIG);
  } catch (error) {
    //   toast.error(i18n.t("reset_password.sending_request_error"), TOAST_CONFIG);
    return result({ error });
  }
  // setUser({ ...user, needrecover: true });
};

/**
 * Slices any array to arrays of {batchSize} length
 * @example arrayBatch( [1,2,3,4,5,6,7], 3 ) = [[1,2,3],[4,5,6],[7]]
 */
export const arrayBatch = <T>(array: T[], batchSize: number): T[][] => {
  const batches: T[][] = [];
  for (let i = 0; i < array.length; i += batchSize) {
    const chunk = array.slice(i, i + batchSize);
    batches.push(chunk);
  }
  return batches;
};

/**
 * Fetches all the contracts from the `contracts/` collection associated with a given list of NFTs
 * @notice `_id` is the snapshot id
 */
export const getContractsFromUsersNft = async (
  usersNfts: UsersNft[],
  contractsList: Contract[]
): Promise<Array<Contract & { _id: string }>> => {
  console.log('Fetching contracts');

  const uniqueContracts = Array.from(new Set(usersNfts.map((nft) => nft.contractId as string))); // returns an array of unique contractId by using the Set data structure
  const batches = arrayBatch(uniqueContracts, 10); // let's optimize by doing query in batches!
  const contracts = (
    await Promise.all(
      batches.map(async (batch) => {
        const contractRefs = await getDocs(
          query(collection(db, 'contracts'), where('id', 'in', batch))
        );

        return contractRefs.docs.map((doc) => {
          const currentContract = findContractById(contractsList, doc.id) || {};
          return { ...currentContract, _id: doc.id, ...(doc.data() as Contract) };
        });
      })
    )
  ).flat();

  return contracts;
};

/**
 * Fetches NFTs metadata from `contracts/{id}/nfts`
 * @param usersNfts
 * @param contracts
 * @notice `_id` is the snapshot id
 */
export const getNftsFromContract = async (
  usersNfts: UsersNft[],
  contracts: Contract[]
): Promise<ContractNft[]> => {
  console.log('Fetching NFTs');

  const contractNfts = await Promise.all(
    contracts.map(async (contract) => {
      const tokensInThisContract = usersNfts
        .filter((nft) => nft.contractId === contract.id)
        .map((nft) => nft.tokenId); // Let's optimize by querying only the tokens of a contract that we actually own

      const batches = arrayBatch(tokensInThisContract, 10); // then, slice them up, just in case we own more than 10 tokens per contract

      const nfts = (
        await Promise.all(
          batches.length
            ? batches.map(async (batch) => {
                const contractRefs = await getDocs(
                  query(collection(db, 'contracts', contract.id, 'nfts'), where('id', 'in', batch))
                );
                return contractRefs.docs.map(
                  (doc) => ({ _id: doc.id, ...doc.data() }) as ContractsNft
                );
              })
            : [''].map(async () => {
                const contractRefs = await getDocs(
                  query(collection(db, 'contracts', contract.id, 'nfts'))
                );
                return contractRefs.docs.map(
                  (doc) => ({ _id: doc.id, ...doc.data() }) as ContractsNft
                );
              })
        )
      ).flat();

      return { contractId: contract.id, nfts };
    })
  );

  return contractNfts;
};

export const getCompaniesFromContracts = async (
  contracts: Contract[]
): Promise<PublicCompany[]> => {
  console.log('Fetching companies =>', contracts.length);
  const uniqueOwners = Array.from(new Set(contracts.map((contract) => contract.owner)));
  const batches = arrayBatch(uniqueOwners, 10);
  const companies = (
    await Promise.all(
      batches.map(async (batch) => {
        const companyRef = await getDocs(
          query(collection(db, 'publicCompany'), where(documentId(), 'in', batch))
        );
        return companyRef.docs.map((doc) => ({ _id: doc.id, ...doc.data() }) as PublicCompany);
      })
    )
  ).flat();

  return companies;
};

/**
 *
 * @param companyAddress
 * @returns all contracts deployed by the company
 */
export const getContractsFromCompany = async (
  companyAddress: string
): Promise<(Contract & { _id: string })[]> => {
  const companyContracts = await getDocs(
    query(collection(db, 'contracts'), where('owner', '==', companyAddress))
  );
  return companyContracts.docs.map((contract) => {
    return { _id: contract.id, ...contract.data() };
  }) as (Contract & { _id: string })[];
};

/**
 * @description Function to sign in user with Google Profile
 *
 * @param {fcm} : Firebase Cloud Messaging Token
 * @returns User profile or error message
 */
export const signInWithGoogle = async ({ fcm }: { fcm: string }) => {
  // const provider = new GoogleAuthProvider();
  // provider.addScope('https://www.googleapis.com/auth/drive.file');

  // return signInWithPopup(auth, provider)
  try {
    const signInResult = await FirebaseAuthentication.signInWithGoogle({
      mode: 'popup',
      scopes: ['https://www.googleapis.com/auth/drive.file'],
    });

    // This gives you a Google Access Token. You can use it to access the Google API.
    // const credential = GoogleAuthProvider.credentialFromResult(signInResult);
    const { credential: googleCredential, additionalUserInfo, user } = signInResult;
    const { data } = await axios.get(
      `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${googleCredential?.accessToken}`
    );

    if (
      !((additionalUserInfo?.profile?.granted_scopes || data.scope) as string)?.includes(
        'https://www.googleapis.com/auth/drive.file'
      )
    ) {
      return result({ error: t('auth.errors.drive_access') });
    }

    const credential = GoogleAuthProvider.credential(googleCredential?.idToken);

    const { user: userCred } = await signInWithCredential(auth, credential);

    if (user) {
      // Working part

      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);

      saveDevice({ fcm, uid: user.uid });

      if (userDoc.exists()) {
        const profile = userDoc.data();
        // The wallet is not decrypted because we need the pin to decrypt it
        // It's still present encrypted in profile
        return result({
          value: {
            profile: {
              ...profile,
              isEmailVerified: true,
              needAction: profile.ssoWallet ? 'unlock' : 'conversion',
              accessToken: googleCredential?.accessToken,
              provider: 'google',
            },
          },
        });
      }

      const newProfile = {
        uid: user.uid,
        name: `${user.displayName?.split(' ')[0]}${Math.floor(Math.random() * 1000000)}`,
        // wallet: userMainWallet,
        // address: wallet.address.toLocaleLowerCase(),
        createdAt: Date.now(),
        email: user.email,
        termsAndConditions: {
          termsAndCondition1: true,
          termsAndCondition2: false,
          marketing: false,
        },
        provider: 'google',
      };

      await setDoc(userRef, newProfile);

      return result({
        value: {
          profile: {
            ...newProfile,
            needAction: 'pin',
            accessToken: googleCredential?.accessToken,
          },
        },
      });
    } else {
      return result({ error: 'user_not_exists' });
    }
  } catch (error: any) {
    console.error('ERROR ==>', error);
    return result({
      error: error.message.includes('12501:') ? t('auth.errors.user_cancel_signin') : error.message,
    });
  }
};

/**
 * @description Function to sign in user with Apple Profile
 *
 * @param {fcm} : Firebase Cloud Messaging Token
 * @returns User profile or error message
 */
export const signInWithApple = async ({ fcm }: { fcm: string }) => {
  // const provider = new GoogleAuthProvider();
  // provider.addScope('https://www.googleapis.com/auth/drive.file');

  // return signInWithPopup(auth, provider)
  try {
    const signInResult = await FirebaseAuthentication.signInWithApple({
      mode: 'popup',
      skipNativeAuth: true,
      // scopes: ['https://www.googleapis.com/auth/drive.file'],
    });

    // This gives you a Google Access Token. You can use it to access the Google API.
    // const credential = GoogleAuthProvider.credentialFromResult(signInResult);
    const { credential: googleCredential, additionalUserInfo } = signInResult;

    // const { data } = await axios.get(
    //   `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${googleCredential?.accessToken}`
    // );

    // if (
    //   !((additionalUserInfo?.profile?.granted_scopes || data.scope) as string)?.includes(
    //     'https://www.googleapis.com/auth/drive.file'
    //   )
    // ) {
    //   return result({ error: t('auth.errors.drive_access') });
    // }

    // const credential = GoogleAuthProvider.credential(googleCredential?.idToken);

    const appleProvider = new OAuthProvider('apple.com');
    const credential = appleProvider.credential({
      idToken: googleCredential?.idToken,
      rawNonce: googleCredential?.nonce,
      // accessToken: googleCredential?.accessToken,
    });

    const userCredentials = await signInWithCredential(auth, credential);
    const user = userCredentials.user;

    if (user) {
      // Working part

      saveDevice({ fcm, uid: user.uid });

      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const profile = userDoc.data();

        // The wallet is not decrypted because we need the pin to decrypt it
        // It's still present encrypted in profile
        return result({
          value: {
            profile: {
              ...profile,
              isEmailVerified: true,
              needAction: profile.ssoWallet ? 'unlock' : 'conversion',
              accessToken: credential?.accessToken,
              provider: 'apple',
            },
          },
        });
      }

      const newProfile = {
        uid: user.uid,
        name: `${user.displayName?.split(' ')[0]}${Math.floor(Math.random() * 1000000)}`,
        // wallet: userMainWallet,
        // address: wallet.address.toLocaleLowerCase(),
        createdAt: Date.now(),
        email: user.email,
        termsAndConditions: {
          termsAndCondition1: true,
          termsAndCondition2: false,
          marketing: false,
        },
        provider: 'apple',
      };

      await setDoc(userRef, newProfile);

      return result({
        value: {
          profile: {
            ...newProfile,
            needAction: 'pin',
            // accessToken: googleCredential?.accessToken,
          },
        },
      });
    } else {
      return result({ error: 'user_not_exists' });
    }
  } catch (error: any) {
    console.error('ERROR ==>', error);
    return result({
      error: error.message.includes('12501:') ? t('auth.errors.user_cancel_signin') : error.message,
    });
  }
};

export const completeSignIn = async ({
  pin,
  userWallet,
  user,
}: {
  pin: string;
  userWallet?: ethers.Wallet;
  user: UserState;
}) => {
  try {
    if (!userWallet) {
      userWallet = ethers.Wallet.createRandom();
    }
    const encryptedWallet = encryptWallet(userWallet, pin);

    let driveFolderId;
    let driveDocId;
    let shares: string[] = [];
    const isApple = auth.currentUser?.providerData[0].providerId === 'apple.com';

    if (!user.share && userWallet && !isApple) {
      // Remember the first element of shares array is always empty
      shares = createShares({ secret: userWallet.mnemonic.phrase, parts: 2, quorum: 2 });

      // Create wallet part to be upload to Google Drive
      driveFolderId = (
        await uploadFolderToGoogleDrive({
          accessToken: user?.accessToken || '',
        })
      ).id;

      driveDocId = (
        await uploadFileToGoogleDrive({
          accessToken: user?.accessToken || '',
          walletChunk: { s: shares[1], a: userWallet.address, i: 1 },
          walletName: `DO_NOT_DELETE_recover_wallet_${user.uid}`,
          folderId: driveFolderId,
        })
      ).id;
    }

    const newUserData = isApple
      ? {
          ssoWallet: encryptedWallet,
          address: userWallet.address.toLocaleLowerCase(),
        }
      : {
          ssoWallet: encryptedWallet,
          address: userWallet.address.toLocaleLowerCase(),
          share: { driveFolderId, driveDocId, s: shares[2], i: 2 },
        };

    if (auth?.currentUser) {
      await updateDoc(doc(db, 'users', auth.currentUser.uid), newUserData);

      return result({
        value: {
          profile: {
            wallet: {
              address: userWallet?.address,
              privateKey: userWallet?.privateKey,
              mnemonic: userWallet?.mnemonic.phrase,
            },
            needAction: '',
          },
        },
      });
    }
    //TODO: set error in i18n
    return result({ error: 'errors.auth/user-not-found' });
  } catch (error) {
    console.error(error);
    return result({ error });
  }
};

export const recoverDriveWallet = async ({ mnemonic, pin }: { mnemonic: string; pin: string }) => {
  try {
    const userWallet = ethers.Wallet.fromMnemonic(mnemonic);

    const encryptedWallet = encryptWallet(userWallet, pin);
    if (auth?.currentUser) {
      await updateDoc(doc(db, 'users', auth.currentUser.uid), {
        ssoWallet: encryptedWallet,
      });

      return result({
        value: {
          profile: {
            ssoWallet: encryptedWallet,
            wallet: {
              address: userWallet?.address,
              privateKey: userWallet?.privateKey,
              mnemonic: userWallet?.mnemonic.phrase,
            },
            needAction: '',
          },
        },
      });
    } else {
      return result({ error: 'no_current_user' });
    }
  } catch (error) {
    console.error(error);
    return result({ error });
  }
};

/**
 * @description Function that save new access device and fcm token, used to send push notifications
 * @param Object with token, token fcm and uid
 *
 * @dev Riccardo Grespan
 */
export const saveDevice = async ({
  token,
  fcm,
  uid,
}: {
  token?: string;
  fcm: string;
  uid: string;
}) => {
  // await axios.post(
  //   `${backendEndpoint}/registerDevice`,
  //   {
  //     fcm,
  //     lastLogin: Date.now(),
  //     version: APP_VERSION,
  //     deviceId: (await Device.getId()).identifier,
  //   },
  //   { headers: { Authorization: token } }
  // );

  await setDoc(doc(db, 'devices', uid), {
    devices: [
      {
        fcm,
        lastLogin: Date.now(),
        version: APP_VERSION,
        deviceId: (await Device.getId()).identifier,
      },
    ],
    uid,
  });
};

export const reauthenticateUser = async (user: User, password: string) => {
  try {
    const credentials = await createUserCredentials(user.email!, password);
    await reauthenticateWithCredential(user, credentials);

    return result({ value: 'success' });
  } catch (error) {
    return result({ error });
  }
};
export const createUserCredentials = async (email: string, password: string) => {
  const hashedPassword = hashPassword(password, 'sha1');
  const credentials = EmailAuthProvider.credential(email, hashedPassword);
  return credentials;
};

export const getSuggested = async () => {
  const suggested = await getDocs(
    query(collection(db, 'publicCompany'), orderBy('projects'), limitToLast(12))
  );
  return suggested.docs.map((doc) => doc.data());
};

export const getAllContracts = async (lastContractLoaded: number) => {
  const contracts = await getDocs(
    query(
      collection(db, 'contracts'),
      where('status', '==', 'created'),
      where('isPrivate', '==', false),
      orderBy('createdAt', 'desc'),
      startAt(lastContractLoaded - 1), // set default value to 2000000000
      limit(10)
    )
  );
  return contracts.docs.map((doc) => doc.data() as Contract);
};

export const getFilteredContracts = async (tagFilter: string) => {
  const filteredContracts = await getDocs(
    query(collection(db, 'contracts'), where('tag', 'array-contains', tagFilter))
  );
  return filteredContracts.docs.map((doc) => doc.data() as Contract);
};

/**
 * @description create CompleteContractNfts[] from userNfts and contracts
 * @param usersNfts
 * @param contracts
 * @returns completeContractNfts
 */
export const getContractsWithNfts = async (
  usersNfts: UsersNft[],
  contracts: Contract[]
): Promise<Contract[]> => {
  console.log('Fetching NFTs');

  const contractWithNfts = await Promise.all(
    contracts.map(async (contract) => {
      const tokensInThisContract = usersNfts
        .filter((nft) => nft.contractId === contract.id)
        .map((nft) => nft.tokenId);

      const batches = arrayBatch(tokensInThisContract, 10);

      const nfts = (
        await Promise.all(
          batches.length
            ? batches.map(async (batch) => {
                const contractNftsRefs = await getDocs(
                  query(collection(db, 'contracts', contract.id, 'nfts'), where('id', 'in', batch))
                );
                const nfts = contractNftsRefs.docs.map((doc) => {
                  const contractNft = { _id: doc.id, ...doc.data() } as ContractsNft;
                  const userNft = (usersNfts.find(
                    (uNft: UsersNft) =>
                      uNft.contractId === contract.id && uNft.tokenId === contractNft.id
                  ) || []) as UsersNft;
                  return getFullNft(contractNft, userNft);
                });
                return nfts;
              })
            : [''].map(async () => {
                const contractRefs = await getDocs(
                  query(collection(db, 'contracts', contract.id, 'nfts'))
                );
                const nfts = await Promise.all(
                  contractRefs.docs.map(async (contractNftDoc) => {
                    const contractNft = {
                      _id: contractNftDoc.id,
                      ...contractNftDoc.data(),
                    } as ContractsNft;
                    const userNftFromDb = (
                      await getDoc(
                        doc(
                          db,
                          `users/${auth?.currentUser?.uid}/nfts/${contract.id}-${contractNftDoc.id}`
                        )
                      )
                    ).data() as UsersNft;

                    return getFullNft(contractNft, userNftFromDb);
                  })
                );
                return nfts;
              })
        )
      ).flat();

      if (contract.nfts) {
        let newNfts: Nft[] = Object.assign([], contract.nfts);

        nfts.forEach((nft) => {
          const index = newNfts.findIndex(({ _id }: Nft) => _id === nft._id);
          if (index === -1) {
            newNfts.push(nft);
          } else {
            newNfts[index] = { ...newNfts[index], ...nft };
          }
        });
        contract.nfts = newNfts;
      } else {
        contract.nfts = nfts;
      }

      return contract;
    })
  );

  return contractWithNfts;
};

export const getCompanyById = async (companyId: string) => {
  return (await getDoc(doc(db, 'publicCompany', companyId))).data() as PublicCompany;
};

export const getCompanyByAddress = async (companyAddress: string) => {
  return await getDocs(
    query(collection(db, 'publicCompany'), where('address', '==', companyAddress))
  );
};

export const getContractById = async (contractId: string) => {
  return (await getDoc(doc(db, 'contracts', contractId))).data() as Contract;
};

export const getContractByAddress = async (contractAddress: string) => {
  return await await getDocs(
    query(collection(db, 'contracts'), where('address', '==', contractAddress))
  );
};

export const getNftFromContractByIds = async (contractId: string, tokenId: string) => {
  const nft = await getDocs(
    query(collection(db, 'contracts', contractId, 'nfts'), where('id', '==', parseInt(tokenId)))
  );

  return nft.docs.map((res) => res.data())[0] as Nft;
};

export const getUserByUid = async (uid: string) => {
  return (await getDoc(doc(db, 'users', uid))).data();
};

export const getReceiptByReceiptId = async (receiptId: string) => {
  const receiptSnap = await getDocs(
    query(collection(db, 'receipts'), where('receiptId', '==', receiptId))
  );
  return receiptSnap.docs.map((res) => res.data())[0] as Receipt;
};
