import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { IconAuthenticity, IconDoc, IconNotarizations, IconWarranty } from '../../icons';
import { COLORS } from '../../imports/constants';
import {
  AnalyticsData,
  AnalyticsFunctionSubtype,
  AnalyticsInfoSubtype,
  AnalyticsType,
  NftAttribute,
} from '../../imports/types';
import { formatUri } from '../../imports/utils/storage';
import AnalyticsWrapper from '../analytics/AnalyticsWrapper';
import DetailItem from '../detail-item/DetailItem';

type RenderAttributeType = {
  accessLink?: string;
  attribute: NftAttribute;
  isCustomAttribute?: boolean;
  lastUpdate?: number;
  getAnalyticsData?: (
    type: AnalyticsType,
    subtype: AnalyticsInfoSubtype | AnalyticsFunctionSubtype,
    name: string
  ) => AnalyticsData | undefined;
};

const RenderAttribute = ({
  attribute,
  isCustomAttribute,
  lastUpdate,
  getAnalyticsData = () => {
    return undefined;
  },
}: RenderAttributeType) => {
  const { user } = useContext(AuthContext);
  const [accessLink, setAccessLink] = useState('');
  // const [imageLink, setImageLink] = useState<string>('');
  const navigate = useNavigate();
  const { contractId, tokenId } = useParams();
  const {
    data: imageLink,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['imageLink', attribute.link],

    queryFn: async () => {
      return await formatUri(
        attribute.type === 'partner' ? (attribute.link as string) : (attribute.link as string),
        !!attribute.private,
        user
      );
    },
  });

  switch (attribute.type) {
    case 'warranty_certificate':
      return (
        <AnalyticsWrapper
          shouldAnalytics
          analyticsData={getAnalyticsData(
            AnalyticsType.INFO,
            AnalyticsInfoSubtype.DOCUMENT,
            attribute.trait_type
          )}
        >
          <button
            key={attribute.trait_type}
            className="flex h-full items-start gap-4"
            onClick={async () =>
              window.open(
                await formatUri(attribute.value as string, !!attribute.private, user),
                '_blank'
              )
            }
            style={{ maxWidth: '100%' }}
          >
            <div className="min-w-[37px]">
              <IconWarranty width={37} />
            </div>
            <div
              className="flex h-full min-h-[54px] w-full flex-col justify-evenly text-left text-body-medium-14"
              style={{
                maxWidth: '100%',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                whiteSpace: 'normal',
              }}
            >
              <p className="break-words text-left text-body-medium-14">{attribute.trait_type}</p>
              <div className="flex h-full w-full flex-row">
                <div className="break-words text-left text-body-regular-12">
                  {attribute.private ? `${t('nft.private')}` : `${t('nft.public')}`}
                </div>
                {lastUpdate && (
                  <div className="break-words text-left text-body-regular-12">
                    &nbsp;-&nbsp;{Intl.DateTimeFormat().format(lastUpdate)}
                  </div>
                )}
              </div>
            </div>
          </button>
        </AnalyticsWrapper>
      );
    case 'authenticity_certificate':
      return (
        <AnalyticsWrapper
          shouldAnalytics
          analyticsData={getAnalyticsData(
            AnalyticsType.INFO,
            AnalyticsInfoSubtype.DOCUMENT,
            attribute.trait_type
          )}
        >
          <button
            key={attribute.trait_type}
            className="flex h-full items-start gap-4"
            onClick={async () =>
              window.open(
                await formatUri(attribute.value as string, !!attribute.private, user),
                '_blank'
              )
            }
            style={{ maxWidth: '100%' }}
          >
            <div className="min-w-[37px]">
              <IconAuthenticity width={37} />
            </div>
            <div
              className="flex h-full min-h-[54px] w-full flex-col justify-evenly text-left text-body-medium-14"
              style={{
                maxWidth: '100vw',
                overflowX: 'auto',
                whiteSpace: 'nowrap',
              }}
            >
              <p className="break-words text-left text-body-medium-14">{attribute.trait_type}</p>
              <div className="flex h-full w-full flex-row">
                <div className="break-words text-left text-body-regular-12">
                  {attribute.private ? `${t('nft.private')}` : `${t('nft.public')}`}
                </div>
                {lastUpdate && (
                  <div className="break-words text-left text-body-regular-12">
                    &nbsp;-&nbsp;{Intl.DateTimeFormat().format(lastUpdate)}
                  </div>
                )}
              </div>
            </div>
          </button>
        </AnalyticsWrapper>
      );
    case 'document':
      return (
        <AnalyticsWrapper
          shouldAnalytics
          analyticsData={getAnalyticsData(
            AnalyticsType.INFO,
            AnalyticsInfoSubtype.DOCUMENT,
            attribute.trait_type
          )}
        >
          <button
            key={attribute.trait_type}
            className="flex h-full items-start gap-4"
            onClick={async () =>
              window.open(
                await formatUri(attribute.value as string, !!attribute.private, user),
                '_blank'
              )
            }
          >
            <div className="min-w-[37px]">
              <IconDoc width={37} />
            </div>
            <div
              className="flex h-full min-h-[54px] w-full flex-col justify-evenly text-left text-body-medium-14"
              style={{
                maxWidth: '100vw',
                overflowX: 'auto',
                whiteSpace: 'nowrap',
              }}
            >
              <p className="break-words text-left text-body-medium-14">{attribute.trait_type}</p>
              <div className="flex h-full w-full flex-row">
                <div className="break-words text-left text-body-regular-12">
                  {attribute.private ? `${t('nft.private')}` : `${t('nft.public')}`}
                </div>
                {lastUpdate && (
                  <div className="break-words text-left text-body-regular-12">
                    &nbsp;-&nbsp;{Intl.DateTimeFormat().format(lastUpdate)}
                  </div>
                )}
              </div>
            </div>
          </button>
        </AnalyticsWrapper>
      );
    case 'partner':
      const value = attribute?.value as string;
      return (
        <AnalyticsWrapper
          shouldAnalytics
          analyticsData={getAnalyticsData(AnalyticsType.INFO, AnalyticsInfoSubtype.PARTNER, value)}
        >
          <div className="flex flex-col">
            <a href={value.replace('{verify}', accessLink)} target="_blank" className="w-full">
              <img
                className="aspect-square max-h-[170px] w-full max-w-[375px] rounded-[20px] object-contain pr-1"
                src={imageLink as string}
              />
            </a>
            <p>{attribute.trait_type}</p>
          </div>
        </AnalyticsWrapper>
      );
    case 'image':
      return (
        <div className="flex w-full flex-col">
          {attribute.value && (
            <img
              className="mt-3 aspect-square max-h-[170px] w-full max-w-[375px] rounded-[20px] object-contain pr-1"
              src={imageLink as string}
            />
          )}
          <p>{attribute.trait_type}</p>
        </div>
      );
    case 'notarizations':
      return (
        <div
          key={attribute.trait_type}
          className="flex max-h-[37px] cursor-pointer items-center gap-4"
        >
          <button
            key={attribute.trait_type}
            className="flex h-[37px] items-start gap-4"
            onClick={() => {
              navigate(`/notarizations/${contractId}/${tokenId}`);
            }}
          >
            <div className="h-[37px] min-w-[37px] rounded-[6px] bg-white">
              <IconNotarizations width={37} height={37} stroke={COLORS.primary400} />
            </div>
            <div className="flex min-h-[37px] items-center">
              <div
                className="text-left text-body-medium-14"
                style={{
                  maxWidth: '100vw',
                  overflowX: 'auto',
                  whiteSpace: 'nowrap',
                }}
              >
                <p className="break-words text-left text-body-medium-14">{attribute.trait_type}</p>
              </div>
            </div>
          </button>
        </div>
      );
    case 'music':
      return (
        <audio
          controls
          controlsList="nodownload"
          src={attribute.value as string}
          className="mx-auto my-12"
        ></audio>
      );
    default:
      return (
        <DetailItem
          key={attribute.trait_type}
          left={attribute.trait_type}
          right={
            (attribute.value as string).startsWith('https')
              ? t('nft.go_to_link')
              : (attribute.value as string)
          }
          isCustomAttribute={
            isCustomAttribute || attribute.type === 'text' || attribute.type === undefined
          }
          link={
            (attribute.value as string).startsWith('https')
              ? (attribute.value as string)
              : undefined
          }
          className="min-h-fit w-full rounded-[10px] bg-grey-300 px-4 py-2 dark:bg-grey-500"
        />
      );
  }
};

export default RenderAttribute;
