import { format } from 'date-fns/format';

export const checkAge = (birthDate: any) => {
  if (
    birthDate.year !== undefined &&
    birthDate.month !== undefined &&
    birthDate.day !== undefined
  ) {
    const birth = new Date(birthDate.year, birthDate.month - 1, birthDate.day);

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const timeDifference = currentDate.getTime() - birth.getTime();
    const ageInMilliseconds = Math.abs(timeDifference);

    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);

    return ageInYears < 17.99;
  }
  return;
};

export const formatDate = (input: number): string => {
  const inputString = input.toString();
  if (inputString.length === 10) {
    const date = new Date(input * 1000);
    return format(date, 'dd/MM/yyyy').toString();
  }
  return format(new Date(input), 'dd/MM/yyyy').toString();
};
