import { Capacitor } from '@capacitor/core';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { PropsWithChildren, createContext, useEffect, useMemo, useReducer } from 'react';

interface MiscContextProps {
  pageY: number;
  insets: { top: number; bottom: number; left: number; right: number };
}

const initialValues: MiscContextProps = {
  pageY: 0,
  insets: { top: 0, bottom: 0, left: 0, right: 0 },
};
interface MiscContextFunctions {
  setPageY: (pageY: number) => void;
  setInsets: (insets: { top: number; bottom: number; left: number; right: number }) => void;
}
export interface MiscContextAction {
  type: `SET_${Uppercase<keyof MiscContextProps>}`;
  payload: Partial<MiscContextProps>;
}

export const MiscContext = createContext<MiscContextProps & MiscContextFunctions>({
  ...initialValues,
  setPageY: () => {},
  setInsets: () => {},
});

const reducer = (state: MiscContextProps, action: MiscContextAction): MiscContextProps => {
  switch (action.type) {
    case 'SET_PAGEY':
      return { ...state, pageY: action.payload.pageY ?? 0 };
    case 'SET_INSETS':
      return {
        ...state,
        insets: action.payload.insets ?? { top: 0, bottom: 0, left: 0, right: 0 },
      };
  }
};

export const MiscProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, initialValues);
  const value = useMemo(() => ({ ...state }), [state]);
  const setPageY = (pageY: number) => {
    dispatch({ type: 'SET_PAGEY', payload: { pageY } });
  };

  const setInsets = (insets: { top: number; bottom: number; left: number; right: number }) => {
    dispatch({ type: 'SET_INSETS', payload: { insets } });
  };

  const checkSafeArea = () => {
    SafeArea.getSafeAreaInsets().then(({ insets }: any) => {
      if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') {
        setInsets(insets);
      }
      // setInsets({ top: 59, bottom: 34, left: 0, right: 0 });
    });
  };

  useEffect(() => {
    checkSafeArea();
  }, []);

  return (
    <MiscContext.Provider value={{ ...value, setPageY, setInsets }}>
      {children}
    </MiscContext.Provider>
  );
};
