import { sendEmailVerification, User } from 'firebase/auth';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'react-simple-modal-provider';
import { getUserByUid } from '../api/firebase';
import { AuthContext } from '../contexts/AuthContext';
import { useAppSelector } from '../redux/hooks';

const useRedirect = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { redeem, needAction, uid } = useAppSelector((state) => state.user);

  const { open: openCreationModal } = useModal('SsoSignupModal');

  const resendEmail = (authUser: User) => {
    const lastEmailSent = localStorage.getItem('LastEmailSent');

    if (!lastEmailSent && !authUser.emailVerified) {
      sendEmailVerification(authUser);
      localStorage.setItem('LastEmailSent', Date.now().valueOf().toString());
      return;
    }

    const timePassedSinceLastTime = Date.now().valueOf() - parseInt(lastEmailSent!, 10);

    if (timePassedSinceLastTime > 3600000 && !authUser.emailVerified) {
      sendEmailVerification(authUser);
      localStorage.setItem('LastEmailSent', Date.now().valueOf().toString());
    }
  };

  const redirectIfNotEmailVerified = (authUser: User) => {
    const isNotVerified = !authUser?.emailVerified;
    if (isNotVerified) {
      const creationTime = authUser?.metadata.creationTime;
      if (creationTime) {
        const diffMs = new Date(Date.now()).valueOf() - new Date(creationTime).valueOf();

        if (diffMs > 86400000 && window.location.pathname !== '/verify-email') {
          navigate('/verify-email');
          return true;
        }
      }
    }
    return false;
  };

  const checkRedeem = () => {
    if (redeem && window.location.pathname !== '/redeem') {
      const { id, key } = redeem;
      navigate(`/redeem?id=${id}&key=${key}`);
      return true;
    }
    return false;
  };

  const checkTermsAndCond = async (user: User) => {
    const userDoc = await getUserByUid(user.uid);

    if (!userDoc?.termsAndConditions && userDoc?.ssoWallet) {
      navigate('/sso-signup');
      return true;
    }
    //  else if (!userDoc?.termsAndConditions) {
    // navigate('/tec');
    // return true;
    // }
    return false;
  };

  const check = async () => {
    if (!user) return true;
    if (user.isAnonymous) return true;
    resendEmail(user);
    if (redirectIfNotEmailVerified(user)) return false;
    if (await checkTermsAndCond(user)) return false;

    if ((!needAction || needAction === 'unlock') && uid) {
      checkRedeem();
    }
    return true;
  };

  return { check };
};

export default useRedirect;
