import { PropsWithChildren, useCallback } from 'react';
import { AnalyticsData } from '../../imports/types';
import { setClickAnalytics } from '../../imports/utils/analytics';

interface AnalyticsWrapperProps {
  shouldAnalytics?: boolean;
  analyticsData?: AnalyticsData;
}

const AnalyticsWrapper = ({
  shouldAnalytics = false,
  analyticsData,
  children,
}: PropsWithChildren<AnalyticsWrapperProps>) => {
  const debounce = (func: Function, delay: number) => {
    let timeoutId: NodeJS.Timeout;

    return (...args: any[]) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };
  const handleDebouncedClick = useCallback(
    debounce(() => {
      if (shouldAnalytics && analyticsData) {
        setClickAnalytics(analyticsData);
      }
    }, 500),
    [shouldAnalytics, analyticsData]
  );

  return <div onClick={handleDebouncedClick}>{children}</div>;
};

export default AnalyticsWrapper;
