import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import Chip from '../chip/Chip';

type FilterProps = {
  setSortingType: Dispatch<SetStateAction<any>>;
  sortingType: any;
  dateRange: any;
  setDateRange: Dispatch<SetStateAction<any>>;
  filterType: any;
  setFilterType: Dispatch<SetStateAction<any>>;
  filter: any;
  setFilter: Dispatch<SetStateAction<any>>;
  tags: string[];
  // settags: (cat: string) => void;
  addTag: (cat: string) => void;
  removeTag: (cat: string) => void;
  resetTags: () => void;
  availableTags: string[];
};

const Filters = ({
  setSortingType,
  sortingType,
  dateRange,
  setDateRange,
  filterType,
  setFilterType,
  filter,
  setFilter,
  tags,
  addTag,
  removeTag,
  resetTags,
  availableTags,
}: // settags,
FilterProps) => {
  const { t } = useTranslation();
  const nfts_categories = availableTags;
  const handleDateChip = () => {
    setFilterType('default');
    setDateRange('');
  };
  const dateOptions = { day: 'numeric', month: 'numeric', year: '2-digit' };
  return (
    <div className="relative mb-4 mt-1 flex items-center justify-between overflow-y-auto px-4">
      <div className="flex items-center pb-4">
        {dateRange && (
          <Chip
            label={
              dateRange
                ? `${dateRange[0].toLocaleString(
                    'it',
                    dateOptions
                  )} -> ${dateRange[1].toLocaleString('it', dateOptions)} `
                : t('filter.date')
            }
            action={handleDateChip}
            selected={sortingType === 'dateRange' || dateRange}
            name="dateRange"
            className="min-w-fit shrink-0"
          />
        )}
        {nfts_categories.map((category) => (
          <Chip
            key={category}
            label={category.toUpperCase()}
            className="min-w-fit dark:border-grey-600 dark:bg-grey-600"
            action={() => {
              tags.includes(category)
                ? (() => {
                    removeTag(category);
                    if (tags.length === 1) {
                      setFilterType('default');
                    }
                  })()
                : (() => {
                    setFilterType('category');
                    addTag(category);
                  })();
            }}
            selected={filterType === 'category' && tags.includes(category)}
          />
        ))}
      </div>
    </div>
  );
};
export default Filters;
