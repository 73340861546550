import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

type PieChartProps = {
  chartData: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string[];
    }[];
  };
};

const PieChart = ({ chartData }: PieChartProps) => {
  const options: any = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="flex h-52 w-52 items-center justify-center">
      <Pie data={chartData} options={options} />
    </div>
  );
};

export default PieChart;
