import { SVGProps } from 'react';

const IconScan = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.6249 4.6875H25.3124V9.37499"
      stroke={props.stroke || 'white'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.37496 25.3125H4.68747V20.625"
      stroke={props.stroke || 'white'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.3124 20.625V25.3125H20.6249"
      stroke={props.stroke || 'white'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.68748 9.37499V4.6875H9.37497"
      stroke={props.stroke || 'white'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.6874 9.375H10.3125C9.7947 9.375 9.37497 9.79473 9.37497 10.3125V19.6875C9.37497 20.2052 9.7947 20.625 10.3125 20.625H19.6874C20.2052 20.625 20.6249 20.2052 20.6249 19.6875V10.3125C20.6249 9.79473 20.2052 9.375 19.6874 9.375Z"
      stroke={props.stroke || 'white'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconScan;
