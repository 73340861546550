import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import NoInternetConnection from '../../pages/noInternetConnection/NoInternetConnection';

interface Layout extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  header?: ReactNode;
  paddingX?: boolean;
  paddingBottom?: boolean;
}

export default function Layout({
  paddingX = true,
  paddingBottom = true,
  header,
  ...props
}: Layout) {
  return (
    <NoInternetConnection>
      <>
        <div>{header}</div>
        <div
          {...props}
          className={`mx-auto w-full max-w-[768px] ${paddingX ? 'px-6' : ''} ${
            paddingBottom ? 'pb-bottomBar' : ''
          } ${props.className ?? ''}`}
        />
      </>
    </NoInternetConnection>
  );
}
