import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { formatIPFSUri } from '../../imports/utils/strings';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import ModalLayout from '../layouts/ModalLayout';

type ShowNftProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { img, aspect_ratio } = useModalProps('ShowNftModal');
  const { t } = useTranslation();
  const { uid } = useAppSelector(({ user }) => user);
  const dispatch = useAppDispatch();
  return (
    <ModalLayout onClose={() => setOpen(false)} className="max-w-[400px]">
      <div className="w-full p-4">
        <img
          className={`mx-auto ${
            aspect_ratio ? aspect_ratio : 'aspect-square'
          } w-full max-w-[390px] rounded-[20px] border border-black object-fill dark:border-none`}
          src={formatIPFSUri(img) || ''}
        />
      </div>
    </ModalLayout>
  );
};
const ShowNftModal = ({ children }: ShowNftProps) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="ShowNftModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default ShowNftModal;
