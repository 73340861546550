import { randomBytes } from 'crypto-browserify';
import { range } from 'lodash';
import { join, split } from 'shamir';

export const uploadFolderToGoogleDrive = ({ accessToken }: { accessToken: string }) => {
  const folderName = 'Bcode Wallet'; // Name of the folder to create
  const boundary = 'boundary'; // MIME boundary string

  const metadata = {
    name: folderName,
    mimeType: 'application/vnd.google-apps.folder',
  };

  const requestBody = `--${boundary}\r\nContent-Type: application/json; charset=UTF-8\r\n\r\n${JSON.stringify(
    metadata
  )}\r\n--${boundary}\r\nContent-Type: text/plain\r\nContent-Disposition: form-data; name="file"; --${boundary}--`;

  return fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': `multipart/related; boundary=${boundary}`,
    },
    body: requestBody,
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => console.error(error));
};

export const uploadFileToGoogleDrive = ({
  accessToken,
  walletChunk,
  walletName,
  folderId,
}: {
  accessToken: string;
  walletChunk: { [key: string]: string | number };
  walletName: string;
  folderId: string;
}) => {
  const metadata = `{"name": "${walletName}", "parents": ["${folderId}"]}`; // JSON file metadata
  const boundary = 'boundary'; // MIME boundary string

  const requestBody = `--${boundary}\r\nContent-Type: application/json; charset=UTF-8\r\n\r\n${metadata}\r\n--${boundary}\r\nContent-Type: application/json; charset=UTF-8\r\n\r\n${JSON.stringify(
    walletChunk
  )}\r\n--${boundary}--`;

  return fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': `multipart/related; boundary=${boundary}`,
    },
    body: requestBody,
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => console.error(error));
};

export const getFileFromGoogleDrive = ({
  fileId,
  accessToken,
}: {
  fileId: string;
  accessToken: string;
}) =>
  fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => response.json())
    .then((value) => ({ value, error: null }))
    .catch((error) => ({ value: null, error }));

export const createShares = ({
  secret,
  parts,
  quorum,
}: {
  secret: string;
  parts: number;
  quorum: number;
}): string[] => {
  const textEncoder = new TextEncoder();

  const secretBytes = textEncoder.encode(secret);

  // parts is a object whos keys are the part number and values are an Uint8Array
  const sharesBytes = split(randomBytes, parts, quorum, secretBytes);

  const shares: string[] = [];

  // It needs to start from 1 and leave the first one empty, otherwise it doesn't work
  for (const i of range(1, parts + 1)) {
    shares[i] = uint8ToHex(sharesBytes[i]);
  }

  return shares;
};

function uint8ToHex(uint8: Uint8Array) {
  let hex = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < uint8.length; i++) {
    let val = uint8[i].toString(16);
    if (val.length < 2) val = `0${val}`;
    hex += val;
  }
  return hex;
}
function hexToUint8(hex: string) {
  const uint8 = new Uint8Array(hex.length / 2);
  for (let i = 0; i < hex.length; i += 2) {
    uint8[i / 2] = parseInt(hex.substr(i, 2), 16);
  }
  return uint8;
}

export const recoverFromShares = (shares: string[]): string => {
  const utf8Decoder = new TextDecoder();

  const sharesBytes = shares.map((share) => hexToUint8(share));

  const recovered = join(sharesBytes);
  return utf8Decoder.decode(recovered);
};
