import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import companiesSlice from './companies/companies.slice';
import contractsSlice from './contracts/contracts.slice';
import gameSlice from './game/game.slice';
import pollSlice from './poll/poll.slice';
import userSlice from './user/user.slice';

// const replacer = (key: string, value: any) =>
//     // eslint-disable-next-line no-nested-ternary
//     value instanceof Date
//         ? value.toISOString()
//         : key === "wallet" && value
//         ? value.privateKey
//         : value;

// const reviver = (key: string, value: any) =>
//     // eslint-disable-next-line no-nested-ternary
//     typeof value === "string" &&
//     value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)
//         ? new Date(value)
//         : key === "wallet" && value
//         ? new ethers.Wallet(value)
//         : value;

// export const encode = (toDeshydrate: object) =>
//     JSON.stringify(toDeshydrate, replacer);
// export const decode = (toRehydrate: string) => JSON.parse(toRehydrate, reviver);

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [],
  whitelist: ['user', 'contracts', 'companies', 'poll'],
  // transforms: [createTransform(encode, decode)],
};

const rootReducer = combineReducers({
  user: userSlice,
  game: gameSlice,
  contracts: contractsSlice,
  companies: companiesSlice,
  poll: pollSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
