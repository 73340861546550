/* eslint-disable tailwindcss/no-custom-classname */
import './flipCard.css';

interface FlipCardProps {
  front: JSX.Element;
  back: JSX.Element;
  always: JSX.Element;
  flip: boolean;
  className?: string;
}

export default function FlipCard({ front, back, always, flip, className }: FlipCardProps) {
  // const [display, setDisplay] = useState(0); // 0 = front, 1 = back
  return (
    // <div className="flip">
    //   <div className="flip-content">
    //     <div className="flip-front">{front}</div>
    //     <div className="flip-back">{back}</div>
    //   </div>
    // </div>
    <div className={`relative mb-5 aspect-square items-center ${className}`}>
      {flip ? back : front}
      {always}
    </div>
  );
}
