import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { updateUserNft } from '../../api/firebase';
import { Nft } from '../../imports/types';
import { findNftFromContracts } from '../../imports/utils/nfts';
import { formatIPFSUri } from '../../imports/utils/strings';
import { updateContractNft, updateTransferState } from '../../redux/contracts/contracts.slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Button from '../button/Button';
import ModalLayout from '../layouts/ModalLayout';
type TransferNftConfirmation = {
  children?: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { uid, lastNewNftCheck, contractsList, transferState } = useAppSelector(
    ({ user, contracts }) => ({
      ...user,
      lastNewNftCheck: user.lastNewNftCheck || 0,
      contractsList: contracts.list,
      transferState: contracts.transferState,
    })
  );
  const { addedNfts, isSender } = useModalProps('TransferNftConfirmation');

  const [checked, setChecked] = useState<{ [key: string]: boolean }>({});
  const dispatch = useAppDispatch();
  const handleChecked = (nameid: string) => {
    setChecked({ ...checked, [nameid]: !checked[nameid] });
  };

  useEffect(() => {
    if (isSender) {
      dispatch(updateTransferState('success'));
    }
  }, [isSender]);

  // newNfts is found filtering contractsList for the nfts that are in the added nfts list
  const newNfts: Nft[] = useMemo(() => {
    const newNftsArray = [] as Nft[];
    addedNfts?.forEach((nft: Nft) => {
      if (nft.updatedAt > lastNewNftCheck) {
        const newNft = findNftFromContracts(contractsList, nft.contractId, nft.tokenId.toString());
        if (newNft) {
          newNftsArray.push(newNft);
        }
      }
    });
    return newNftsArray;
  }, [addedNfts]);

  const { t } = useTranslation();
  const addToUsersNfts = () => {
    newNfts &&
      newNfts.length > 0 &&
      newNfts?.forEach((nft: Nft) => {
        if (checked[nft.contractId + '-' + nft.tokenId]) {
          const refId = `${nft?.contractId}-${nft?.tokenId}`;
          updateUserNft(`${nft?.contractId}-${nft?.tokenId}`, { hide: true }, uid).then(
            (result) => {
              if (result?.error) {
                console.error(result.error);
              } else {
                dispatch(updateContractNft({ nftToUpdate: nft, newData: { hide: true } }));
              }
            }
          );
        }
      });
    setOpen(false);
  };

  const nftRender = (nft: Nft) => {
    return (
      <div key={`${nft?.contractId}-${nft?.tokenId}`}>
        <div className="flex justify-around gap-6">
          <div
            className={`mb-2 max-w-[70%] rounded-md border-4 ${
              checked[`${nft?.contractId}-${nft?.tokenId}`]
                ? 'rounded-md border-4 border-primary-500'
                : 'border-grey-50'
            }`}
          >
            <button onClick={() => handleChecked(`${nft?.contractId}-${nft?.tokenId}`)}>
              <div className="ml-1 text-left">{nft?.name}</div>

              {nft?.image ? (
                <img alt={nft.name} src={formatIPFSUri(nft?.image)} className="w-[300px] p-1" />
              ) : null}
            </button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <ModalLayout onClose={() => setOpen(false)} className="max-h-[70vh] max-w-[320px] p-7">
      <div className="flex-col overflow-y-auto overflow-x-hidden">
        <p className="mx-auto mb-2 text-center text-body-bold-16">
          {isSender
            ? t('TransferNftConfirmationModal.sender_title')
            : t('TransferNftConfirmationModal.title')}
        </p>
        <p className="mx-auto mb-2 text-center text-body-medium-14">
          {isSender
            ? t('TransferNftConfirmationModal.sender_subtitle')
            : t('TransferNftConfirmationModal.subtitle')}
        </p>
        {newNfts &&
          newNfts.length > 0 &&
          newNfts?.map((nft: Nft) => {
            return nft ? nftRender(nft) : null;
          })}
      </div>
      {newNfts?.length > 0 && !isSender ? (
        <Button action={addToUsersNfts} className="m-4 mt-7 max-w-[70%] p-3">
          {t('TransferNftConfirmationModal.button')}
        </Button>
      ) : null}
    </ModalLayout>
  );
};
const TransferNftConfirmation = ({ children }: TransferNftConfirmation) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="TransferNftConfirmation" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};
export default TransferNftConfirmation;
