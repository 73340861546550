import { InputHTMLAttributes, LegacyRef, useState } from 'react';
import { useTheme } from '../../contexts/ThemeProvider';
import { IconCancel, IconDone, IconEyeClose, IconEyeOpen, IconModify } from '../../icons';

interface Input extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  formRef?: LegacyRef<HTMLInputElement>;
  error?: boolean;
  errorMessage?: string;
  onCancel?: () => void;
  password?: boolean;
  disabled?: boolean;
  modify?: boolean;
  icon?: JSX.Element;
  noIcon?: boolean;
  notEye?: boolean;
  onChange?: any;
  forceLight?: boolean;
  cy?: string;
  onEdit?: any;
  onEditCancel?: any;
}
export default function Input(props: Input) {
  const a = 2;
  const {
    label,
    error,
    errorMessage,
    password,
    onCancel,
    className,
    type,
    formRef,
    noIcon,
    disabled,
    modify,
    icon,
    notEye,
    onChange,
    forceLight,
    cy,
    onEdit,
    onEditCancel,
    ...inputProps
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [inputType, setInputType] = useState<'password' | 'text' | 'number'>(
    password ? 'password' : 'text'
  );
  const [isDisabled, setIsDisabled] = useState(disabled);
  const { getBaseIconColor } = useTheme();

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    modify ? setIsDisabled(true) : setIsDisabled(false);
  };

  const togglePassword = () => {
    setInputType((oldType) => (oldType === 'password' ? 'text' : 'password'));
  };

  const handleModify = () => {
    isDisabled ? setIsFocused(true) : setIsFocused(false);
    setIsDisabled(!isDisabled);
  };
  const handleEdit = () => {
    onEdit(props.value);
    setIsDisabled(true);
    setIsFocused(false);
  };
  const handleEditCancel = () => {
    onEditCancel();
    setIsDisabled(true);
    setIsFocused(false);
  };
  const bgColor = forceLight ? 'bg-white' : 'bg-white dark:bg-grey-600';
  const labelColor = forceLight ? 'text-grey-500' : 'text-grey-500 dark:text-white';
  const placeholderColor = forceLight
    ? 'placeholder:text-grey-400'
    : 'placeholder:text-grey-400 dark:placeholder:text-grey-500';
  const textColor = forceLight ? 'text-black' : 'dark:text-white text-black';
  const iconColor = getBaseIconColor();

  return (
    <div className="flex flex-col">
      <div
        className={`box-border grid h-[56px] w-full grid-cols-12 rounded-[10px] border border-black px-[14px] transition-all duration-200 ${
          error ? ' shadow-inputError' : isFocused ? 'shadow-inputFocused' : ''
        } ${bgColor} ${className}`}
      >
        <div
          className={`${
            noIcon ? 'col-span-12' : 'col-span-11'
          } flex w-full flex-col items-start justify-center`}
        >
          <span className={`text-body-regular-12 ${labelColor}`}>{label}</span>
          <input
            data-cy={cy}
            {...inputProps}
            type={inputType}
            className={` w-full text-ellipsis bg-[transparent] text-body-medium-18 outline-none ${placeholderColor} ${textColor} `}
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={formRef}
            disabled={isDisabled}
            onChange={onChange}
          />
        </div>
        {!noIcon && (
          <div className="flex shrink-0 items-center justify-end">
            {!notEye &&
              (password && inputType === 'password' ? (
                <IconEyeClose
                  role="button"
                  aria-label="eye closed"
                  onClick={togglePassword}
                  color={iconColor}
                />
              ) : (
                password && (
                  <IconEyeOpen onClick={togglePassword} aria-label="eye open" color={iconColor} />
                )
              ))}
            {onCancel != null && (
              <IconCancel
                role="button"
                onClick={onCancel}
                aria-label="cancel button"
                color={iconColor}
              />
            )}
            {onEdit != null ? (
              isDisabled ? (
                <IconModify
                  className=" hover:cursor-pointer"
                  onClick={handleModify}
                  color={iconColor}
                />
              ) : (
                <div className="flex items-center justify-between gap-2">
                  <IconCancel
                    className="w-[20px] hover:cursor-pointer"
                    onClick={handleEditCancel}
                    color={iconColor}
                  />
                  <IconDone
                    className="w-[27px]  hover:cursor-pointer"
                    onClick={handleEdit}
                    color={iconColor}
                  />
                </div>
              )
            ) : (
              ''
            )}
            {icon}
          </div>
        )}
      </div>
      <p className="mb-[5px] mt-[2px] min-h-[13px] text-body-small-12 text-error">{errorMessage}</p>
    </div>
  );
}
