import { t } from 'i18next';
import * as yup from 'yup';

export const validateEmail = () => yup.string().email(t('form_validation.email_not_valid'));

export const validatePassword = () =>
  yup
    .string()
    .min(8, t('form_validation.pwd_error', { minChars: 8 }))
    .max(16, t('form_validation.pwd_error', { maxChars: 16 }))
    .matches(/\d/g, t('form_validation.pwd_error'))
    .matches(/[a-z]/g, t('form_validation.pwd_error'))
    .matches(/[A-Z]/g, t('form_validation.pwd_error'));
// yup
// .string()
// .min(8, t('form_validation.min_length', { minChars: 8 }))
// .max(16, t('form_validation.max_length', { maxChars: 16 }))
// .matches(/\d/g, t('form_validation.one_number'))
// .matches(/[a-z]/g, t('form_validation.one_letter_lowercase'))
// .matches(/[A-Z]/g, t('form_validation.one_letter_uppercase'));

export const validateRepeatPassword = (ref: string) =>
  yup
    .string()
    .oneOf([yup.ref(ref), null], t('form_validation.dont_match', { matchField: 'password' }));

export const validateRepeatEmail = (ref: string) =>
  yup
    .string()
    .oneOf([yup.ref(ref), null], t('form_validation.dont_match', { matchField: 'email' }));

export const required = (validation: yup.AnySchema) =>
  validation.required(t('form_validation.required'));

export const validateAddress = () => yup.string().matches(/^0x[a-fA-F0-9]{40}$/g);
