import { SVGProps } from 'react';

const IconProduct = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} viewBox="0 0 30 30" fill="none">
    <path d="M10 14H3" stroke={props.fill} strokeWidth="1.5" strokeLinecap="round" />
    <path d="M10 18H3" stroke={props.fill} strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M14 15L17.5 18L21 15"
      stroke={props.fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 6L13.5 6M20 6L17.75 6"
      stroke={props.fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M20 10L9.5 10M3 10H5.25" stroke={props.fill} strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default IconProduct;
