import { getApp, initializeApp } from 'firebase/app';

import { getAuth, indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getMessaging } from 'firebase/messaging';
import { getRemoteConfig } from 'firebase/remote-config';

import { PUSH_NOTIFICATION_TYPES, firebaseConfig as firebaseConfigEnvs } from './constants';

import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';

const firebaseConfig = firebaseConfigEnvs;

const app = initializeApp(firebaseConfig);

// const appCheck = initializeAppCheck(app, {
//     provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA as string),

//     // Optional argument. If true, the SDK automatically refreshes App Check
//     // tokens as needed.
//     isTokenAutoRefreshEnabled: true
//   });

export const auth = Capacitor.isNativePlatform()
  ? initializeAuth(getApp(), {
      persistence: indexedDBLocalPersistence,
    })
  : getAuth(app);

export const db = getFirestore(app);

export const database = getDatabase(app);

export let messaging: any;

export const remoteConfig = getRemoteConfig(app);

try {
  messaging = getMessaging(app);
} catch (err) {
  console.error('Failed to initialize Firebase Messaging', err);
}

export function pushNotificationInit({
  cb,
  redirect,
}: {
  cb: (value: string) => void;
  redirect: (value: string) => void;
}) {
  // Request permission to use push notifications
  // iOS will prompt user and return if they granted permission or not
  // Android will just grant without prompting

  PushNotifications.requestPermissions().then((result) => {
    if (result.receive === 'granted') {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
    } else {
      console.log(result.receive);
      // Show some error
    }
  });

  // On success, we should be able to receive notifications
  PushNotifications.addListener('registration', (token) => {
    if (cb) cb(token.value);
    // alert("Push registration success, token: " + token.value);
  });

  // Some issue with our setup and push will not work
  PushNotifications.addListener('registrationError', (error) => {
    alert('Error on registration: ' + JSON.stringify(error));
  });

  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener('pushNotificationReceived', (notification) => {
    const { data = {} } = notification || {};
    switch (data.type) {
      case PUSH_NOTIFICATION_TYPES.NEW_MESSAGE:
        if (data.senderAddress) {
          redirect(`/messages/${data.senderAddress}`);
        }
        break;
      case PUSH_NOTIFICATION_TYPES.MODIFIED_NFT:
        if (data.modifiedNft && data.contractId) {
          redirect(`/nfts/${data.contractId}/${data.modifiedNft}`);
        }
        break;
      default:
        redirect('/home/nfts');
    }

    // alert('Push received: ' + JSON.stringify(notification));
  });

  // Method called when tapping on a notification
  PushNotifications.addListener('pushNotificationActionPerformed', ({ notification }) => {
    const { data = {} } = notification || {};
    switch (data.type) {
      case PUSH_NOTIFICATION_TYPES.NEW_MESSAGE:
        if (data.senderAddress) {
          redirect(`/messages/${data.senderAddress}`);
        }
        break;
      case PUSH_NOTIFICATION_TYPES.MODIFIED_NFT:
        if (data.modifiedNft && data.contractId) {
          redirect(`/nfts/${data.contractId}/${data.modifiedNft}`);
        }
        break;
      default:
        redirect('/home/nfts');
    }
  });
}
