import { getCompanyByAddress, getContractByAddress } from '../../../api/firebase';

export const contractExistsOnDatabase = async (contractAddress: string) => {
  const contract = await getContractByAddress(contractAddress);

  if (contract && !contract.empty) {
    return contract.docs.map((res) => res.data());
  }

  return false;
};

export const companyExistsOnDatabase = async (companyAddress: string) => {
  const company = await getCompanyByAddress(companyAddress);

  if (company && !company.empty) {
    return true;
  }

  return false;
};
