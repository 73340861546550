import { Dispatch, ReactNode, SetStateAction } from 'react';

type ChipProps = {
  action?: () => void | Dispatch<SetStateAction<any>>;
  children?: ReactNode;
  className?: string;
  label: string | JSX.Element;
  selected?: boolean;
  name?: string;
};

const Chip = ({ action, children, className, label, selected }: ChipProps) => {
  /* const [selected, setSelected] = useState(false); */
  const handleSelected = () => {
    action?.();

    /* setSelected(!selected); */
  };

  return (
    <button
      onClick={handleSelected}
      /* check alphabetical order render tailwind */
      className={` ml-1 mr-3 flex h-[34px] min-w-[87px] items-center justify-center   rounded-[20px]  border-[1px]  px-4 font-sans text-body-regular-16 dark:border-white dark:bg-black dark:text-white  ${className} ${
        selected
          ? '!dark:bg-primary-500 !bg-primary-500'
          : '' /* added ! because it doesn't overwrite */
      }`}
    >
      {label}
    </button>
  );
};

export default Chip;
