import { GAME } from '../../imports/constants';
import { stopGame } from '../../redux/game/game.slice';
import { store } from '../../redux/store';
import { GameOverConfig } from '../configs/gameOverConfig';
import ChooseCharScene from './chooseCharScene';

export default class GameOverScene extends Phaser.Scene {
  private _levelConfig: GameOverConfig;

  private _backgroundLevels!: Phaser.GameObjects.TileSprite[];

  private _score: number = 0;
  private _config: object = {};

  private _scoreCounter: number = 1;

  private _scoreTimer!: Phaser.Time.TimerEvent;

  constructor(levelConfig: GameOverConfig = new GameOverConfig()) {
    super('GameOverScene');
    this._levelConfig = levelConfig;
  }

  init(config: any) {
    this._score = config.score;
    this._config = config.config;
  }

  preload() {
    const progressBox = this.add.graphics();
    const progressBar = this.add.graphics();

    const boxWidth = 320;
    const boxHeight = 50;
    const boxMargin = 10;
    const boxX = this.cameras.main.width / 2 - boxWidth / 2;
    const boxY = this.cameras.main.height / 2 - boxHeight / 2;

    this.load.on('start', () => {
      progressBox.fillStyle(0xffec00, 1);
      progressBox.fillRoundedRect(boxX, boxY, boxWidth, boxHeight, 24);
    });

    this.load.on('progress', (value: integer) => {
      progressBar.clear();
      progressBar.fillStyle(0x1dd7ff, 1);
      progressBar.fillRoundedRect(
        boxX + boxMargin,
        boxY + boxMargin,
        (boxWidth - boxMargin * 2) * value,
        boxHeight - boxMargin * 2,
        value < 0.1 ? 0 : 16
      );
    });

    this.load.on('complete', () => {
      progressBar.destroy();
      progressBox.destroy();

      this.cameras.main.setBackgroundColor(GAME.bgColor);
    });

    this.load.image('logoNFTF', '/assets/images/game/logo-violet.png');

    for (let i = 0; i < this._levelConfig.backgroundLevelsCount; i++) {
      const key = `background${i}`;
      if (this.textures.exists(key)) {
        this.textures.remove(key);
      }
      this.load.image(key, this._levelConfig.getBackgroundAsset(i));
    }
  }

  create() {
    const w = this.game.config.width as number;
    const h = this.game.config.height as number;

    this._backgroundLevels = [];
    for (let i = 0; i < this._levelConfig.backgroundLevelsCount; i++) {
      const bgLevel = this.add.tileSprite(w * 0.5, h * 0.5, w, h, `background${i}`).setDepth(-2);

      this._backgroundLevels.push(bgLevel);
    }

    const highScoreText = this.add
      .text(w / 2, 100, `YOUR HIGHSCORE: 0`, { fontFamily: 'Work Sans' })
      .setColor('white')
      .setScale(2)
      .setResolution(2)
      .setOrigin(0.5);

    this._scoreTimer = this.time.addEvent({
      delay: this._score < 1000 ? 5 : 2,
      callback: this._setHighScore,
      args: [highScoreText],
      callbackScope: this,
      loop: true,
    });

    const buttonWidth = 250;
    const buttonHeight = 100;

    const restartButtonX = this.cameras.main.width / 4 - buttonWidth / 2;
    const restartButtonY = this.cameras.main.height / 1.5 - buttonHeight / 2;

    const restartButton = this.add
      .graphics()
      .fillRoundedRect(restartButtonX, restartButtonY, buttonWidth, buttonHeight)
      .fillStyle(0xffffff, 0.5);

    const restartText = this.add
      .text(restartButtonX + buttonWidth / 2, restartButtonY + buttonHeight / 2, 'Try Again', {
        fontFamily: 'Work Sans',
      })
      .setScale(2)
      .setResolution(2)
      .setStroke('grey', 5)
      .setOrigin(0.5, 0.5)
      .setInteractive({ useHandCursor: true })
      .addListener(
        'pointerdown',
        () => {
          this._restartGame();
        },
        this
      )
      .addListener('pointerover', () => {
        restartText.setScale(2.2);
      })
      .addListener('pointerout', () => {
        restartText.setScale(2);
      });

    const exitButtonX = this.cameras.main.width - this.cameras.main.width / 4 - buttonWidth / 2;
    const exitButtonY = this.cameras.main.height / 1.5 - buttonHeight / 2;

    const exitButton = this.add
      .graphics()
      .fillRoundedRect(exitButtonX, exitButtonY, buttonWidth, buttonHeight)
      .fillStyle(0xffffff, 0.5);

    const exitText = this.add
      .text(exitButtonX + buttonWidth / 2, exitButtonY + buttonHeight / 2, 'Exit Game', {
        fontFamily: 'Work Sans',
      })
      .setScale(2)
      .setResolution(2)
      .setStroke('grey', 5)
      .setOrigin(0.5, 0.5)
      .setInteractive({ useHandCursor: true })
      .addListener('pointerdown', () => {
        this._exitGame();
      })
      .addListener('pointerover', () => {
        exitText.setScale(2.2);
      })
      .addListener('pointerout', () => {
        exitText.setScale(2);
      });

    this.add
      .image(w - 40, h - 70, 'logoNFTF')
      .setScale(0.2)
      .setOrigin(1, 0);
  }

  update() {
    if (this._scoreCounter > this._score) {
      this.time.removeEvent(this._scoreTimer);
    }
  }

  _setHighScore(text: Phaser.GameObjects.Text) {
    text.setText(`YOUR HIGHSCORE: ${this._scoreCounter}`);
    // eslint-disable-next-line no-plusplus
    this._scoreCounter++;
  }

  _restartGame() {
    this.time.removeAllEvents();
    this.scene.stop();
    this.scene.remove('ChooseCharScene');
    delete (this._config as { playerAsset?: string }).playerAsset;
    this.scene.add('ChooseCharScene', new ChooseCharScene(), true, this._config);
    this.scene.remove('GameOverScene');
  }

  _exitGame() {
    this.time.removeAllEvents();
    this.game.destroy(true);
    store.dispatch(stopGame());
  }
}
