import { SVGProps } from 'react';

const IconGoogleSSO = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_893_2265)">
      <path
        d="M10.5 10H17.375C17.3767 11.5927 16.8259 13.1367 15.8166 14.3688C14.8074 15.6009 13.402 16.4448 11.8402 16.7567C10.2783 17.0687 8.6566 16.8293 7.25149 16.0794C5.84637 15.3295 4.74482 14.1154 4.1346 12.6443C3.52437 11.1731 3.44324 9.53586 3.90504 8.01157C4.36684 6.48729 5.34298 5.17033 6.66707 4.28519C7.99116 3.40004 9.58125 3.0015 11.1663 3.15749C12.7513 3.31348 14.2332 4.01435 15.3594 5.14063"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_893_2265">
        <rect width={20} height={20} fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconGoogleSSO;
