import { SVGProps } from 'react';

const IconSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={33}
    height={33}
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.25 22.3434C19.6155 22.3434 22.3437 19.6152 22.3437 16.2497C22.3437 12.8842 19.6155 10.1559 16.25 10.1559C12.8845 10.1559 10.1562 12.8842 10.1562 16.2497C10.1562 19.6152 12.8845 22.3434 16.25 22.3434Z"
      stroke={props.stroke || 'white'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3213 8.2644C23.6429 8.56062 23.9476 8.86531 24.2353 9.17846L27.7012 9.67358C28.2659 10.6543 28.7011 11.7041 28.9961 12.7966L26.8887 15.6023C26.8887 15.6023 26.9267 16.4656 26.8887 16.8972L28.9961 19.7029C28.7025 20.7959 28.2672 21.8458 27.7012 22.8259L24.2353 23.321C24.2353 23.321 23.6387 23.9431 23.3213 24.2351L22.8262 27.7009C21.8454 28.2656 20.7957 28.7009 19.7031 28.9958L16.8975 26.8884C16.4667 26.9265 16.0333 26.9265 15.6025 26.8884L12.7969 28.9958C11.7038 28.7022 10.6539 28.2669 9.67382 27.7009L9.17871 24.2351C8.86556 23.9389 8.56087 23.6342 8.26465 23.321L4.79883 22.8259C4.23412 21.8452 3.79885 20.7954 3.50391 19.7029L5.61133 16.8972C5.61133 16.8972 5.57324 16.0339 5.61133 15.6023L3.50391 12.7966C3.79749 11.7036 4.23282 10.6537 4.79883 9.67358L8.26465 9.17846C8.56087 8.86531 8.86556 8.56062 9.17871 8.2644L9.67382 4.79858C10.6545 4.23387 11.7043 3.79861 12.7969 3.50366L15.6025 5.61108C16.0333 5.57299 16.4667 5.57299 16.8975 5.61108L19.7031 3.50366C20.7962 3.79724 21.8461 4.23258 22.8262 4.79858L23.3213 8.2644Z"
      stroke={props.stroke || 'white'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconSettings;
