import { SVGProps } from 'react';

const IconEyeClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_748_2568)">
      <path
        d="M4.5 4.25L19.5 20.75"
        stroke={props.color || 'white'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5215 15.275C13.8333 15.9054 12.9329 16.2535 11.9996 16.25C11.2427 16.2499 10.5036 16.0208 9.87935 15.5928C9.25512 15.1647 8.77502 14.5579 8.50216 13.8519C8.2293 13.1459 8.17646 12.3739 8.35058 11.6373C8.5247 10.9007 8.91764 10.2341 9.47773 9.72498"
        stroke={props.color || 'white'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.9375 6.93127C3.1125 8.86252 1.5 12.5 1.5 12.5C1.5 12.5 4.5 19.25 12 19.25C13.7574 19.2643 15.4929 18.8594 17.0625 18.0688"
        stroke={props.color || 'white'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5564 16.3531C21.6002 14.525 22.5002 12.5 22.5002 12.5C22.5002 12.5 19.5002 5.75002 12.0002 5.75002C11.35 5.74874 10.7008 5.80205 10.0596 5.9094"
        stroke={props.color || 'white'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7031 8.81567C13.5006 8.96678 14.2273 9.37317 14.7735 9.97353C15.3198 10.5739 15.6559 11.3356 15.7313 12.1438"
        stroke={props.color || 'white'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_748_2568">
        <rect width={24} height={24} fill={props.color || 'white'} transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconEyeClose;
