/* type SwitchProps = {
  label: string | JSX.Element;
  className?: string;
}; */
import { InputHTMLAttributes, LegacyRef, ReactNode } from 'react';

interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  label: ReactNode;
  className: string;
  formRef?: LegacyRef<HTMLInputElement>;
  error?: boolean;
  id?: string;
  cy?: string;
}
const Switch = ({ className, label, formRef, error, checked, cy, ...inputProps }: SwitchProps) => {
  return (
    <div className={`flex justify-center ${className}`}>
      <div className="flex justify-between">
        <label className="max-w-[80%] text-body-regular-16">{label}</label>
        <input
          {...inputProps}
          className={` mt-[10px] h-[24px] w-[48px] appearance-none rounded-[24px] bg-grey-400  before:absolute before:rounded-full before:bg-grey-50 before:content-[''] after:absolute after:z-[2]  after:ml-[2px] after:mt-[2px] after:h-[20px] after:w-[20px] after:rounded-full after:border-none after:bg-white after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary-500 checked:after:absolute checked:after:z-[2]  checked:after:ml-[26px] checked:after:mt-[2px] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-white checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[20px] focus:after:w-[20px] focus:after:rounded-full focus:after:content-[''] checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]`}
          type="checkbox"
          role="switch"
          ref={formRef}
          checked={checked}
          data-cy={cy}
        />
      </div>
    </div>
  );
};

export default Switch;
