import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useState } from 'react';
import { useTheme } from '../../contexts/ThemeProvider';
import { IconChevron } from '../../icons';

interface SelectProps {
  label: string;
  options: Array<{ id: string | number; label: string; short?: string }>;
  onChange: (id: string) => void;
  defaultId?: string | number;
  placeholder?: string;
  className?: string;
  icon?: JSX.Element;
  error?: boolean;
  cy?: string;
}

const SelectCustom = (props: SelectProps) => {
  const [open, setOpen] = useState(false);
  const [animate] = useAutoAnimate({ duration: 150 });
  const { getBaseIconColor } = useTheme();
  const [selected, setSelected] = useState<
    { id: string | number; label: string; short?: string } | undefined
  >(
    props.defaultId !== undefined
      ? props.options.find((opt) => opt.id === props.defaultId)
      : undefined
  );

  const iconColor = getBaseIconColor();

  return (
    <div
      role="listbox"
      className="relative w-full "
      tabIndex={0}
      ref={animate}
      onClick={() => setOpen((open) => !open)}
    >
      <div
        className={`flex h-[56px]  w-full cursor-pointer items-center  rounded-[10px] bg-grey-300 px-[14px] hover:bg-grey-400 dark:bg-grey-600 dark:hover:bg-grey-500 ${
          props.error ? 'shadow-inputError' : null
        } `}
        data-cy={props.cy}
      >
        <div className="flex w-full flex-col">
          <p className="text-body-regular-12">{props.label}</p>
          <p className="text-body-medium-18">{selected ? selected.short : props.placeholder}</p>
        </div>

        {props.icon || <IconChevron direction={open ? 'up' : 'down'} stroke={iconColor} />}
      </div>

      {open && (
        <div
          className={
            'absolute top-full mt-2 max-h-[220px] w-full overflow-scroll rounded-[10px] transition-shadow duration-200  '
          }
        >
          {props.options?.map((option, i) => (
            <div
              data-cy={props.cy + '-' + option.id}
              role="listitem"
              onClick={() => {
                setSelected(option);
                props.onChange(props.options[i].id.toString());
              }}
              key={option.id}
              tabIndex={0}
              className={`flex h-[56px] w-full cursor-pointer items-center  ${
                i !== props.options.length - 1 ? 'border-b border-white' : null
              }   bg-grey-300 px-[14px] text-body-medium-16 hover:bg-primary-500 dark:bg-grey-600`}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
      <p className="mt-6 px-[14px] text-body-medium-16">{selected ? selected.label : ''}</p>
    </div>
  );
};

export default SelectCustom;
