export default class ChooseCharConfig {
  backgroundLevelsCount: integer = 5;

  playerCount: integer = 2;

  gameVersionStr: string = '1.0.0';

  assetsFolder: string = '/assets/images/game/';

  getPlayerAsset(number: integer): string {
    return `${this.assetsFolder}players/atlas_player${number}.png`;
  }

  getBackgroundAsset(index: integer): string {
    return `${this.assetsFolder}set-one/background/${index}.png`;
  }
}
