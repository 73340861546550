import { SVGProps } from 'react';

const IconClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1015_4424)">
      <path
        d="M15.625 4.375L4.375 15.625"
        stroke={props.stroke || 'white'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.625 15.625L4.375 4.375"
        stroke={props.stroke || 'white'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1015_4424">
        <rect width={20} height={20} fill={props.stroke || 'white'} />
      </clipPath>
    </defs>
  </svg>
);

export default IconClose;
