import { User } from 'firebase/auth';

export type Nft = {
  _id: string;
  attributes: NftAttributes[];
  contractAddress: string | undefined;
  contractId: string;
  createdAt?: number;
  description: string;
  quantity?: number; //qta token
  external_url?: string;
  favorite?: boolean;
  hide?: boolean;
  image: string;
  quantityContract: number;
  quantityUser: number;
  mint: boolean;
  name: string;
  status: 'success' | 'failed' | 'pending';
  tokenId: number;
  transactionHash?: string;
  transfer?: boolean;
  transfersCount?: number;
  updatedAt: number;
  contractNftUpdatedAt: number;
  shop?: { id: string; value: string };
};

export type CompleteContractNfts = {
  contractId: string;
  nfts: Nft[];
};

export type NftAttributes = {
  img?: string | undefined;
  trait_type: string;
  value: string | NotarizationsValue[];
  type: FunctionalityType;
  link?: string;
  id: string;
  private: boolean;
};

export type FunctionalityType =
  | 'warranty_certificate'
  | 'authenticity_certificate'
  | 'document'
  | 'partner'
  | 'music'
  | 'shop'
  | 'poll'
  | 'news'
  | 'ecommerce'
  | 'product'
  | 'restricted_area'
  | 'notarizations'
  | 'text';

// collezione
export type Contract = {
  address: string;
  chain: 'polygon';
  image?: string;
  createdAt: number;
  description: string;
  contractExternalUrl?: string;
  expiration: number;
  expirationFrom: 'mint' | 'contract';
  id: string;
  maxSupplyPerRarity: number[];
  maxTokensPerUser: number;
  metadataUri: string;
  name: string;
  owner: string;
  qrCodeDrop: boolean;
  status?: 'new' | 'paid' | 'created';
  symbol?: string;
  tag?: string[];
  hasGame?: boolean;
  tokenBurn: boolean;
  transfer: 'creator' | 'none' | 'owners' | 'specificList' | 'creator&owner' | '';
  transferAddresses?: string[];
  transferOwnership: boolean;
  type: string;
  updateHistory: [];
  nfts: Nft[]; //categorie
  qrCodes: { id: string; key: string }[];
  transactionHash?: string;
  updatedAt: number;
};

export type NftTaker = Nft & {
  companyName: string;
  collectionName: string;
  companyId: string;
  contractId: string;
  companyImage: string;
  contractAddress: string;
  contractExpiration: number;
  contractExpirationFrom: 'mint' | 'contract';
  contractCreatedAt: number;
  contractOwner: string;
  contractTransfer: string;
  external_url: string;
  hasGame: boolean;
  status: 'success' | 'failed' | 'pending';
  requestId: string;
  transferStatus: 'success' | 'failed' | 'pending';
  transactionHash?: string;
  transferTransactionHash?: string;
  transfersCount?: number;
  transfer?: 'none' | 'owners' | 'creator' | 'creator&owner';
  quantityOfTheSameToken?: number;
  maxTokensPerUser?: number;
  contractChain?: Contract['chain'];
  contractHasGame?: Contract['hasGame'];
  companyBgColor?: string;
  companyTextColor?: string;
  updatedAt: number;
  createdAt: number;
};

export type Score = {
  coins: number;
  company: string;
  contract: string;
  diamonds: number;
  endTimestamp: number;
  gemeVersion: string;
  score: number;
  tokenId: number;
  user: string;
};

export type Answer = {
  aId: string;
  answer?: string;
  nextQuestion: string;
  hasOtherType?: boolean;
  type?: 'boolean' | 'string' | 'check';
};

export type Question = {
  qId: string;
  isFirst: boolean;
  question: string;
  minAnswers: number;
  maxAnswers: number;
  type: 'boolean' | 'string' | 'check';
  answers: Answer[];
};

export type PollType = {
  private: boolean;
  id: string;
  availableOn: { [key: string]: number[] };
  description: string;
  img?: string;
  title: string;
  body: string;
  startDate: number;
  endDate: number;
  minTotalAnswers: number;
  maxTotalAnswers: number;
  questions: Question[];
  owner?: string;
};

export type NftAttribute = {
  id: string;
  trait_type: string;
  value: string | ProductValue | NotarizationsValue[];
  type: string;
  link?: string;
  file?: (File & { preview?: string })[];
  private?: boolean;
  img?: string;
};
export type NftAttributeString = {
  id: string;
  trait_type: string;
  value: string;
  type: string;
  link?: string;
  file?: (File & { preview?: string })[];
  private?: boolean;
};

export type ProductValue = { name: string; description: string }[];

export type SignUpForm = {
  username: string;
  email: string;
  repeatEmail: string;
  password: string;
  repeatPassword: string;
  termsAndCondition1: boolean;
  termsAndCondition2: boolean;
  marketing: boolean;
  isAdult: boolean;
};

export type NotarizationsValue = {
  hash: string;
  txHash: string;
  name: string;
  id: string;
};

export type FAQQuestion = {
  answer: string;
  question: string;
};

export type FAQ = {
  common: FAQQuestion[];
  walletplace: FAQQuestion[];
  tokenCreator: FAQQuestion[];
};

export enum AnalyticsType {
  FUNCTION = 'evt_click_function',
  INFO = 'evt_click_info',
}

export enum AnalyticsFunctionSubtype {
  WEBSITE = 'evt_click_website',
  RESTRICTED_AREA = 'evt_click_restricted_area',
  E_COMMERCE = 'evt_click_e_commerce',
  PRODUCT = 'evt_click_product',
  NEWS = 'evt_click_news',
}

export enum AnalyticsInfoSubtype {
  LINK = 'evt_click_link',
  PARTNER = 'evt_click_partner',
  DOCUMENT = 'evt_click_document',
  WARRANTY = 'evt_click_warranty',
  AUTHENTICITY = 'evt_click_authenticity',
}

export type AnalyticsData = {
  companyId: string;
  type: AnalyticsType;
  subtype: string;
  contractId: string;
  nftId: string | number;
  user: User;
  name?: string;
};

// export type Notarization = {
//   id: string;
//   name: string;
//   date: number;
//   hash: string;
//   signature: string;
//   // status: NotarizationStatus;
//   merkleRoot: string;
//   merkleTreeURI?: string;
//   auditId?: string;
//   description?: string;
//   notes?: string;
//   // tags?: string[];
//   ipfsUri?: string;
//   externalUrl?: string;
//   receiptId?: string;
//   verified?: boolean;
//   userId: string;
//   folderId: string;
//   storageType?: string;
//   txHash?: string;
// };
enum NotarizationStatus {
  pending = 'pending',
  success = 'success',
  error = 'error',
}

export type StorageType = 'ipfs' | 'bcode' | 'nowhere' | '';

export type Notarization = {
  id: string;
  name: string;
  date: number;
  hash: string;
  signature: string;
  status?: NotarizationStatus;
  merkleRoot: string;
  merkleTreeURI?: string;
  auditId?: string;
  description?: string;
  notes?: string;
  tags?: string[];
  ipfsUri?: string;
  externalUrl?: string;
  receiptId?: string;
  verified?: boolean;
  userId: string;
  folderId: string;
  storageType: StorageType;
};

export type Receipt = {
  status: NotarizationStatus;
  txHash?: string;
  receiptId: string;
  date: number;
  tx?: NotarizationTransaction | null;
};
export type NotarizationTransaction = {
  from: string;
  to: string;
  transactionHash: string;
  blockHash: string;
  blockNumber: number;
  status: number;
};
export type Audit = {
  name: string;
  description: string;
  date: number;
  // verifiedNotarizations: DocumentReference[];
  // status: AuditStatus;
  userId: string;
  tags?: string[];
  model?: string;
  externalUrl?: string;
  auditorId?: string;
  transactionHash?: string;
  // notarizationRef?: DocumentReference;
  // tx?: AuditTransaction | null;
  merkleTreeURI?: string;
  workspace_id: string;
  auditId?: string;
};
export type Providers = 'google' | 'apple' | 'email';

export type TutorialSteps = {
  [key: string]: {
    [key: string]: {
      back: string;
      forward: string;
      icon?: React.ReactElement<any, any>;
    };
  };
};

export type TutorialNames = 'welcomeTutorial' | 'completeTutorial';
