import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Contract, Nft } from '../../imports/types';

export type contractsState = {
  list: Contract[];
  contractsIds: string[];
  exploreList: Contract[];
  exploreIds: string[];
  transferState?: 'success' | 'failed' | 'pending';
};

const initialState: contractsState = {
  list: [],
  contractsIds: [],
  exploreList: [],
  exploreIds: [],
  transferState: 'success',
};

export const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    loadContract: (state, action) => {
      state = {
        ...state,
        list: [...state.list, action.payload],
        contractsIds: [...state.contractsIds, action.payload.id],
      };
      return state;
    },
    loadContracts: (state, action) => {
      action.payload.forEach((contract: Contract) => {
        const contractIndex = state.list.findIndex(
          (existingContract) => existingContract.id === contract.id
        );
        if (contractIndex !== -1) {
          state.list[contractIndex] = { ...state.list[contractIndex], ...contract };
        } else {
          state.list.push(contract);
          state.contractsIds.push(contract.id);
        }
      });
    },
    updateContract: (state, action) => {
      const { id, updatedFields } = action.payload;
      const contractIndex = state.list.findIndex((existingContract) => existingContract.id === id);
      if (contractIndex !== -1) {
        state.list[contractIndex] = {
          ...state.list[contractIndex],
          ...updatedFields,
        };
      }
    },
    removeContract: (state, action) => {
      // const idToRemove= action.payload;
      const newContractList = action.payload;
      //const updatedcontract = state.list.filter((contract) => contract.id !== idToRemove);
      state.list = { ...newContractList };
      return state;
    },

    removeContractNft: (state, action: PayloadAction<{ contractId: string; tokenId: string }>) => {
      const { contractId, tokenId } = action.payload;
      const contractIndex = state.list.findIndex(
        (existingContract) => existingContract.id === contractId
      );
      if (contractIndex !== -1) {
        state.list[contractIndex] = {
          ...state.list[contractIndex],
          nfts: state.list[contractIndex].nfts.filter((nft) => nft.tokenId !== parseInt(tokenId)),
        };
      }
    },

    updateContractNft: (state, action: PayloadAction<{ nftToUpdate: Nft; newData: any }>) => {
      const { nftToUpdate, newData } = action.payload;
      const contractIndex = state.list.findIndex(
        (existingContract) => existingContract.id === nftToUpdate.contractId
      );

      if (contractIndex !== -1) {
        const newNfts = state.list[contractIndex].nfts.map((nft) => {
          if (nft.tokenId === nftToUpdate.tokenId) {
            return {
              ...nft,
              ...newData,
            };
          }
          return nft;
        });
        state.list[contractIndex] = {
          ...state.list[contractIndex],
          nfts: newNfts,
        };
      }
    },

    updateTransferState: (state, action) => {
      state.transferState = action.payload;
    },
    loadExploreContract: (state, action) => {
      state = {
        ...state,
        exploreList: [...state.exploreList, action.payload],
        exploreIds: [...state.exploreIds, action.payload.id],
      };
      return state;
    },
    loadExploreContracts: (state, action) => {
      action.payload.forEach((contract: Contract) => {
        const contractIndex = state.exploreList.findIndex(
          (existingContract) => existingContract.id === contract.id
        );
        if (contractIndex !== -1) {
          state.exploreList[contractIndex] = { ...state.exploreList[contractIndex], ...contract };
        } else {
          state.exploreList.push(contract);
          state.exploreIds.push(contract.id);
        }
      });
    },
    logOutContracts: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  loadContract,
  loadContracts,
  logOutContracts,
  updateContract,
  updateTransferState,
  updateContractNft,
  loadExploreContract,
  removeContract,
  removeContractNft,
  loadExploreContracts,
} = contractsSlice.actions;
export default contractsSlice.reducer;
