import { SVGProps } from 'react';

const IconWarranty = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '54'}
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="54" height="54" rx="13.7647" fill="white" />
    <path
      d="M31.4668 9.13245V19.5552H41.8896"
      stroke="#642EFF"
      strokeWidth="2.74887"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.4439 44.8677H40.3998C40.7947 44.8677 41.1734 44.7109 41.4527 44.4316C41.7319 44.1524 41.8888 43.7737 41.8888 43.3788V19.5552L31.466 9.13245H13.5983C13.2034 9.13245 12.8247 9.28932 12.5455 9.56856C12.2662 9.84779 12.1094 10.2265 12.1094 10.6214V27.0001"
      stroke="#642EFF"
      strokeWidth="2.74887"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.9984 36.6783H13.5977V44.8677H26.9984V36.6783Z"
      stroke="#642EFF"
      strokeWidth="2.74887"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5762 36.6783V34.4448C16.5762 33.4576 16.9684 32.5108 17.6664 31.8127C18.3645 31.1146 19.3113 30.7224 20.2986 30.7224C21.2858 30.7224 22.2327 31.1146 22.9308 31.8127C23.6288 32.5108 24.021 33.4576 24.021 34.4448V36.6783"
      stroke="#642EFF"
      strokeWidth="2.74887"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconWarranty;
