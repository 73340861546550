import { SVGProps } from 'react';

const IconUser = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={31}
    height={30}
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5 18.75C19.6421 18.75 22.9999 15.3921 22.9999 11.25C22.9999 7.10786 19.6421 3.75 15.5 3.75C11.3578 3.75 7.99997 7.10786 7.99997 11.25C7.99997 15.3921 11.3578 18.75 15.5 18.75Z"
      stroke={props.stroke || 'white'}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M4.13281 25.3124C5.28469 23.3169 6.94159 21.6598 8.93697 20.5076C10.9323 19.3555 13.1959 18.7489 15.5 18.7489C17.8041 18.7489 20.0676 19.3555 22.063 20.5076C24.0584 21.6598 25.7153 23.3169 26.8671 25.3124"
      stroke={props.stroke || 'white'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconUser;
