import { GAME } from '../../imports/constants';
import ChooseCharConfig from '../configs/chooseCharConfig';
import GameScene from './gameScene';

type StartGameConfig = {
  tokenId: number;
  contractId: string;
  firebaseToken: string;
  address: string;
  highscore: number;
  companyId: string;
  name: string;
};
export default class ChooseCharScene extends Phaser.Scene {
  private _levelConfig: ChooseCharConfig;

  private _backgroundLevels: Phaser.GameObjects.TileSprite[] = [];

  private _players!: Phaser.GameObjects.Sprite[];

  private _carousel!: Phaser.GameObjects.Container;

  constructor(levelConfig: ChooseCharConfig = new ChooseCharConfig()) {
    super('ChooseCharScene');
    this._levelConfig = levelConfig;
  }

  preload() {
    const progressBox = this.add.graphics();
    const progressBar = this.add.graphics();

    const boxWidth = 320;
    const boxHeight = 50;
    const boxMargin = 10;
    const boxX = this.cameras.main.width / 2 - boxWidth / 2;
    const boxY = this.cameras.main.height / 2 - boxHeight / 2;

    this.load.on('start', () => {
      progressBox.fillStyle(0xffec00, 1);
      progressBox.fillRoundedRect(boxX, boxY, boxWidth, boxHeight, 24);
    });

    this.load.on('progress', (value: integer) => {
      progressBar.clear();
      progressBar.fillStyle(0x1dd7ff, 1);
      progressBar.fillRoundedRect(
        boxX + boxMargin,
        boxY + boxMargin,
        (boxWidth - boxMargin * 2) * value,
        boxHeight - boxMargin * 2,
        value < 0.1 ? 0 : 16
      );
    });

    this.load.on('complete', () => {
      progressBar.destroy();
      progressBox.destroy();

      this.cameras.main.setBackgroundColor(GAME.bgColor);
    });

    this.load.image('logoNFTF', '/assets/images/game/logo-violet.png');
    this.load.image('chevronLeft', '/assets/images/game/chevron-left.png');
    this.load.image('chevronRight', '/assets/images/game/chevron-right.png');

    for (let i = 0; i < this._levelConfig.backgroundLevelsCount; i++) {
      const key = `background${i}`;
      if (this.textures.exists(key)) {
        this.textures.remove(key);
      }
      this.load.image(key, this._levelConfig.getBackgroundAsset(i));
    }

    for (let i = 1; i < this._levelConfig.playerCount + 1; i++) {
      this.load.spritesheet(`player${i}`, this._levelConfig.getPlayerAsset(i), {
        frameWidth: 100,
        frameHeight: 150,
      });
    }
  }

  create(config: StartGameConfig) {
    const w = this.game.config.width as number;
    const h = this.game.config.height as number;

    this._backgroundLevels = [];
    for (let i = 0; i < this._levelConfig.backgroundLevelsCount; i++) {
      const bgLevel = this.add.tileSprite(w * 0.5, h * 0.5, w, h, `background${i}`).setDepth(-2);
      this._backgroundLevels.push(bgLevel);
    }

    const logo = this.add
      .image(w - 40, h - 70, 'logoNFTF')
      .setScale(0.2)
      .setOrigin(1, 0);

    this.add
      .text(this.cameras.main.width / 2, 100, 'Choose your Character!', { fontFamily: 'Work Sans' })
      .setScale(2)
      .setResolution(2)
      .setColor('white')
      .setOrigin(0.5);

    //Adds playable characters
    this._players = [];
    for (let i = 1; i < this._levelConfig.playerCount + 1; i++) {
      this.anims.create({
        key: `player${i}`,
        frames: this.anims.generateFrameNumbers(`player${i}`, { start: 0, end: 14 }),
        frameRate: 30,
        repeat: -1,
      });

      const player = this.add
        .sprite(0, 0, `player${i}`)
        .play(`player${i}`)
        .setVisible(false)
        .setInteractive({ useHandCursor: true })
        .addListener('pointerdown', () => {
          this._startGame({ playerAsset: this._selectPlayer(i), ...config });
        })
        .addListener('pointerover', () => {
          player.setScale(1.1);
        })
        .addListener('pointerout', () => {
          player.setScale(1);
        });
      this._players.push(player);
    }

    this._players[this._players.length - 1].setVisible(true);

    //Adds container for player carousel
    this._carousel = new Phaser.GameObjects.Container(this, w / 2, h / 2, this._players);

    this.add.existing(this._carousel);

    const carouselBounds = this._carousel.getBounds();

    this.add
      .graphics()
      .fillRoundedRect(
        carouselBounds.left - 60,
        carouselBounds.top - 60,
        carouselBounds.right - carouselBounds.left + 120,
        carouselBounds.bottom - carouselBounds.top + 120
      )
      .fillStyle(0xffffff, 0.5)
      .setDepth(-1);

    const rightArrow = this.add
      .image(carouselBounds.right + 100, carouselBounds.centerY, 'chevronRight')
      .setScale(0.5)
      .setInteractive({ useHandCursor: true })
      .on('pointerdown', () => {
        this._swipeRight(this._carousel);
      });

    const leftArrow = this.add
      .image(carouselBounds.left - 100, carouselBounds.centerY, 'chevronLeft')
      .setScale(0.5)
      .setInteractive({ useHandCursor: true })
      .on('pointerdown', () => {
        this._swipeLeft(this._carousel);
      });
  }

  _selectPlayer(number: number): string {
    console.log(number);
    return this._levelConfig.getPlayerAsset(number);
  }

  _startGame(config: StartGameConfig & { playerAsset: string }) {
    this.scene.add('GameScene', GameScene, true, {
      ...config,
      eventsCb: () => {},
    });

    this.scene.remove('ChooseCharScene');
  }

  _swipeRight(carousel: Phaser.GameObjects.Container) {
    this._players.forEach((player) => {
      player === carousel.last && player.setVisible(false);
    });

    carousel.sendToBack(carousel.last!);

    this._players.forEach((player) => {
      player === carousel.last && player.setVisible(true);
    });
  }

  _swipeLeft(carousel: Phaser.GameObjects.Container) {
    this._players.forEach((player) => {
      player === carousel.last && player.setVisible(false);
    });

    carousel.moveTo(carousel.first!, this._players.length - 1);

    this._players.forEach((player) => {
      player === carousel.last && player.setVisible(true);
    });
  }
}
