import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalState } from 'react-simple-modal-provider';
import Button from '../button/Button';
import ModalLayout from '../layouts/ModalLayout';
interface AppStoreModalProps {
  children?: ReactNode;
}

const ModalBody = ({ setOpen }: any) => {
  const { t } = useTranslation();

  const redirectToAppStore = () => {
    setOpen(false);
    setTimeout(() => {
      window.location.href = 'https://apps.apple.com/it/app/bcode-walletplace/id6451476111';
      console.log('🚀 ~ redirectToAppStore ~ window.location.href :', window.location.href);
    }, 200);
  };

  return (
    <ModalLayout>
      <div className="w-full max-w-[600px] p-7">
        <p className="mx-auto mb-2 text-center text-body-bold-16">
          {t('appleStoreNewVersion.title')}
        </p>
        <p className="mx-auto mb-2 text-center text-body-medium-14">
          {t('appleStoreNewVersion.subtitle')}
        </p>
        <Button action={redirectToAppStore} type={'primary'} className={`w-full`}>
          {t('appleStoreNewVersion.cta') as string}
        </Button>
      </div>
    </ModalLayout>
  );
};

const AppStoreModal = ({ children }: AppStoreModalProps) => {
  const [isOpen, setOpen] = useModalState();

  return (
    <Modal
      id="AppStoreModal"
      consumer={children}
      isOpen={isOpen}
      setOpen={setOpen}
      allowClickOutside={false}
    >
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default AppStoreModal;
