import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MessagesContext } from '../../contexts/MessagesContext';
import { MiscContext } from '../../contexts/MiscContext';
import { useTheme } from '../../contexts/ThemeProvider';
import { IconBack, IconEnvelope } from '../../icons';
import { COLORS } from '../../imports/constants';
import { getUnreadMessages } from '../../imports/utils/messages';
import { useAppSelector } from '../../redux/hooks';

interface SearchbarHeader {
  backTo?: string;
  hasMessage?: boolean;
  onBack?: () => void;
  id?: string;
}

export default function GoBackHeader({ onBack, backTo, hasMessage = true }: SearchbarHeader) {
  const location = useLocation();
  const { getLogoImg, isThemeDark } = useTheme();
  const { messages } = useContext(MessagesContext);
  const { insets } = useContext(MiscContext);
  const { companyOptions } = useAppSelector(({ user }) => user);

  const iconStroke = isThemeDark() ? COLORS.white : COLORS.black;

  return (
    <div
      className="fixed inset-x-0 top-0 z-50 mx-auto flex w-full max-w-[768px] justify-between space-x-[18px] border-b border-grey-600 bg-white px-[30px] pb-4 dark:bg-black "
      style={{ paddingTop: 16 + insets.top }}
    >
      <div className="flex w-[56px] items-center justify-center">
        {onBack ? (
          <IconBack aria-label="back" role="button" onClick={onBack} stroke={iconStroke} />
        ) : (
          backTo && (
            <Link to={backTo}>
              <IconBack aria-label="back" stroke={iconStroke} />
            </Link>
          )
        )}
      </div>
      <img src={getLogoImg()} width={100} className="mx-auto" />
      <div className="flex w-[56px] items-center justify-center">
        {hasMessage && (
          <Link to="/messages" className="relative">
            <IconEnvelope
              stroke={location.pathname.startsWith('/messag') ? COLORS.primary500 : iconStroke}
            />
            {getUnreadMessages(messages, companyOptions) > 0 && (
              <span className="absolute bottom-0 right-0 flex h-4 w-4 items-center justify-center rounded-full bg-primary-500 text-center text-[10px]">
                {String(getUnreadMessages(messages, companyOptions)).padStart(2, '0')}
              </span>
            )}
          </Link>
        )}
      </div>
    </div>
  );
}
