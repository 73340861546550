export class GameOverConfig {
  backgroundLevelsCount: integer = 5;

  gameVersionStr: string = '1.0.0';

  assetsFolder: string = '/assets/images/game/';

  getBackgroundAsset(index: integer): string {
    return this.assetsFolder + 'set-two/background/' + index + '.png';
  }
}
