import { Capacitor } from '@capacitor/core';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useModal } from 'react-simple-modal-provider';
import { checkIfVersionIsOld } from '../imports/utils/apple';

export const useCheckAppleVersion = () => {
  const isAppleDevice = Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios';

  const { open: openAppStoreModal } = useModal('AppStoreModal');

  const { data: isVersionOld } = useQuery({
    queryFn: checkIfVersionIsOld,
    queryKey: ['checkIfVersionIsOld'],
    enabled: isAppleDevice,
  });

  useEffect(() => {
    if (isVersionOld) {
      openAppStoreModal(true);
    }
  }, [isVersionOld]);
};
