import { SVGProps } from 'react';

const IconInternet = (props: SVGProps<SVGSVGElement>) => (
  <svg width={26} height={27} viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_969_10737)">
      <path
        d="M13 22.9659C18.3848 22.9659 22.75 18.6007 22.75 13.2159C22.75 7.83117 18.3848 3.46594 13 3.46594C7.61522 3.46594 3.25 7.83117 3.25 13.2159C3.25 18.6007 7.61522 22.9659 13 22.9659Z"
        stroke={props.fill}
        strokeWidth={1.625}
        strokeMiterlimit={10}
      />
      <path
        d="M3.80859 9.96594H22.1914"
        stroke={props.fill}
        strokeWidth={1.625}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.80859 16.4659H22.1914"
        stroke={props.fill}
        strokeWidth={1.625}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 22.7019C15.2437 22.7019 17.0625 18.4549 17.0625 13.2159C17.0625 7.97698 15.2437 3.72998 13 3.72998C10.7563 3.72998 8.9375 7.97698 8.9375 13.2159C8.9375 18.4549 10.7563 22.7019 13 22.7019Z"
        stroke={props.fill}
        strokeWidth={1.625}
        strokeMiterlimit={10}
      />
    </g>
    <defs>
      <clipPath id="clip0_969_10737">
        <rect width={26} height={26} fill="white" transform="translate(0 0.215942)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconInternet;
