import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { IconLoading } from '../icons';

interface LoadingState {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const LoadingContext = createContext<LoadingState>({
  loading: false,
  setLoading: () => {},
});

interface LoadingProps {
  children: React.ReactNode;
}

export function LoadingProvider({ children }: LoadingProps) {
  const [loading, setLoading] = useState(false);

  const value = { loading, setLoading };

  useEffect(() => {
    if (loading) {
      document.body.classList.add('no-action');
    } else {
      document.body.classList.remove('no-action');
    }
  }, [loading]);

  return (
    <LoadingContext.Provider value={value}>
      <>
        {loading ? (
          <div className="absolute z-[99] flex h-screen w-screen items-center justify-center bg-black/60">
            <IconLoading className="h-12 w-12 animate-spin" />
          </div>
        ) : (
          <></>
        )}
        {children}
      </>
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider');
  }
  return context;
}
