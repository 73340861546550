import { Document, Image, Link, Page, Path, Svg, Text, View } from '@react-pdf/renderer';
import { getIdToken } from 'firebase/auth';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../contexts/AuthContext';
import { COLORS, explorerPolygon, paidIpfsGateway } from '../../imports/constants';
import { Audit, Notarization, NotarizationTransaction, Receipt } from '../../imports/types';
import { formatDate } from '../../imports/utils/date';
import { getFileFromStorage } from '../../imports/utils/notarizations';
import { formatIPFSUri } from '../../imports/utils/strings';
import MainLogo from '../../assets/images/main-logo.png';

type ReportPageProps = {
  children: React.ReactNode | React.ReactNode[];
};
const ReportPage = ({ children }: ReportPageProps) => (
  <Page size="A4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <View style={{ width: '90%', height: '90%' }}>
      <View
        style={{
          width: '100%',
          height: 80,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: 10,
          borderColor: COLORS.primary500,
          borderBottomWidth: 2,
        }}
      >
        <Image src={MainLogo} style={{ width: 'auto', height: '100%' }} />
      </View>

      <View style={{ width: '100%', height: '100%' }}>{children}</View>
    </View>
  </Page>
);

type ReportTableTitleProps = {
  children: React.ReactNode;
};
const ReportTableTitle = ({ children }: ReportTableTitleProps) => (
  <View style={{ margin: 20 }}>
    <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{children}</Text>
  </View>
);

type TableRow = { key: string; label: string; linkTo?: any; value: any };

type ReportTableProps = {
  row: TableRow;
  isNotarizationsList?: boolean;
};
const ReportTable = ({ row, isNotarizationsList }: ReportTableProps) => {
  const { t } = useTranslation();
  const { key, label, linkTo, value } = row;

  return (
    <View
      style={{
        width: '100%',
        minHeight: 30,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <View
        style={{
          width: '30%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderWidth: 1,
          borderColor: 'black',
        }}
      >
        <Text style={{ fontWeight: 'bold', fontSize: 12, color: COLORS.primary500 }}>
          {isNotarizationsList ? label : t(`notarizations.report.${label}`)}
        </Text>
      </View>

      <View
        style={{
          width: '70%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingLeft: 10,
          borderWidth: 1,
          borderColor: 'black',
        }}
      >
        {linkTo && value ? (
          <Link
            style={{ fontSize: 10, color: COLORS.primary500, textDecoration: 'underline' }}
            src={linkTo}
          >
            <Text style={{ fontSize: 10 }}>{value.toString()}</Text>
          </Link>
        ) : (
          <Text style={{ fontSize: 10 }}>{value}</Text>
        )}
      </View>
    </View>
  );
};

type RenderReportPageProps = {
  notarization?: Notarization & Receipt;
  transactionData: NotarizationTransaction;
};
const RenderReportPage = ({ notarization, transactionData }: RenderReportPageProps) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [externalUrl, setExternalUrl] = useState<string>('');
  const [multipleExternalUrls, setMultipleExternalUrls] = useState<
    { hash: string; externalUrl: string }[]
  >([]);

  const bcodelink = paidIpfsGateway;
  const chainLink = explorerPolygon;

  const handleGetExternalUrl = async () => {
    if (user) {
      const authTokenId = await getIdToken(user);
      if (notarization && notarization.externalUrl) {
        getFileFromStorage(notarization.externalUrl, authTokenId).then((res) => {
          setExternalUrl(res.value.url);
        });
      }
    }
  };

  const getNotarizationTable = (data: Notarization & Receipt) => {
    const getStorageKey = () => {
      if (data.externalUrl) {
        return 'externalUrl';
      }
      return 'ipfsUri';
    };

    const getStorageLinkTo = () => {
      let linkTo = '';
      if (data.ipfsUri) {
        linkTo = `${bcodelink}/${data.ipfsUri}`;
      }
      if (data.externalUrl) {
        linkTo = externalUrl;
      }
      return linkTo;
    };

    const getStorageValue = () => {
      let value = t('notarizations.report.file_not_available');
      if (data.ipfsUri) {
        value = t('notarizations.report.ipfs');
      }
      if (data.externalUrl) {
        value = t('notarizations.report.bcode');
      }
      return value;
    };

    return [
      { key: 'name', label: 'name', value: data.name },
      { key: 'description', label: 'description', value: data.description },
      { key: 'date', label: 'date', value: formatDate(data.date!) },
      { key: 'hash', label: 'hash', value: data.hash },
      {
        key: getStorageKey(),
        label: 'storage',
        linkTo: getStorageLinkTo(),
        value: getStorageValue(),
      },
      { key: 'notes', label: 'notes', value: data.notes },
    ];
  };

  const getMaticTransactionTable = (data: (Notarization & Receipt) | Audit) => {
    const formattedMerkleTreeURI = formatIPFSUri(data.merkleTreeURI!);
    return [
      { key: 'from', label: 'from', value: transactionData.from },
      { key: 'to', label: 'to', value: transactionData.to },
      {
        key: 'transactionHash',
        label: 'transaction_hash',
        linkTo: `${chainLink}/tx/${transactionData.transactionHash}`,
        value: transactionData.transactionHash,
      },
      {
        key: 'blockNumber',
        label: 'block_number',
        value: transactionData.blockNumber,
      },
      {
        key: 'blockHash',
        label: 'block_hash',
        value: transactionData.blockHash,
      },
      {
        key: 'merkleTreeURI',
        label: 'merkle_tree_uri',
        linkTo: formattedMerkleTreeURI,
        value: formattedMerkleTreeURI,
      },
    ];
  };

  useEffect(() => {
    if (externalUrl.length === 0) {
      handleGetExternalUrl();
    }
  }, []);

  return (
    <ReportPage>
      <ReportTableTitle>{t('notarizations.report.notarization_data')}</ReportTableTitle>
      {notarization &&
        getNotarizationTable(notarization).map((row, index) => (
          <ReportTable key={index} row={row} />
        ))}
      {notarization && (
        <>
          <ReportTableTitle>{t('notarizations.report.matic_transaction')}</ReportTableTitle>
          {getMaticTransactionTable(notarization).map((row, index) => (
            <ReportTable key={index} row={row} />
          ))}
        </>
      )}
    </ReportPage>
  );
};

type ReportProps = {
  notarizations: Notarization & Receipt;
  transactionData: NotarizationTransaction;
};
const ReportNotarization = ({ notarizations, transactionData }: ReportProps) => {
  return (
    <Document>
      <RenderReportPage notarization={notarizations} transactionData={transactionData} />
    </Document>
  );
};

export default ReportNotarization;
