import { SVGProps } from 'react';

const IconBack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '54'}
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="54" height="54" rx="13.7647" fill="white" />
    <g clipPath="url(#clip0_1077_17823)">
      <path
        d="M34.4439 44.8677H40.3998C40.7947 44.8677 41.1734 44.7109 41.4527 44.4316C41.7319 44.1524 41.8888 43.7737 41.8888 43.3788V19.5552L31.466 9.13245H13.5983C13.2034 9.13245 12.8247 9.28932 12.5455 9.56856C12.2662 9.84779 12.1094 10.2265 12.1094 10.6214V27.0001"
        stroke="#642EFF"
        strokeWidth="2.74887"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.4668 9.13245V19.5552H41.8896"
        stroke="#642EFF"
        strokeWidth="2.74887"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M33.9492 29.7299L22.6992 40.9799L17.0742 35.3549"
      stroke="#642EFF"
      strokeWidth="2.97794"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <clipPath id="clip0_1077_17823">
        <rect
          width="47.6471"
          height="47.6471"
          fill="white"
          transform="translate(3.17578 3.17651)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default IconBack;
