import { Link, useNavigate } from 'react-router-dom';
import { FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PublicCompany } from '../../contexts/FirestoreContext';
import { useTheme } from '../../contexts/ThemeProvider';
import { IconFavoritesEmpty, IconFavoritesFilled, IconQrCode } from '../../icons';
import { Contract, Nft } from '../../imports/types';
import { truncateText } from '../../pages/game/Game';
import NftImage from '../nftImage/NftImage';
interface NftsCompanySliderProps {
  company: PublicCompany;
  nfts: Nft[];
  contracts: Contract[];
}
export default function NftsCompanySlider({ company, nfts, contracts }: NftsCompanySliderProps) {
  const navigate = useNavigate();
  const { getBaseIconColor } = useTheme();

  const iconColor = getBaseIconColor();

  return (
    <div className="relative flex w-full flex-col space-y-4" key={company?._id}>
      <p
        className="pl-6 text-body-medium-24"
        onClick={() => navigate(`/companies/${company?.address}`)}
      >
        {company?.companyName}
      </p>
      <Swiper
        slidesPerView="auto"
        spaceBetween={15}
        role="list"
        freeMode
        modules={[FreeMode]}
        className="h-full w-full"
      >
        {nfts.map((nft, i: number) => (
          <SwiperSlide
            role="listitem"
            key={`${nft.contractId}-${nft.tokenId}`}
            className={`h-full w-auto ${
              i === 0 ? 'pl-6' : i === contracts.length - 1 ? 'pr-6' : ''
            }`}
          >
            <div className="relative flex flex-col space-y-[10px]">
              <Link to={`/nfts/${nft.contractId}/${nft.tokenId}`}>
                <NftImage
                  nftName={nft.name}
                  src={nft.image || company?.bannerPic || company.profilePic || ''}
                />
              </Link>
              <button
                aria-label="Qr code"
                className="absolute bottom-[10px] left-[10px] flex h-10 w-10 items-center justify-center rounded-[10px] bg-white"
                onClick={() => navigate(`/nfts/${nft.contractId}/${nft.tokenId}/certify`)}
              >
                <IconQrCode />
              </button>
            </div>
            <p className="flex w-[175px] justify-between pt-1 text-body-regular-14">
              {truncateText(nft.name, {
                maxLength: 18,
              })}
              <span className="pr-2">
                {nft.favorite ? (
                  <IconFavoritesFilled className="h-[14px]" stroke={iconColor} />
                ) : (
                  <IconFavoritesEmpty className="h-[14px]" stroke={iconColor} />
                )}
              </span>
            </p>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
