import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalState } from 'react-simple-modal-provider';
import * as yup from 'yup';
import { recoverDriveWallet } from '../../api/firebase';
import { getFileFromGoogleDrive, recoverFromShares } from '../../imports/utils/google';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { UserState, signIn } from '../../redux/user/user.slice';
import Button from '../button/Button';
import Form from '../form/Form';
import Input from '../input/Input';
import ModalLayout from '../layouts/ModalLayout';

type DriveRecoverModal = {
  children?: ReactNode;
};

const DriveRecoverModal = ({ children }: DriveRecoverModal) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useModalState();
  const { share, accessToken } = useAppSelector(({ user }: { user: UserState }) => user);
  const dispatch = useAppDispatch();
  const form = {
    initialValues: {
      pin: '',
      repeatPin: '',
    },
    validationSchema: yup.object({
      pin: yup
        .string()
        .min(6, t('form_validation.pin_length', { minChars: 6 }))
        .test('pin_check', t('form_validation.pin_must_be_digit'), (str) =>
          /^[0-9]+$/.test(str || '')
        )
        .required(t('form_validation.required')),

      repeatPin: yup.string().oneOf([yup.ref('pin'), null], t('form_validation.pin_dont_match')),
    }),
  };

  const googleDriveRecover = async (values: { pin: number }) => {
    const { pin } = values;
    if (share) {
      const shares = [];

      shares[2] = share.s;
      shares[1] = (
        await getFileFromGoogleDrive({
          fileId: share.driveDocId,
          accessToken,
        })
      ).value.s;
      const { value } = await recoverDriveWallet({
        mnemonic: recoverFromShares(shares),
        pin: pin.toString(),
      });
      dispatch(signIn(value));
    }
    setOpen(false);
  };

  return (
    <Modal id="DriveRecoverModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalLayout onClose={() => setOpen(false)}>
        <div className="w-full max-w-[600px] p-7">
          <Form
            initialValues={form.initialValues}
            validationSchema={form.validationSchema}
            mode={'all'}
          >
            {({ register, handleSubmit, errors, isValid, watch }) => {
              const values = watch();

              return (
                <>
                  <p className="mb-4 text-body-medium-24">{t('driveRecoverModal.title')}</p>

                  <div className="mt-7 flex flex-col gap-6 ">
                    <Input
                      label={t('unlockModal.pin')}
                      placeholder={t('unlockModal.form_placeholders.pin')}
                      error={errors['pin']?.message}
                      password
                      {...register('pin')}
                      forceLight={true}
                      inputMode="numeric"
                    />
                    <Input
                      label={t('ssoSignupModal.repeat_pin')}
                      placeholder={t('ssoSignupModal.form_placeholders.repeat_pin')}
                      error={errors['repeatPin']?.message}
                      password
                      {...register('repeatPin')}
                      forceLight={true}
                      inputMode="numeric"
                    />
                    <Button
                      type="primary"
                      className="mt-14"
                      // disabled={!isValid}
                      action={handleSubmit(googleDriveRecover)}
                    >
                      {t('driveRecoverModal.confirm') as string}
                    </Button>
                    <Button
                      type="secondary"
                      action={() => {
                        setOpen(false);
                      }}
                    >
                      {t('driveRecoverModal.cancel') as string}
                    </Button>
                  </div>
                </>
              );
            }}
          </Form>
        </div>
      </ModalLayout>
    </Modal>
  );
};

export default DriveRecoverModal;
