import { User } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { auth } from '../imports/firebase';

interface AuthState {
  user: User | null | undefined;
}
export const AuthContext = React.createContext<AuthState>({
  user: undefined,
});

interface AuthProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProps) => {
  const [user, setUser] = useState<User | null | undefined>();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      console.count('[onAuthStateChanged] - times called: ');
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = { user, isLoading: user === undefined };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
