import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { updateUserNft } from '../../api/firebase';
import { updateContractNft } from '../../redux/contracts/contracts.slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Button from '../button/Button';
import ModalLayout from '../layouts/ModalLayout';

type HideNftProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { nft } = useModalProps('HideNftModal');
  const { t } = useTranslation();
  const { uid } = useAppSelector(({ user }) => user);
  const dispatch = useAppDispatch();
  const handleHideNft = () => {
    let isHide = nft?.hide;
    updateUserNft(`${nft?.contractId}-${nft?.tokenId}`, { hide: !isHide }, uid).then((result) => {
      if (result?.error) {
        console.error(result.error);
      } else {
        dispatch(updateContractNft({ nftToUpdate: nft, newData: { hide: !isHide } }));
      }
    });
    setOpen(false);
  };
  return (
    <ModalLayout onClose={() => setOpen(false)} className="w-full max-w-[400px] p-7">
      <div className="w-full">
        <p className=" text-body-regular-18">
          {nft.hide ? t('nft.want_show') : t('nft.want_hide')}
        </p>
        <div className="mt-[40px] flex flex-col gap-7 pb-7">
          <Button action={handleHideNft} type="secondary">
            {nft.hide ? `${t('nft.show')}` : `${t('nft.hide')}`}
          </Button>
          <Button action={() => setOpen(false)}>{t('auth.back')}</Button>
        </div>
      </div>
    </ModalLayout>
  );
};
const HideNftModal = ({ children }: HideNftProps) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="HideNftModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default HideNftModal;
