import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import { PollType } from '../../imports/types';

export type PollSlice = {
  polls: PollType[];
  pollObject: PollType;
  answersGiven: {
    qId: string;
    answers: { aId: string; value: boolean | string }[];
    nextQuestion: string;
  }[];
  currentQuestion: string;
};

const initialState: PollSlice = {
  polls: [],
  pollObject: {
    private: false,
    maxTotalAnswers: 0,
    minTotalAnswers: 0,
    body: '',
    endDate: 0,
    id: '',
    questions: [],
    startDate: 0,
    title: '',
    description: '',
    availableOn: {},
  },
  answersGiven: [],
  currentQuestion: '',
};

export const pollSlice = createSlice({
  name: 'poll',
  initialState,
  reducers: {
    setPolls: (state, action) => {
      //Pass first  to uniqBy fn the action.payload spread, because in case of same id in payload and state the fn takes the first passed, so the payload can contain some update
      state = { ...state, polls: uniqBy([...action.payload, ...state.polls], 'id') };
      return state;
    },
    setPollObject: (state, action) => {
      state = {
        ...state,
        pollObject: action.payload,
      };
      return state;
    },
    setAnswersGiven: (state, action) => {
      state = {
        ...state,
        answersGiven: action.payload,
      };
      return state;
    },
    setCurrentQuestion: (state, action) => {
      state = {
        ...state,
        currentQuestion: action.payload,
      };
      return state;
    },
    clearPollCache: (state) => {
      state = { ...initialState, polls: state.polls };
      return state;
    },
    logOutPoll: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  setPolls,
  setPollObject,
  setCurrentQuestion,
  setAnswersGiven,
  clearPollCache,
  logOutPoll,
  // setValueSForm,
} = pollSlice.actions;
export default pollSlice.reducer;
