import { t } from 'i18next';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'next-share';
import { toast } from 'react-toastify';
import { IconCopy, IconX } from '../../icons';

type ShareBarProps = {
  url: string;
};

const ShareBar = ({ url }: ShareBarProps) => {
  function copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text);
    toast.success(t('toast.profile.copied_successfully') as string);
  }

  return (
    <div className="mt-2 flex w-full justify-end gap-2 ">
      <EmailShareButton url={url}>
        <EmailIcon size={32} round={true} />
      </EmailShareButton>
      <FacebookShareButton url={url}>
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
      <LinkedinShareButton
        // url={`https://walletplace.dev.bcode.cloud/share/nfts/illimitati1706112852/1`} //test per funzionamento perchè con localhost non funziona, probabilmente controlla se il link è esistente?
        url={url}
      >
        <LinkedinIcon size={32} round={true} />
      </LinkedinShareButton>
      <TelegramShareButton url={url}>
        <TelegramIcon size={32} round={true} />
      </TelegramShareButton>
      <WhatsappShareButton url={url}>
        <WhatsappIcon size={32} round={true} />
      </WhatsappShareButton>
      <a
        href={`https://twitter.com/intent/tweet?url=${url}`}
        className="flex h-[31px] w-[31px] items-center justify-center rounded-full bg-grey-600"
      >
        <IconX />
      </a>
      <div
        className="flex h-[32px] w-[32px] items-center justify-center rounded-full bg-grey-400"
        onClick={() => copyToClipboard(url)}
      >
        <IconCopy />
      </div>
    </div>
  );
};

export default ShareBar;
