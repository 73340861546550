import { SVGProps } from 'react';

const IconHome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.8125 24.375V18.75C17.8125 18.5014 17.7137 18.2629 17.5379 18.0871C17.3621 17.9113 17.1236 17.8125 16.875 17.8125H13.125C12.8764 17.8125 12.6379 17.9113 12.4621 18.0871C12.2863 18.2629 12.1875 18.5014 12.1875 18.75V24.375C12.1875 24.6236 12.0887 24.8621 11.9129 25.0379C11.7371 25.2137 11.4986 25.3125 11.25 25.3125H5.625C5.37636 25.3125 5.1379 25.2137 4.96209 25.0379C4.78627 24.8621 4.6875 24.6236 4.6875 24.375V13.5352C4.6896 13.4054 4.71764 13.2774 4.76996 13.1587C4.82229 13.0399 4.89785 12.9329 4.99219 12.8438L14.3672 4.32423C14.54 4.16612 14.7658 4.07843 15 4.07843C15.2342 4.07843 15.46 4.16612 15.6328 4.32423L25.0078 12.8438C25.1022 12.9329 25.1777 13.0399 25.23 13.1587C25.2824 13.2774 25.3104 13.4054 25.3125 13.5352V24.375C25.3125 24.6236 25.2137 24.8621 25.0379 25.0379C24.8621 25.2137 24.6236 25.3125 24.375 25.3125H18.75C18.5014 25.3125 18.2629 25.2137 18.0871 25.0379C17.9113 24.8621 17.8125 24.6236 17.8125 24.375Z"
      stroke={props.stroke || 'white'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconHome;
