import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalState } from 'react-simple-modal-provider';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { logOut, reauthenticateUser, updateUser } from '../../api/firebase';
import { SSO_PROVIDERS } from '../../imports/constants';
import { auth } from '../../imports/firebase';
import { decryptWallet } from '../../imports/utils/auth';
import { required, validatePassword } from '../../imports/utils/validation';
import { logOutCompanies } from '../../redux/companies/companies.slice';
import { logOutContracts } from '../../redux/contracts/contracts.slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { logOut as logOutAction, updateUserData } from '../../redux/user/user.slice';
import Button from '../button/Button';
import Form from '../form/Form';
import Input from '../input/Input';
import ModalLayout from '../layouts/ModalLayout';

interface AccountDeleteModalProps {
  children?: ReactNode;
}

const ModalBody = ({ setOpen }: { setOpen: (value: boolean) => void }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(({ user }) => user);

  const form = {
    initialValues: {
      password: '',
    },
    validationSchema: yup.object({
      password: profile.ssoWallet
        ? yup
            .string()
            .min(6, t('form_validation.pin_length', { minChars: 6 }))
            .test('pin_check', t('form_validation.pin_must_be_digit'), (str) =>
              /^[0-9]+$/.test(str || '')
            )
            .required(t('form_validation.required'))
        : required(validatePassword()),
    }),
  };

  const completeLogOut = async () => {
    const { value } = await logOut();
    if (value) {
      dispatch(logOutContracts());
      dispatch(logOutCompanies());
      dispatch(logOutAction());
      dispatch(logOutCompanies());
    }
  };

  const deleteWithPassword = async (values: any) => {
    const { password } = values;
    const { currentUser } = auth;
    try {
      password.trim();

      if (currentUser && currentUser.email) {
        const { error: reauthenticationError } = await reauthenticateUser(currentUser, password);

        if (reauthenticationError) {
          toast.error(t('auth.errors.auth/wrong-password') as string, {});
        } else {
          const { value } = await updateUser(
            {
              disabled: true,
              deletionSchedule: Date.now(),
            },
            profile
          );
          setOpen(false);
          dispatch(updateUserData({ ...value }));
          completeLogOut();
          console.log('first');
        }
      }
    } catch (error) {
      toast.error(error as string);
    }
  };

  const deleteWithPin = async (values: any) => {
    const { password } = values;
    let userWallet;
    try {
      userWallet = decryptWallet(profile.ssoWallet || '', password);
    } catch (e) {
      toast.error(t('auth.errors.auth/wrong-pin') as string);
    }
    if (userWallet) {
      const { value } = await updateUser(
        {
          disabled: true,
          deletionSchedule: Date.now(),
        },
        profile
      );
      setOpen(false);
      dispatch(updateUserData({ ...value }));
      completeLogOut();
    }
  };

  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="w-full max-w-[600px] p-7">
        <Form
          initialValues={form.initialValues}
          validationSchema={form.validationSchema}
          mode={'all'}
        >
          {({ register, handleSubmit, errors, isValid, watch }) => {
            const values = watch();

            return (
              <>
                <p className="mb-4 text-body-medium-18">
                  {profile.ssoWallet
                    ? t('settings.pin_for_delete')
                    : t('settings.password_for_delete')}
                </p>
                <div className="flex flex-col mt-7">
                  {profile.ssoWallet ? (
                    <Input
                      label={t('ssoSignupModal.pin')}
                      placeholder={t('ssoSignupModal.form_placeholders.pin')}
                      password
                      error={errors['password']?.message}
                      {...register('password')}
                      forceLight={true}
                    />
                  ) : (
                    <Input
                      id="account-delete-input-pwd"
                      label={t('auth.password')}
                      placeholder={t('auth.passwordPlaceholder')}
                      error={errors['password']?.message}
                      // error={passworError ? true : false}
                      password
                      {...register('password')}
                      forceLight={true}
                    />
                  )}

                  {/* <p className="mt-2 h-7 text-red">{passworError}</p> */}

                  <Button
                    type="secondary"
                    className="my-10"
                    disabled={!isValid}
                    action={handleSubmit(
                      profile.ssoWallet || SSO_PROVIDERS.includes(profile.provider ?? '')
                        ? deleteWithPin
                        : deleteWithPassword
                    )}
                  >
                    {t('ssoSignupModal.confirm') as string}
                  </Button>
                </div>
              </>
            );
          }}
        </Form>
      </div>
    </ModalLayout>
  );
};

const AccountDeleteModal = ({ children }: AccountDeleteModalProps) => {
  const [isOpen, setOpen] = useModalState();

  return (
    <Modal
      id="AccountDeleteModal"
      consumer={children}
      isOpen={isOpen}
      setOpen={setOpen}
      allowClickOutside={false}
    >
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default AccountDeleteModal;
