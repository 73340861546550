import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/en.json';
import translationIT from './locales/it/it.json';

const resources = {
  it: {
    translation: translationIT,
  },
  en: {
    translation: translationEN,
  },
};

const defaultLng = navigator.language.slice(0, 2);

const backUpLanguage = defaultLng === 'it' ? 'en' : 'it';

i18n.use(initReactI18next).init(
  {
    resources,
    lng: defaultLng ?? 'it',
    fallbackLng: backUpLanguage || 'en',
    // debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  },
  (err, t) => {
    // eslint-disable-next-line no-console
    if (err) console.error('i18n Error', err);
  }
);

export default i18n;
