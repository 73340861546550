import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MiscContext } from '../../contexts/MiscContext';
import { useTheme } from '../../contexts/ThemeProvider';
import { IconHome, IconScan, IconSettings, IconUser, IconWarning } from '../../icons';
import { COLORS } from '../../imports/constants';
import { useAppSelector } from '../../redux/hooks';
import Tutorial from '../tutorial/Tutorial';

const ROUTES_WITHOUT_BOTTOMBAR = [
  '/login',
  '/register',
  '/signUp',
  '/game',
  '/verify-email',
  '/finish-tc',
  '/sso-signup',
  '/tec',
  '/forgot-password',
  '/share',
  '/backup',
  '/welcome',
];

export default function BottomBar() {
  const location = useLocation();
  const { isThemeDark } = useTheme();
  const { uid, backup } = useAppSelector(({ user }) => user);
  const { insets } = useContext(MiscContext);
  const [style, setStyle] = useState({ height: '86px', alignItems: 'center' });
  // const [hasBackup, setHasBackup] = useState(backup);
  const profile = useAppSelector((state) => state.user);
  useEffect(() => {
    if (insets.bottom) {
      setStyle({ height: `${86 + insets.bottom}px`, alignItems: 'flex-start' });
    }
  }, [insets]);

  const activeIndex = (() => {
    if (location.pathname.startsWith('/redeem')) return 'redeem';
    if (location.pathname.startsWith('/settings')) return 'settings';
    if (location.pathname.startsWith('/profile')) return 'profile';
    if (location.pathname.startsWith('/nfts')) return '';
    if (location.pathname.startsWith('/collections')) return '';
    if (location.pathname.startsWith('/companies')) return '';
    if (location.pathname.startsWith('/history')) return '';
    else return 'home';
  })();

  const hasBackup = useMemo(() => {
    return backup;
  }, [backup]);

  if (ROUTES_WITHOUT_BOTTOMBAR.some((route) => location.pathname.startsWith(route)) || !uid) {
    return <></>;
  }
  return (
    <nav
      className={`fixed inset-x-0 bottom-0 z-50 mx-auto flex w-full max-w-[768px] justify-between border-t border-grey-600 bg-white px-[30px] pt-[17px] dark:bg-black`}
      style={style}
    >
      {profile.tutorial.active && location.pathname === profile.tutorial.page && (
        <div className="fixed inset-0 z-50 bg-black opacity-50"></div>
      )}
      <div>
        <Tutorial
          tutorialName="welcomeTutorial"
          step={1}
          className="bottom-[80px] left-8"
          arrow="bottom"
        />
        <BottomBarItem
          to="home/nfts"
          active={activeIndex === 'home'}
          icon={
            <IconHome
              stroke={
                isThemeDark() ||
                activeIndex === 'home' ||
                (profile.tutorial.name === 'welcomeTutorial' && profile.tutorial.step === 1)
                  ? COLORS.white
                  : COLORS.black
              }
              className={`${
                profile.tutorial.name === 'welcomeTutorial' && profile.tutorial.step === 1
                  ? 'pointer-events-none z-[100]'
                  : ''
              }`}
            />
          }
          cy="bottom-home"
        />
      </div>

      <BottomBarItem
        to="redeem"
        active={activeIndex === 'redeem'}
        icon={
          <IconScan
            id="icon-redeem"
            stroke={isThemeDark() || activeIndex === 'redeem' ? COLORS.white : COLORS.black}
          />
        }
        cy="bottom-redeem"
      />

      <BottomBarItem
        to="profile"
        active={activeIndex === 'profile'}
        icon={
          <IconUser
            stroke={isThemeDark() || activeIndex === 'profile' ? COLORS.white : COLORS.black}
          />
        }
        cy="bottom-profile"
      />
      <div>
        <Tutorial
          tutorialName="completeTutorial"
          step={7}
          className="bottom-[70px] right-8"
          arrow="bottom"
          arrowPositionX="right-[18px]"
        />
        <div>
          <BottomBarItem
            to="settings"
            active={activeIndex === 'settings'}
            icon={
              <IconSettings
                stroke={
                  isThemeDark() ||
                  activeIndex === 'settings' ||
                  (profile.tutorial.name === 'completeTutorial' && profile.tutorial.step === 7)
                    ? COLORS.white
                    : COLORS.black
                }
                className={`${
                  profile.tutorial.name === 'completeTutorial' && profile.tutorial.step === 7
                    ? 'pointer-events-none z-[100]'
                    : ''
                }`}
              />
            }
            cy="bottom-settings"
          />
          {!hasBackup && (
            <div className="absolute right-[-10px] top-[-10px]">
              <IconWarning stroke="#FFC000" />
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

function BottomBarItem(props: { to: string; icon: ReactNode; active: boolean; cy?: string }) {
  return (
    <Link
      aria-label={props.to}
      to={`/${props.to}`}
      className={`flex h-[56px] w-[56px] items-center justify-center rounded-full transition-colors duration-200 ${
        props.active ? 'bg-primary-500' : 'bg-grey-50'
      }`}
      data-cy={props.cy}
    >
      {props.icon}
    </Link>
  );
}
