import DOMPurify from 'dompurify';

type CustomizedTextProps = {
  text: string;
  className?: string;
};

const CustomizedText = ({ text, className }: CustomizedTextProps) => {
  const specialChar = /[<>/]/.test(text);
  const sanitizedText = specialChar ? DOMPurify.sanitize(text) : text;

  if (specialChar) {
    return <div dangerouslySetInnerHTML={{ __html: sanitizedText }} className={className} />;
  }
  return <p>{sanitizedText}</p>;
};

export default CustomizedText;
