import { PropsWithChildren } from 'react';

interface FeatureButtonProps {
  show: boolean;
}

export default function FeatureButton({ show, children }: PropsWithChildren<FeatureButtonProps>) {
  return show ? (
    <div className="h-11 w-11 rounded-md border border-black bg-white p-2 dark:border-white">
      {children}
    </div>
  ) : null;
}
