import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { formatIPFSUri } from '../../imports/utils/strings';
import ModalLayout from '../layouts/ModalLayout';
type ShowImageModalProps = {
  children?: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { image } = useModalProps('ShowImageModal');
  const { t } = useTranslation();
  return (
    <ModalLayout onClose={() => setOpen(false)} className="max-h-[70vh] max-w-[90vw] bg-grey-50">
      <img src={formatIPFSUri(image)} alt={t('messages.img_alt')}></img>
    </ModalLayout>
  );
};
const ShowImageModal = ({ children }: ShowImageModalProps) => {
  const [isOpen, setOpen] = useModalState();

  return (
    <Modal id="ShowImageModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default ShowImageModal;
