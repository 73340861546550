import { createSlice } from '@reduxjs/toolkit';

export const gameSlice = createSlice({
  name: 'startGame',
  initialState: {
    gameStart: false,
  },
  reducers: {
    startGame: (state) => ({
      ...state,
      gameStart: true,
    }),
    stopGame: (state) => ({
      ...state,
      gameStart: false,
    }),
  },
});

export const { startGame, stopGame } = gameSlice.actions;
export default gameSlice.reducer;
