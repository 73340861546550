import { SVGProps } from 'react';

const IconShare = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 14C5.65685 14 7 12.6569 7 11C7 9.34315 5.65685 8 4 8C2.34315 8 1 9.34315 1 11C1 12.6569 2.34315 14 4 14Z"
        stroke={props.stroke || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 20.75C16.1569 20.75 17.5 19.4069 17.5 17.75C17.5 16.0931 16.1569 14.75 14.5 14.75C12.8431 14.75 11.5 16.0931 11.5 17.75C11.5 19.4069 12.8431 20.75 14.5 20.75Z"
        stroke={props.stroke || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 7.25C16.1569 7.25 17.5 5.90685 17.5 4.25C17.5 2.59315 16.1569 1.25 14.5 1.25C12.8431 1.25 11.5 2.59315 11.5 4.25C11.5 5.90685 12.8431 7.25 14.5 7.25Z"
        stroke={props.stroke || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9782 5.87207L6.52197 9.37832"
        stroke={props.stroke || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.52197 12.6221L11.9782 16.1283"
        stroke={props.stroke || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconShare;
