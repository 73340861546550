import { SVGProps } from 'react';
const IconFavoritesFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={21}
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.4125 17.094L16.1375 20.094C16.7469 20.4784 17.4969 19.9065 17.3187 19.2034L15.95 13.8222C15.913 13.673 15.9189 13.5164 15.967 13.3705C16.0151 13.2245 16.1034 13.0951 16.2219 12.9972L20.4594 9.46278C21.0125 9.0034 20.7312 8.07528 20.0094 8.0284L14.4781 7.67215C14.3272 7.66337 14.1821 7.61082 14.0606 7.52091C13.939 7.43101 13.8463 7.30765 13.7937 7.1659L11.7312 1.97215C11.6767 1.82206 11.5772 1.69241 11.4464 1.60079C11.3156 1.50917 11.1597 1.46002 11 1.46002C10.8403 1.46002 10.6844 1.50917 10.5536 1.60079C10.4228 1.69241 10.3233 1.82206 10.2687 1.97215L8.20625 7.1659C8.15368 7.30765 8.06096 7.43101 7.93943 7.52091C7.81789 7.61082 7.67279 7.66337 7.52187 7.67215L1.99062 8.0284C1.26875 8.07528 0.987496 9.0034 1.54062 9.46278L5.77812 12.9972C5.89656 13.0951 5.98492 13.2245 6.03302 13.3705C6.08112 13.5164 6.08701 13.673 6.05 13.8222L4.78437 18.8097C4.56875 19.6534 5.46875 20.3378 6.19062 19.8784L10.5875 17.094C10.7108 17.0156 10.8539 16.974 11 16.974C11.1461 16.974 11.2892 17.0156 11.4125 17.094Z"
      fill={props.stroke || 'white'}
      stroke={props.stroke || 'white'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default IconFavoritesFilled;
