import { Link, useNavigate, useParams } from 'react-router-dom';
import { useModal } from 'react-simple-modal-provider';
import {
  IconEcommerce,
  IconGame,
  IconInternet,
  IconLock,
  IconLottery,
  IconNews,
  IconPoll,
  IconProduct,
  IconTransfer,
} from '../../icons';
import { COLORS } from '../../imports/constants';
import {
  AnalyticsFunctionSubtype,
  AnalyticsType,
  Contract,
  Nft,
  NftAttributeString,
} from '../../imports/types';
import { getAnalyticsData } from '../../imports/utils/analytics';
import { removeTrailingSlash } from '../../imports/utils/strings';
import AnalyticsWrapper from '../analytics/AnalyticsWrapper';
import FeatureButton from '../featureButton/FeatureButton';
import { useTranslation } from 'react-i18next';

interface RenderFeaturesProps {
  nft: Nft;
  oldVersion: boolean;
  companyId: string;
  contract?: Contract;
  nftId: string;
  hasPoll: boolean;
  restrictedArea: NftAttributeString;
  accessLink: string;
}

export default function RenderFeatures({
  nft,
  oldVersion,
  companyId,
  contract,
  nftId,
  hasPoll,
  restrictedArea,
  accessLink,
}: RenderFeaturesProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { open: openTransferModal } = useModal('TransferModal');
  const { contractId, tokenId } = useParams();

  const hasFeatures =
    contract?.transfer === 'owners' ||
    contract?.transfer === 'creator&owner' ||
    hasPoll ||
    nft?.external_url ||
    contract?.hasGame ||
    restrictedArea?.value ||
    nft?.attributes?.find(({ type }) => ['news', 'product'].includes(type)) ||
    nft.shop;

  return hasFeatures ? (
    <div id="features" className="flex flex-col">
      <span className="mt-4 text-body-bold-14">{t('nft.features') as string}</span>
      <div className="mt-4 flex justify-start gap-3">
        <FeatureButton show={!!(nft?.external_url && !nft?.hide && !oldVersion)}>
          <AnalyticsWrapper
            shouldAnalytics
            analyticsData={getAnalyticsData(
              AnalyticsType.FUNCTION,
              AnalyticsFunctionSubtype.WEBSITE,
              nft?.external_url || '',
              { companyId, nftId, contractId: nft?.contractId || '' }
            )}
          >
            <a href={nft?.external_url} target="_blank">
              <IconInternet aria-label="website" fill={COLORS.primary500} />
            </a>
          </AnalyticsWrapper>
        </FeatureButton>

        {/* TRANSFER */}
        <FeatureButton
          show={
            (contract?.transfer === 'owners' || contract?.transfer === 'creator&owner') &&
            !nft?.hide &&
            !oldVersion &&
            nft?.status === 'success'
          }
        >
          {/* <Link to="transfer" replace={true}>
          <IconTransfer aria-label="transfer" fill={COLORS.primary500} />
        </Link> */}
          <button
            onClick={() =>
              openTransferModal({ goBack: () => navigate('/home/nfts'), contractId, tokenId })
            }
          >
            <IconTransfer aria-label="transfer" fill={COLORS.primary500} />
          </button>
        </FeatureButton>

        {/* GAMING */}
        <FeatureButton
          show={!!(contract?.hasGame && !nft?.hide && nft?.status === 'success' && !oldVersion)}
        >
          <Link to={`/game/${nft?.tokenId}?contract=${nft?.contractId}`}>
            <IconGame aria-label="game" fill={COLORS.primary500} />
          </Link>
        </FeatureButton>

        {/* RESTRICTED AREA */}
        <FeatureButton
          show={!!(restrictedArea?.value && !nft?.hide && !oldVersion && nft?.status === 'success')}
        >
          <AnalyticsWrapper
            shouldAnalytics
            analyticsData={getAnalyticsData(
              AnalyticsType.FUNCTION,
              AnalyticsFunctionSubtype.RESTRICTED_AREA,
              restrictedArea?.value,
              { companyId, nftId, contractId: nft?.contractId || '' }
            )}
          >
            <a
              // href={(restrictedArea?.value || '').replace('{verify}', accessLink)}
              href={`${removeTrailingSlash(restrictedArea?.value || '')}?${
                accessLink.split('?')[1]
              }`}
              target=""
            >
              <IconLock aria-label="lock" fill={COLORS.primary500} />
            </a>
          </AnalyticsWrapper>
        </FeatureButton>

        {/* POLLS */}
        <FeatureButton show={!!hasPoll}>
          <Link to={`/polls/${contract?.address}/${nft?.tokenId}`}>
            <IconPoll aria-label="poll" fill={COLORS.primary500} />
          </Link>
        </FeatureButton>

        {/* LOTTERY */}
        <FeatureButton show={false}>
          <Link to={`/`}>
            <IconLottery aria-label="lottery" fill={COLORS.primary500} />
          </Link>
        </FeatureButton>

        {/* NEWS */}
        <FeatureButton show={!!nft?.attributes?.find(({ type }) => type === 'news') && !!contract}>
          <AnalyticsWrapper
            shouldAnalytics
            analyticsData={getAnalyticsData(
              AnalyticsType.FUNCTION,
              AnalyticsFunctionSubtype.NEWS,
              '',
              { companyId, nftId, contractId: nft?.contractId || '' }
            )}
          >
            <Link to={`/news/${contract?.id}/${nft?.tokenId}`}>
              <IconNews aria-label="news" fill={COLORS.primary500} />
            </Link>
          </AnalyticsWrapper>
        </FeatureButton>

        {/* PRODUCTS */}
        <FeatureButton
          show={!!nft?.attributes?.find(({ type }) => type === 'product') && !!contract}
        >
          <AnalyticsWrapper
            shouldAnalytics
            analyticsData={getAnalyticsData(
              AnalyticsType.FUNCTION,
              AnalyticsFunctionSubtype.PRODUCT,
              '',
              { companyId, nftId, contractId: nft?.contractId || '' }
            )}
          >
            <Link to={`/products/${contract?.id}/${nft?.tokenId}`}>
              <IconProduct aria-label="products" fill={COLORS.primary500} />
            </Link>
          </AnalyticsWrapper>
        </FeatureButton>

        {/* ECOMMERCE */}
        <FeatureButton show={!!nft.shop}>
          <AnalyticsWrapper
            shouldAnalytics
            analyticsData={getAnalyticsData(
              AnalyticsType.FUNCTION,
              AnalyticsFunctionSubtype.E_COMMERCE,
              '',
              { companyId, nftId, contractId: nft?.contractId || '' }
            )}
          >
            <Link to={`/${nft?.shop ? nft.shop.value : ''}`}>
              <IconEcommerce aria-label="ecommerce" fill={COLORS.primary500} />
            </Link>
          </AnalyticsWrapper>
        </FeatureButton>
      </div>
    </div>
  ) : (
    <></>
  );
}
