import { yupResolver } from '@hookform/resolvers/yup';
import type { ReactNode } from 'react';
import { Mode, useForm, UseFormStateProps } from 'react-hook-form';

import type {
  Control,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormRegisterReturn,
  UseFormResetField,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import type { OptionalObjectSchema } from 'yup/lib/object';

export type ExportedProps = {
  fields: { [key: string]: UseFormRegisterReturn };
  handleSubmit: UseFormHandleSubmit<any>;
  errors: any;
  isValid: boolean;
  resetField: UseFormResetField<any>;
  trigger: UseFormTrigger<any>;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
  control: Control;
  register: UseFormRegister<any>;
  formState: UseFormStateProps<any>;
};

type FormProps = {
  initialValues: {
    [key: string | number]: any;
  };
  validationSchema: OptionalObjectSchema<any>;
  children: (props: ExportedProps) => ReactNode;
  className?: string;
  mode?: Mode;
};

const Form = ({
  children,
  initialValues,
  validationSchema,
  className,
  mode = 'onSubmit',
}: FormProps) => {
  const resolver = yupResolver(validationSchema);

  const formState = useForm({
    defaultValues: initialValues,
    resolver,
    mode: mode,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    resetField,
    trigger,
    watch,
    setValue,
    control,
  } = formState;

  const fields = Object.keys(initialValues).reduce(
    (previousValue, currentValue) => ({ ...previousValue, [currentValue]: register(currentValue) }),
    {}
  );

  // Avoids problem with forwared ref
  const newRegister = (name: string) => {
    const { ref, ...other } = register(name);
    return { ...other, formRef: ref };
  };

  return (
    <form className={className}>
      {children({
        fields,
        errors,
        isValid,
        handleSubmit,
        resetField,
        trigger,
        watch,
        setValue,
        control,
        //@ts-ignore
        register: newRegister,
      })}
    </form>
  );
};

export default Form;
