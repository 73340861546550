import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import App from '../App';

export const router = createBrowserRouter([
  {
    path: '/',
    Component: App,
    children: [
      {
        path: '*',
        Component: lazy(() => import('../pages/error404/Error404')),
      },
      {
        path: 'messages',
        Component: lazy(() => import('../pages/messages/Messages')),
      },
      {
        path: 'messages/:companyId',
        Component: lazy(() => import('../pages/company-messages/CompanyMessages')),
      },
      {
        path: 'messages/companies',
        Component: lazy(() => import('../pages/messages/MutedCompanies')),
      },
      {
        path: 'finish-tc',
        Component: lazy(() => import('../pages/finish-tc/FinishTc')),
      },
      {
        path: 'sso-signup',
        Component: lazy(() => import('../pages/ssoStepper/SsoStepper')),
      },
      {
        path: 'tec',
        Component: lazy(() => import('../pages/tecStepper/TecStepper')),
      },
      {
        path: 'verify-email',
        Component: lazy(() => import('../pages/verify-email/VerifyEmail')),
      },
      {
        path: 'redeem',
        Component: lazy(() => import('../pages/redeem/Redeem')),
      },
      {
        path: 'game/:id',
        Component: lazy(() => import('../pages/game/Game')),
      },
      {
        path: 'login',
        Component: lazy(() => import('../pages/login/Login')),
      },
      {
        path: 'welcome',
        Component: lazy(() => import('../pages/welcome/Welcome')),
      },
      {
        path: 'register',
        Component: lazy(() => import('../pages/register')),
      },
      {
        path: 'forgot-password',
        Component: lazy(() => import('../pages/forgot-password/ForgotPassword')),
      },
      {
        path: 'collections/:contractId',
        Component: lazy(() => import('../pages/collections/Collection')),
      },
      {
        path: 'companies/:companyId',
        Component: lazy(() => import('../pages/companies/Company')),
      },
      {
        path: 'nfts/:contractId/:tokenId',
        Component: lazy(() => import('../pages/nfts/Nft')),
        children: [
          {
            path: 'certify',
            Component: lazy(() => import('../pages/nfts/certify/Certify')),
          },
          {
            path: 'transfer',
            Component: lazy(() => import('../pages/nfts/transfer/Transfer')),
          },
        ],
      },
      {
        path: 'share/nfts/:contractId/:tokenId',
        Component: lazy(() => import('../pages/nfts/ShareNft')),
        children: [
          {
            path: 'certify',
            Component: lazy(() => import('../pages/nfts/certify/Certify')),
          },
          {
            path: 'transfer',
            Component: lazy(() => import('../pages/nfts/transfer/Transfer')),
          },
        ],
      },

      {
        path: '/home',

        Component: lazy(() => import('../pages/home/Home')),

        children: [
          {
            path: 'nfts',
            Component: lazy(() => import('../pages/home/nfts/Nfts')),
          },
          // {
          //   path: 'followed',
          //   Component: lazy(() => import('../pages/home/followed/Followed')),
          // },
        ],
      },
      {
        path: '/profile',
        Component: lazy(() => import('../pages/profile/Profile')),
      },
      {
        path: '/settings/hiddennfts',
        Component: lazy(() => import('../pages/profile/hiddenNfts/HiddenNfts')),
      },
      {
        path: '/settings',
        Component: lazy(() => import('../pages/settings/Settings')),
      },
      {
        path: '/settings/support',
        Component: lazy(() => import('../pages/profile/support/Support')),
      },
      {
        path: '/settings/theme',
        Component: lazy(() => import('../pages/profile/theme/Theme')),
      },
      {
        path: '/settings/privacy',
        //@ts-ignore
        Component: lazy(() => import('../pages/profile/privacy/Privacy')),
      },
      {
        path: '/settings/privacy/companies',
        Component: lazy(() => import('../pages/profile/privacy/CompaniesPrivacy')),
      },
      {
        path: '/settings/backup',
        //@ts-ignore
        Component: lazy(() => import('../pages/profile/backup/Backup')),
      },
      {
        path: '/backup',
        //@ts-ignore
        Component: lazy(() => import('../pages/profile/backup/Backup')),
      },
      {
        path: '/settings/delete',
        //@ts-ignore
        Component: lazy(() => import('../pages/profile/deleteAccount/DeleteAccount')),
      },
      {
        path: '/settings/faq',
        //@ts-ignore
        Component: lazy(() => import('../pages/profile/FAQ/FAQPage')),
      },
      {
        path: '/redeem',
        //@ts-ignore
        // Component: 'Redeem',
        Component: lazy(() => import('../pages/redeem/Redeem')),
      },
      {
        path: '/polls/:contractAddress/:tokenId',
        //@ts-ignore
        async lazy() {
          const { Polls } = await import('../pages');
          return { Component: Polls };
        },
      },
      {
        path: '/poll/:contractAddress/:tokenId/:pollId',
        //@ts-ignore
        async lazy() {
          const { Poll } = await import('../pages');
          return { Component: Poll };
        },
      },
      {
        path: '/news/:contractId/:tokenId',
        //@ts-ignore
        async lazy() {
          const { News } = await import('../pages');
          return { Component: News };
        },
      },
      {
        path: '/news/:contractId/:tokenId/:newsId',
        //@ts-ignore
        async lazy() {
          const { ReadNews } = await import('../pages');
          return { Component: ReadNews };
        },
      },
      {
        path: '/products/:contractId/:tokenId',
        //@ts-ignore
        async lazy() {
          const { Products } = await import('../pages');
          return { Component: Products };
        },
      },
      {
        path: '/notarizations/:contractId/:tokenId',
        //@ts-ignore
        async lazy() {
          const { Notarizations } = await import('../pages');
          return { Component: Notarizations };
        },
      },
      {
        path: '/history/:contractId/:tokenId',
        //@ts-ignore
        async lazy() {
          const { History } = await import('../pages');
          return { Component: History };
        },
      },
      {
        path: '/history/:contractId/:tokenId/:metadataUri/:replacedAt',
        //@ts-ignore
        async lazy() {
          const { OldVersionNft } = await import('../pages');
          return { Component: OldVersionNft };
        },
      },
    ],
  },
]);
