import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconOffline } from '../../icons';

type NoInternetConnectionProps = {
  children: ReactElement;
};

const NoInternetConnection = ({ children }: NoInternetConnectionProps) => {
  const { t } = useTranslation();

  const [isOnline, setOnline] = useState(true);
  useEffect(() => {
    window.addEventListener('online', () => {
      setOnline(true);
    });
    window.addEventListener('offline', () => {
      setOnline(false);
    });
  }, []);

  return isOnline ? (
    children
  ) : (
    <div className="relative flex h-screen flex-col items-center justify-center gap-4 overflow-hidden ">
      <IconOffline className="w-[100px]" stroke="#3F8EFF" />
      <p className="text-body-bold-30">{t(`network.ops`)}</p>
      <p className="text-body-bold-20">{t(`network.offline`)}</p>
      <p className="text-body-bold-20">{t(`network.connect`)}</p>
    </div>
  );
};

export default NoInternetConnection;
