import { useContext } from 'react';
import { MiscContext } from '../../contexts/MiscContext';
import { useTheme } from '../../contexts/ThemeProvider';

export default function SettingsHeader() {
  const { getLogoImg } = useTheme();
  const { insets } = useContext(MiscContext);

  return (
    <div
      style={{ paddingTop: 16 + insets.top }}
      className="fixed inset-x-0 top-0 z-50 mx-auto flex w-full max-w-[768px] justify-between space-x-[18px] border-b border-grey-600 bg-white px-[30px] pb-4 dark:bg-black "
    >
      <img src={getLogoImg()} width={100} className="mx-auto" />
    </div>
  );
}
