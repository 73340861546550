import { SVGProps } from 'react';

const IconLock = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3309_23226)">
      <path
        d="M19.5 8.46582H4.5C4.08579 8.46582 3.75 8.80161 3.75 9.21582V19.7158C3.75 20.13 4.08579 20.4658 4.5 20.4658H19.5C19.9142 20.4658 20.25 20.13 20.25 19.7158V9.21582C20.25 8.80161 19.9142 8.46582 19.5 8.46582Z"
        stroke={props.fill ?? '#FFF8F8'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.625 8.46582V5.09082C8.625 4.19571 8.98058 3.33727 9.61351 2.70433C10.2465 2.0714 11.1049 1.71582 12 1.71582C12.8951 1.71582 13.7535 2.0714 14.3865 2.70433C15.0194 3.33727 15.375 4.19571 15.375 5.09082V8.46582"
        stroke={props.fill ?? '#FFF8F8'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3309_23226">
        <rect
          width={24}
          height={24}
          fill={props.fill ?? 'white'}
          transform="translate(0 0.21582)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default IconLock;
