import { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { useTheme } from '../../contexts/ThemeProvider';
import useClickOutsideToClose from '../../hooks/useClickOutsideToClose';
import { IconClose } from '../../icons';

export interface ModalProps extends PropsWithChildren {
  open: boolean;
  onClose: () => void;
  className?: string;
  canClickOutside?: boolean;
}
export default function Modal(props: ModalProps) {
  const { getBaseIconColor } = useTheme();

  const iconColor = getBaseIconColor();

  const domNode = useClickOutsideToClose(() => {
    if (!props.canClickOutside) {
      props.onClose();
    }
  });

  if (!props.open) {
    return <></>;
  }

  return createPortal(
    <div
      className="fixed inset-0 z-[100] flex h-screen w-full justify-center backdrop-brightness-50"
      ref={domNode}
    >
      <div
        className={`relative mt-[94px] h-min rounded-[10px] bg-grey-300 dark:bg-grey-600  ${props.className}`}
      >
        <IconClose
          role="button"
          onClick={props.onClose}
          className="absolute right-4 top-4"
          stroke={iconColor}
        />
        {props.children}
      </div>
    </div>,
    document.body
  );
}
