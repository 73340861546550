export class Range {
  start: number;
  end: number;

  constructor(start: number, end: number) {
    this.start = start;
    this.end = end;
  }
}

export class GameSceneConfig {
  static CITIES = ['set-one', 'set-two'];

  city: string = GameSceneConfig.CITIES[0];

  showFps: boolean = false;

  playerGravity: number = 700;
  jumpForce: number = 300;
  platformSpeed: number = 500;
  platformSpeedIncrease: number = 0.5;
  playerStartX: number = 200;
  playerMaxJumps: integer = 3;
  platformsCount: integer = 3;
  platformYOffset: Range = new Range(0, 100);
  platformsDistanceRange: Range = new Range(60, 250);
  platformColliderOffsetY: integer = 33;
  backgroundLevelsCount: integer = 5;
  backgroundSpeeds: Array<number> = [25, 100, 125, 200, 240];
  playerFramesCount: integer = 15;
  playerFrameRate: number = 30;
  playerColliderOffsetX: number = 20;
  playerColliderOffsetY: number = 30;
  playerColliderWidth: number = 65;
  playerColliderHeight: number = 80;

  collectibleDistanceRange: Range = new Range(2, 6);
  collectibleYOffsetRange: Range = new Range(50, 70);

  coinScore: number = 50;
  coinIdleAnimDuration: number = 1000;
  coinCollectAnimDuration: number = 500;

  diamondSpawnRate: number = 0.25;

  diamondScore: number = 250;
  diamondIdleAnimDuration: number = 1000;
  diamondCollectAnimDuration: number = 500;

  assetsFolder: string = '/assets/images/game/';

  scoreIncrease: number = 10;

  enableSpaceBar: boolean = true;

  serverAPIUrl: string = process.env.REACT_APP_BACKEND_ENDPOINT as string;

  gameVersionStr: string = '1.0.0';

  getPlatformAsset(index: integer): string {
    return this.assetsFolder + this.city + '/platforms/' + index + '.png';
  }

  getBackgroundAsset(index: integer): string {
    return this.assetsFolder + this.city + '/background/' + index + '.png';
  }

  getCoinAsset(): string {
    return this.assetsFolder + this.city + '/coin.png';
  }

  getDiamondAsset(): string {
    return this.assetsFolder + this.city + '/diamond.png';
  }
}
