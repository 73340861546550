import axios from 'axios';
import { DocumentData } from 'firebase/firestore';
import { t } from 'i18next';
import { ReactNode, useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import Modal, { useModal, useModalProps, useModalState } from 'react-simple-modal-provider';
import * as yup from 'yup';
import transferAbi from '../../abi/transfer';
import { getContractById } from '../../api/firebase';
import { Button, Form, Input } from '../../components';
import { firebaseEndpoint } from '../../imports/constants';
import { auth } from '../../imports/firebase';
import sdk from '../../imports/pablockSdk';
import { Contract } from '../../imports/types';
import { findContractById } from '../../imports/utils/nfts';
import { capitalizeFirstLetter } from '../../imports/utils/strings';
import { invokePromiseToast } from '../../imports/utils/toast';
import { required, validateAddress } from '../../imports/utils/validation';
import { updateTransferState } from '../../redux/contracts/contracts.slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import ModalLayout from '../layouts/ModalLayout';

interface TransferModalProps {
  children?: ReactNode;
}

const ModalBody = ({ setOpen }: any) => {
  const { goBack, contractId, tokenId } = useModalProps('TransferModal');
  const dispatch = useAppDispatch();

  const {
    contractsList,
    wallet: { privateKey, address },
    transferState,
  } = useAppSelector(({ contracts, user }) => ({
    contractsList: contracts.list,
    wallet: user.wallet,
    transferState: contracts.transferState,
  }));

  const [disableForm, setDisableForm] = useState(false);
  const form = {
    inititalValues: {
      wallet: '',
    },
    validationSchema: yup.object({
      wallet: required(validateAddress()),
    }),
  };
  //console.log(contractsList);
  const transferNft: SubmitHandler<(typeof form)['inititalValues']> = async (values) => {
    setDisableForm(true);
    const wallet = values.wallet.toLowerCase();
    const { ethers } = await import('ethers');

    if (!sdk.isInitialized()) {
      await sdk.init();
    }
    sdk.setPrivateKey(privateKey);

    try {
      let contract:
        | DocumentData
        | null
        | (Contract & {
            _id: string;
          })
        | undefined;
      /* check if we have the contract in local */
      contract = findContractById(contractsList, contractId);
      /* if not get it from db */
      if (!contract) {
        contract = await getContractById(contractId ?? '');
        if (!contract) {
          return null;
        }
      }

      const promise = new Promise(async (res, rej) => {
        try {
          const metaTx = await sdk.prepareTransaction(
            {
              address: contract!.address,
              abi: transferAbi,
              name: capitalizeFirstLetter(contract!.name).replace(/\s/g, ''),
              version: '1.0',
            },
            'safeTransferFrom',
            [address, wallet, tokenId, 1, ethers.utils.formatBytes32String('')]
          );
          const firebaseToken = (await auth.currentUser?.getIdToken()) as string;
          await axios.post(
            `${firebaseEndpoint}/executeTransfer`,
            {
              metaTx,
            },
            {
              headers: {
                'x-access-token': firebaseToken,
              },
            }
          );
          res({});
        } catch (e) {
          rej(e);
        }
      });

      invokePromiseToast(promise);
      await promise;
    } catch (error) {
      console.error(error);
      return error;
    } finally {
      setDisableForm(false);
      setOpen(false);
      goBack();
      dispatch(updateTransferState('pending'));
    }
  };

  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="flex w-full flex-col items-center justify-center space-y-6 px-7 pb-9">
        <p className="text-body-semibold-20">{t('nft.transfer.title') as string}</p>
        <Form initialValues={form.inititalValues} validationSchema={form.validationSchema}>
          {({ register, errors, handleSubmit, isValid, watch }) => {
            const walletAddress = watch('wallet');
            const isWalletValid = walletAddress === address ? false : true;
            return (
              <div className="flex flex-col space-y-6">
                <Input
                  forceLight
                  label={t('nft.transfer.walletlabel') as string}
                  placeholder={t('nft.transfer.walletplaceholder') as string}
                  error={!!errors.wallet || !isWalletValid}
                  errorMessage={isWalletValid ? '' : (t('nft.transfer.user-own-wallet') as string)}
                  {...register('wallet')}
                />
                {transferState === 'pending' ? (
                  <p className="text-body-regular-12">{t('nft.transfer.pending') as string}</p>
                ) : null}
                <Button
                  type={`${isValid ? 'primary' : 'secondary'}`}
                  className={`w-full`}
                  action={handleSubmit(transferNft)}
                  disabled={
                    disableForm || !isValid || !isWalletValid || transferState === 'pending'
                  }
                >
                  {t('nft.transfer.cta') as string}
                </Button>
                <Button type={'secondary'} className={`w-full`} action={() => setOpen(false)}>
                  {t('nft.transfer.close') as string}
                </Button>
              </div>
            );
          }}
        </Form>
      </div>
    </ModalLayout>
  );
};

export default function TransferModal({ children }: TransferModalProps) {
  const [isOpen, setOpen] = useModalState();
  const { open: openUnlockModal } = useModal('UnlockModal');

  const { needAction } = useAppSelector(({ contracts, user }) => ({
    needAction: user.needAction,
  }));

  useEffect(() => {
    if (isOpen && needAction === 'unlock') {
      setTimeout(() => openUnlockModal({}), 100);
    }
  }, [isOpen]);

  return (
    <Modal
      id="TransferModal"
      consumer={children}
      isOpen={isOpen}
      setOpen={setOpen}
      allowClickOutside={true}
    >
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
}
