import axios from 'axios';
import Phaser from 'phaser';
import { GameSceneConfig } from '../configs/gameSceneConfig';
import GameOverScene from './gameOverScene';

let globalCityIndex = 0;

export enum GameEvent {
  GameStarted = 'GAME_STARTED',
  GameOver = 'GAME_OVER',
}

export default class GameScene extends Phaser.Scene {
  private _levelConfig: GameSceneConfig;
  private _platformGroup!: Phaser.GameObjects.Group;
  private _platformPool!: Phaser.GameObjects.Group;
  private _coinGroup!: Phaser.GameObjects.Group;
  private _coinPool!: Phaser.GameObjects.Group;
  private _diamondGroup!: Phaser.GameObjects.Group;
  private _diamondPool!: Phaser.GameObjects.Group;
  private _player!: Phaser.Physics.Arcade.Sprite;

  private _smokeEmitter!: Phaser.GameObjects.Particles.ParticleEmitter;

  private _jumps: number = 0;

  private _nextPlatformDistance: number = 0;

  private _platformSpeed: number = 0;

  private _nextCoinDistance: integer = 0;

  private _nextCoinCounter: integer = 0;

  private _backgroundLevels: Phaser.GameObjects.TileSprite[] = [];

  private _scoreText!: Phaser.GameObjects.Text;

  private _fpsText!: Phaser.GameObjects.Text;

  private _score: number = 0;

  private _collectedCoinsCount: integer = 0;

  private _collectedDiamondsCount: integer = 0;

  private _infoText!: Phaser.GameObjects.Text;

  private _engineSound!: Phaser.Sound.BaseSound;
  private _backgroundSound!: Phaser.Sound.BaseSound;

  private _nftID: string = '';
  private _contractId: string = '';
  private _firebaseToken: string = '';
  private _userAddress: string = '';
  private _userName: string = '';
  private _playerAsset: string = '';
  private _highscore: number = 0;
  private _companyId: string = '';

  private _eventsCb: ((event: GameEvent, payload: any) => void) | null = null;

  constructor(levelConfig: GameSceneConfig = new GameSceneConfig()) {
    super('GameScene');
    this._levelConfig = levelConfig;
  }

  init(config: {
    tokenId: string;
    contractId: string;
    companyId: string;
    firebaseToken: string;
    address: string;
    name: string;
    highscore: number;
    eventsCb: ((event: GameEvent, payload: any) => void) | null;
    playerAsset: string;
  }) {
    this._nftID = config.tokenId;
    this._userAddress = config.address;
    this._userName = config.name;
    this._eventsCb = config.eventsCb;
    this._playerAsset = config.playerAsset;
    this._contractId = config.contractId;
    this._firebaseToken = config.firebaseToken;
    this._highscore = config.highscore;
    this._companyId = config.companyId;
  }

  preload() {
    const progressBox = this.add.graphics();
    const progressBar = this.add.graphics();

    const boxWidth = 320;
    const boxHeight = 50;
    const boxMargin = 10;
    const boxX = this.cameras.main.width / 2 - boxWidth / 2;
    const boxY = this.cameras.main.height / 2 - boxHeight / 2;

    progressBox.fillStyle(0xffec00, 1);
    progressBox.fillRoundedRect(boxX, boxY, boxWidth, boxHeight, 24);

    this.load.on('progress', (value: integer) => {
      progressBar.clear();
      progressBar.fillStyle(0x1dd7ff, 1);
      progressBar.fillRoundedRect(
        boxX + boxMargin,
        boxY + boxMargin,
        (boxWidth - boxMargin * 2) * value,
        boxHeight - boxMargin * 2,
        value < 0.1 ? 0 : 16
      );
    });

    this.load.on('complete', () => {
      progressBar.destroy();
      progressBox.destroy();

      this.cameras.main.setBackgroundColor(0);
    });

    if (globalCityIndex === 0) {
      // if (this.cache.json.has('ticket')) {
      //   this.cache.json.remove('ticket');
      // }
      // this.load.json('ticket', this._levelConfig.serverAPIUrl + '/ticket');
      this._eventsCb?.call(undefined, GameEvent.GameStarted, {});
    }

    if (this.cache.json.has('highscore')) {
      this.cache.json.remove('highscore');
    }

    axios
      .get(
        this._levelConfig.serverAPIUrl +
          `/highscore?contract=${this._contractId}&address=${this._userAddress}`,
        {
          headers: {
            authorization: this._firebaseToken,
          },
        }
      )
      .then(({ data }) => {
        //Had tp change method because this.load.json fetch automatically the json but we need authentication
        // this.load.json('highscore', data);
        this._highscore = data.score;
      })
      .catch((err) => console.error('ERRORE =>', err));

    // this.load.json('highscore', this._levelConfig.serverAPIUrl + '/highscore/');

    this.load.image('mute', '/assets/images/game/mute.png');
    this.load.image('unmute', '/assets/images/game/unmute.png');
    this.load.image('logoNFTF', '/assets/images/game/logo-violet.png');

    const city = GameSceneConfig.CITIES[globalCityIndex];

    this._levelConfig.city = city;

    for (let i = 0; i < this._levelConfig.platformsCount; i++) {
      const key = `platform${i}`;
      if (this.textures.exists(key)) {
        this.textures.remove(key);
      }
      this.load.image(key, this._levelConfig.getPlatformAsset(i));
    }

    for (let i = 0; i < this._levelConfig.backgroundLevelsCount; i++) {
      const key = `background${i}`;
      if (this.textures.exists(key)) {
        this.textures.remove(key);
      }
      this.load.image(key, this._levelConfig.getBackgroundAsset(i));
    }

    this.load.spritesheet('player', this._playerAsset, {
      frameWidth: 100,
      frameHeight: 150,
    });

    if (this.textures.exists('coin')) {
      this.textures.remove('coin');
    }

    this.load.spritesheet('coin', this._levelConfig.getCoinAsset(), {
      frameWidth: 100,
      frameHeight: 100,
    });

    // this.load.image('smoke', '/assets/images/game/smoke.png')

    if (this.textures.exists('diamond')) {
      this.textures.remove('diamond');
    }

    this.load.spritesheet('diamond', this._levelConfig.getDiamondAsset(), {
      frameWidth: 57,
      frameHeight: 61,
    });

    this.load.audio('loop1', '/assets/sounds/game/loop1.mp3');
    this.load.audio('engine', '/assets/sounds/game/robot.mp3');
  }

  create() {
    const w = this.game.config.width as number;
    const h = this.game.config.height as number;

    const scene = this;

    this._backgroundLevels = [];
    for (let i = 0; i < this._levelConfig.backgroundLevelsCount; i++) {
      const bgLevel = this.add.tileSprite(w * 0.5, h * 0.5, w, h, `background${i}`);

      this._backgroundLevels.push(bgLevel);
    }

    this._platformGroup = this.add.group({
      removeCallback: (platform: Phaser.GameObjects.GameObject) => {
        scene._platformPool.add(platform);
      },
    });

    this._platformPool = this.add.group({
      removeCallback: (platform: Phaser.GameObjects.GameObject) => {
        scene._platformGroup.add(platform);
      },
    });

    this._coinGroup = this.add.group({
      removeCallback: (coin: Phaser.GameObjects.GameObject) => {
        this.tweens.killTweensOf(coin);
        scene._coinPool.add(coin);
      },
    });

    this._coinPool = this.add.group({
      removeCallback: (coin: Phaser.GameObjects.GameObject) => {
        scene._coinGroup.add(coin);
      },
    });

    this._diamondGroup = this.add.group({
      removeCallback: (diamond: Phaser.GameObjects.GameObject) => {
        this.tweens.killTweensOf(diamond);
        scene._diamondPool.add(diamond);
      },
    });

    this._diamondPool = this.add.group({
      removeCallback: (diamond: Phaser.GameObjects.GameObject) => {
        scene._diamondGroup.add(diamond);
      },
    });

    this._jumps = 0;
    this._nextCoinDistance = 3;
    this._nextCoinCounter = 0;

    if (globalCityIndex === 0) {
      // Reset score if starting from the first city
      this._score = 0;
      this._collectedCoinsCount = 0;
      this._collectedDiamondsCount = 0;
      this._platformSpeed = this._levelConfig.platformSpeed;
    }

    let platformX = 0;
    for (let i = 0; i < this._levelConfig.platformsCount; i++) {
      const platform = this._spawnPlatform(i, platformX);
      platformX += platform.displayWidth + this._levelConfig.platformsDistanceRange.start;
    }

    if (this.anims.exists('player')) {
      this.anims.remove('player');
    }

    this.anims.create({
      key: 'player',
      frames: this.anims.generateFrameNumbers('player', { start: 0, end: 14 }),
      frameRate: 30,
      repeat: -1,
    });

    this._player = this.physics.add.sprite(this._levelConfig.playerStartX, 0, 'player0');
    this._player.play('player');
    this._player.depth = 10000;
    this._player.setGravityY(this._levelConfig.playerGravity);

    // Customize body size
    this._player.body!.setSize(
      this._levelConfig.playerColliderWidth,
      this._levelConfig.playerColliderHeight
    );
    this._player.body!.offset.y = this._levelConfig.playerColliderOffsetY;
    this._player.body!.offset.x = this._levelConfig.playerColliderOffsetX;

    this.add
      .image(w - 40, h - 70, 'logoNFTF')
      .setScale(0.2)
      .setOrigin(1, 0);

    // const highScore =
    //   (this.cache.json.get('highscore') ? this.cache.json.get('highscore').score : 0) || 0;

    const highScoreText = this.add
      .text(20, 20, 'High Score: ' + this._highscore, {
        fontFamily: 'Work Sans',
        fontSize: '21pt',
        shadow: {
          color: '#000000',
          fill: true,
          offsetX: 1,
          offsetY: 1,
        },
      })
      .setOrigin(0);

    this._scoreText = this.add
      .text(20, 20 + highScoreText.displayHeight + 10, 'Score: 0', {
        fontFamily: 'Work Sans',
        fontSize: '21pt',
        shadow: {
          color: '#000000',
          fill: true,
          offsetX: 1,
          offsetY: 1,
        },
      })
      .setOrigin(0);

    if (this._levelConfig.showFps) {
      this._fpsText = this.add.text(w - 140, 20, 'FPS: 60', {
        fontFamily: 'Work Sans',
        fontSize: '18pt',
      });
    }

    this._infoText = this.add.text(0, 0, '+1', {
      fontFamily: 'Work Sans',
      fontSize: '14pt',
      shadow: {
        offsetX: 2,
        color: '#000000',
        fill: true,
      },
    });
    this._infoText.setOrigin(0, 0).setVisible(false).setDepth(10000);

    // Adds collider between player and platforms
    this.physics.add.collider(this._player, this._platformGroup);

    // Configure collision between player and coins
    this.physics.add.overlap(
      this._player,
      this._coinGroup,
      (playerObj, coinObj) => {
        const coin = coinObj as Phaser.Physics.Arcade.Sprite;
        this._collect(coin, false);
      },
      undefined,
      this
    );

    // Configure collision between player and diamonds
    this.physics.add.overlap(
      this._player,
      this._diamondGroup,
      (playerObj, diamondObj) => {
        const diamond = diamondObj as Phaser.Physics.Arcade.Sprite;
        this._collect(diamond, true);
      },
      undefined,
      this
    );

    this.input.on('pointerdown', this._jump, this);

    if (this._levelConfig.enableSpaceBar) {
      const key = this.input.keyboard!.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);

      key.on('down', this._jump, this);
    }

    // const smokeParticles = this.add.particles('smoke');
    // smokeParticles.setDepth(1000);

    // this._smokeEmitter = smokeParticles.createEmitter({
    //   alpha: { start: 1, end: 0 },
    //   scale: { start: 0.2, end: 0.8 },
    //   speed: 20,
    //   accelerationX: -300,
    //   accelerationY: -200,
    //   angle: { min: -85, max: -95 },
    //   rotate: { min: -180, max: 1100 },
    //   lifespan: 1000,
    //   blendMode: 'ADD',
    //   frequency: 110,
    //   x: 0,
    //   y: 0,
    //   followOffset: {
    //     x: -this._player.width * 0.5 + 10,
    //     y: this._player.height - 85,
    //   },
    //   follow: this._player,
    //   tint: 0x505050,
    // });

    this._backgroundSound = this.sound.add('loop1');

    if (globalCityIndex === 0) {
      this.sound.stopAll();

      this._backgroundSound.play({
        loop: true,
        volume: 0.2,
        rate: 1.0,
      });
    } else {
      this.sound.stopByKey('engine');
    }

    this._engineSound = this.sound.add('engine');

    // this._engineSound.play({
    //   loop: false,
    //   volume: 0.4,
    //   rate: 1.0,
    // })

    if (this.anims.exists('coin')) {
      this.anims.remove('coin');
    }

    this.anims.create({
      key: 'coin',
      frames: this.anims.generateFrameNumbers('coin', { start: 0, end: 14 }),
      frameRate: 30,
      repeat: -1,
    });

    if (this.anims.exists('diamond')) {
      this.anims.remove('diamond');
    }

    this.anims.create({
      key: 'diamond',
      frames: this.anims.generateFrameNumbers('diamond', { start: 0, end: 57 }),
      frameRate: 30,
      repeat: -1,
    });

    // Audio button
    const audioButton = this.add
      .image(10, h - 10, this.sound.mute ? 'mute' : 'unmute')
      .setOrigin(0, 1)
      .setInteractive({ useHandCursor: true })
      .setScale(0.8)
      .setDepth(20000);

    audioButton.on('pointerdown', () => {
      const m = this.sound.mute;
      const textureKey = m ? 'unmute' : 'mute';
      audioButton.setTexture(textureKey);
      this.sound.mute = !m;
    });
  }

  update(_: number, deltaTime: number) {
    const deltaSeconds = deltaTime / 1000.0;

    const w = this.game.config.width as number;
    const h = this.game.config.height as number;

    this.events.on('destroy', () => {
      globalCityIndex = 0;
    });

    if (this._player.y > h) {
      this._gameOver();
      return;
    }

    if (this._levelConfig.showFps) {
      this._fpsText.setText(`FPS: ${Math.round(this.game.loop.actualFps)}`);
    }

    this._increaseScore(this._levelConfig.scoreIncrease * deltaSeconds);

    this._platformSpeed += this._levelConfig.platformSpeedIncrease * deltaSeconds;

    this._infoText.setPosition(
      this._player.x + 40,
      this._player.y + this._player.height * 0.5 - 80
    );

    for (let i = 0; i < this._backgroundLevels.length; i++) {
      const level = this._backgroundLevels[i];
      const inc = this._levelConfig.backgroundSpeeds[i] * deltaSeconds;
      level.tilePositionX += inc;
    }

    this._player.body!.x = this._levelConfig.playerStartX;

    let minDistance = w;

    this._platformGroup.getChildren().forEach((gameObj) => {
      const platform = gameObj as Phaser.Physics.Arcade.Sprite;
      const distance = w - platform.x - platform.displayWidth;
      if (distance < minDistance) {
        minDistance = distance;
      }

      if (platform.x < -platform.displayWidth) {
        this._platformGroup.killAndHide(platform);
        this._platformGroup.remove(platform);
      }
    }, this);

    this._coinGroup.getChildren().forEach((gameObj) => {
      const coin = gameObj as Phaser.Physics.Arcade.Sprite;
      if (coin.x < -coin.displayWidth) {
        this._coinGroup.killAndHide(coin);
        this._coinGroup.remove(coin);
      }
    }, this);

    if (minDistance < w && minDistance > this._nextPlatformDistance) {
      const platformIndex = Phaser.Math.Between(0, this._levelConfig.platformsCount - 1);
      this._spawnPlatform(platformIndex, w);
    }
  }

  private _showInfoText(text: string) {
    this._infoText.setText(text).setAlpha(1).setVisible(true);

    this.tweens.add({
      targets: this._infoText,
      alpha: 0,
      duration: 1000,
      onComplete: () => {
        this._infoText.setVisible(false);
      },
    });
  }

  private _collect(collectible: Phaser.Physics.Arcade.Sprite, isDiamond: boolean) {
    collectible.setActive(false).setVelocityX(0);

    collectible.body!.checkCollision.none = true;

    const animDuration = isDiamond
      ? this._levelConfig.diamondCollectAnimDuration
      : this._levelConfig.coinCollectAnimDuration;

    this.tweens.add({
      targets: collectible,
      duration: animDuration,
      y: this._scoreText.y,
      x: this._scoreText.x,
      alpha: 0,
      ease: 'Quadratic',
      onComplete: () => {
        const group = isDiamond ? this._diamondGroup : this._coinGroup;
        group.killAndHide(collectible);
        group.remove(collectible);
      },
      repeat: 0,
    });

    if (isDiamond) {
      this._collectedDiamondsCount++;
    } else {
      this._collectedCoinsCount++;
    }

    const score = isDiamond ? this._levelConfig.diamondScore : this._levelConfig.coinScore;

    this._increaseScore(score);

    this._showInfoText('+' + score);
  }

  private _getCollectible(isDiamond: boolean): Phaser.Physics.Arcade.Sprite {
    const pool = isDiamond ? this._diamondPool : this._coinPool;
    const group = isDiamond ? this._diamondGroup : this._coinGroup;

    if (pool.getLength() > 0) {
      const collectible = pool.getFirst();
      collectible.setActive(true).setVisible(true).setAlpha(1);
      collectible.body.checkCollision.none = false;
      pool.remove(collectible);
      return collectible;
    }

    const collectible = this.physics.add.sprite(0, 0, isDiamond ? 'diamond' : 'coin');

    if (isDiamond) {
      collectible.anims.play('diamond');
    } else {
      collectible.anims.play('coin');
    }

    collectible.setScale(0.5).setImmovable(true);

    group.add(collectible);

    return collectible;
  }

  private _spawnCollectible(
    x: number,
    y: number,
    isDiamond: boolean
  ): Phaser.Physics.Arcade.Sprite {
    const collectible = this._getCollectible(isDiamond);
    collectible.x = x;
    collectible.y = y;
    collectible.setVelocityX(-this._platformSpeed);

    const animDuration = isDiamond
      ? this._levelConfig.diamondIdleAnimDuration
      : this._levelConfig.coinIdleAnimDuration;

    this.tweens.add({
      targets: collectible,
      y: '-=50',
      duration: animDuration,
      ease: 'Linear',
      repeat: -1,
      yoyo: true,
    });

    return collectible;
  }

  private _getPlatform(index: integer): Phaser.Physics.Arcade.Sprite {
    const platformName = `platform${index}`;
    for (let p of this._platformPool.getChildren()) {
      const platform = p as Phaser.Physics.Arcade.Sprite;

      if (platform.name === platformName) {
        platform.setActive(true);
        platform.setVisible(true);

        this._platformPool.remove(platform);

        return platform;
      }
    }

    const platform = this.physics.add.sprite(0, 0, platformName);
    platform.setOrigin(0, 0.5);
    platform.body.setAllowGravity(false);
    platform.body.checkCollision.down = false;
    platform.setImmovable(true);
    platform.setVelocityX(-this._platformSpeed);

    platform.body.offset.y = this._levelConfig.platformColliderOffsetY;

    this._platformGroup.add(platform);

    return platform;
  }

  private _spawnPlatform(index: integer, x: number): Phaser.Physics.Arcade.Sprite {
    const platform = this._getPlatform(index);

    const yOffset = Phaser.Math.Between(
      this._levelConfig.platformYOffset.start,
      this._levelConfig.platformYOffset.end
    );

    const h = this.game.config.height as number;

    platform.x = x;
    platform.y = h - platform.height * 0.5 + yOffset;
    platform.setVelocityX(-this._platformSpeed);

    if (this._nextCoinCounter === this._nextCoinDistance) {
      const coinYOffset = Phaser.Math.Between(
        this._levelConfig.collectibleYOffsetRange.start,
        this._levelConfig.collectibleYOffsetRange.end
      );

      const isDiamond = Math.random() < this._levelConfig.diamondSpawnRate;

      this._spawnCollectible(
        x + platform.width * 0.5,
        platform.y - platform.height * 0.5 - coinYOffset,
        isDiamond
      );

      this._nextCoinCounter = 0;
      this._nextCoinDistance = Phaser.Math.Between(
        this._levelConfig.collectibleDistanceRange.start,
        this._levelConfig.collectibleDistanceRange.end
      );
    } else {
      this._nextCoinCounter++;
    }

    this._nextPlatformDistance = Phaser.Math.Between(
      this._levelConfig.platformsDistanceRange.start,
      this._levelConfig.platformsDistanceRange.end
    );

    return platform;
  }

  private _jump() {
    const canJump =
      this._player.body!.touching.down ||
      (this._jumps > 0 && this._jumps < this._levelConfig.playerMaxJumps);

    if (canJump) {
      if (this._player.body!.touching.down) {
        this._jumps = 0; // Reset jumps
      }

      this._player.setVelocityY(-this._levelConfig.jumpForce);
      this._jumps++;

      this._engineSound.play({
        loop: false,
        volume: 0.4,
        rate: 1.0 / this._jumps,
      });

      //this.tweens.killTweensOf(this._engineSound)

      // this.tweens.add({
      //   targets: this._engineSound,
      //   rate: 1.0 - this._jumps * 0.05,
      //   duration: 500,
      //   yoyo: true,
      //   onComplete: () => {
      //     // @ts-ignore
      //     this._engineSound.rate = 1
      //   },
      // })
    }
  }

  private _increaseScore(amount: number) {
    this._score += amount;
    this._scoreText.setText(`Score: ${Math.floor(this._score)}`);
  }

  private async _saveScore() {
    // const ticket = this.cache.json.get('ticket');
    // const userID = this._getUserID();
    const nftID = this._getNFTID();
    // POST highscore to server

    axios
      .post(
        this._levelConfig.serverAPIUrl + '/score',
        {
          ticket: '',
          userAddress: this._userAddress,
          name: this._userName,
          contract: this._contractId,
          company: this._companyId,
          tokenId: parseInt(nftID),
          score: this._score,
          coins: this._collectedCoinsCount,
          diamonds: this._collectedDiamondsCount,
          endTimestamp: Math.floor(Date.now() / 1000),
          gameVersion: this._levelConfig.gameVersionStr,
        },
        {
          headers: {
            authorization: this._firebaseToken,
          },
        }
      )
      .then((res) => {
        if (res.status !== 200) {
          console.log(`Failed to save score: `, res.statusText);
        }
      });
  }

  private _reloadScene() {
    globalCityIndex = (globalCityIndex + 1) % GameSceneConfig.CITIES.length;
    this.scene.start('GameScene');
  }

  private _loadGameOverScene() {
    this.scene.stop();
    this.scene.add('GameOverScene', GameOverScene, true, {
      score: this._score,

      config: {
        address: this._userAddress,
        tokenId: this._nftID,
        contractId: this._contractId,
        firebaseToken: this._firebaseToken,
        playerAsset: this._playerAsset,
        highscore: this._highscore,
        companyId: this._companyId,
        eventsCb: () => {},
      },
    });
    this.scene.remove('GameScene');
  }

  private _gameOver() {
    if (globalCityIndex === GameSceneConfig.CITIES.length - 1) {
      this._saveScore().then(() => {
        globalCityIndex = 0;
        this.textures.remove('player');
        this._loadGameOverScene();
      });
    } else {
      this._reloadScene();
    }
  }

  private _getUserID(): string {
    return this._userAddress || 'TestUser01';
  }

  private _getNFTID(): string {
    return this._nftID || '0';
  }
}
