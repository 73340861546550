import { SVGProps } from 'react';

const IconQrCode = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={37}
    height={37}
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.1084 7.19482H8.32534C7.70097 7.19482 7.19482 7.70097 7.19482 8.32534V15.1084C7.19482 15.7328 7.70097 16.2389 8.32534 16.2389H15.1084C15.7328 16.2389 16.2389 15.7328 16.2389 15.1084V8.32534C16.2389 7.70097 15.7328 7.19482 15.1084 7.19482Z"
      stroke="#642EFF"
      strokeWidth={2.26103}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1083 20.761H8.32522C7.70085 20.761 7.1947 21.2671 7.1947 21.8915V28.6746C7.1947 29.299 7.70085 29.8051 8.32522 29.8051H15.1083C15.7327 29.8051 16.2388 29.299 16.2388 28.6746V21.8915C16.2388 21.2671 15.7327 20.761 15.1083 20.761Z"
      stroke="#642EFF"
      strokeWidth={2.26103}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.6745 7.19482H21.8914C21.267 7.19482 20.7609 7.70097 20.7609 8.32534V15.1084C20.7609 15.7328 21.267 16.2389 21.8914 16.2389H28.6745C29.2988 16.2389 29.805 15.7328 29.805 15.1084V8.32534C29.805 7.70097 29.2988 7.19482 28.6745 7.19482Z"
      stroke="#642EFF"
      strokeWidth={2.26103}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.7609 20.761V25.283"
      stroke="#642EFF"
      strokeWidth={2.26103}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.7609 29.8051H25.2829V20.761"
      stroke="#642EFF"
      strokeWidth={2.26103}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.283 23.0221H29.805"
      stroke="#642EFF"
      strokeWidth={2.26103}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.8051 27.5441V29.8051"
      stroke="#642EFF"
      strokeWidth={2.26103}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconQrCode;
