import { ReactNode } from 'react';
import useSound from 'use-sound';
type SoundButtonProps = {
  action: () => void;
  children?: ReactNode;
  className?: string;
  id?: string;
  sound?: any;
};

const SoundButton = ({ action, children, className, id, sound }: SoundButtonProps) => {
  const [play] = useSound(sound);

  const handleSound = () => {
    play();
    action();
  };
  return (
    <button onClick={handleSound} id={id} className={`${className}`}>
      {children}
    </button>
  );
};

export default SoundButton;
