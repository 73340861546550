import { useState } from 'react';
import { useTheme } from '../../contexts/ThemeProvider';
import { IconBack } from '../../icons';
import { FAQQuestion } from '../../imports/types';

const FAQComponent = ({ question, answer }: FAQQuestion) => {
  const [showMore, setShowMore] = useState(false);
  const handleShomMore = () => {
    setShowMore(!showMore);
  };

  const { getBaseIconColor } = useTheme();

  return (
    <div
      className="w-full cursor-pointer rounded-[10px] bg-grey-300 p-4 dark:bg-grey-500"
      onClick={handleShomMore}
      key={question}
    >
      <div className="flex w-full items-center justify-between gap-1">
        <p className="text-body-semibold-20">{question}</p>
        <div>
          {showMore ? (
            <IconBack
              stroke={getBaseIconColor()}
              width={24}
              height={24}
              style={{ transform: 'rotate(90deg)' }}
            />
          ) : (
            <IconBack
              stroke={getBaseIconColor()}
              width={24}
              height={24}
              style={{ transform: 'rotate(-90deg)' }}
            />
          )}
        </div>
      </div>

      <p className={`${!showMore && 'hidden'} mt-2 whitespace-pre-line text-body-medium-16`}>
        {answer}
      </p>
    </div>
  );
};

export default FAQComponent;
