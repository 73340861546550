import { exists, t } from 'i18next';
import { toast } from 'react-toastify';

export function invokePromiseToast(promise: Promise<any>, messageHandler?: (prop: any) => string) {
  return toast.promise(promise, {
    pending: {
      render() {
        return <>Loading</>;
      },
    },
    error: {
      render({ data }) {
        console.error(data);
        return <>{messageHandler?.(data) ?? 'Error'}</>;
      },
    },
    success: {
      render() {
        return <>Success</>;
      },
    },
  });
}

export function getAuthErrorMessage(data: any) {
  const errorMessagesSupported = [
    'auth/email-already-in-use',
    'auth/wrong-password',
    'auth/too-many-requests',
    'auth/user-not-found',
  ];

  if (errorMessagesSupported.includes(data.code)) {
    return t(`auth.errors.${data.code}`);
  }

  return t(`auth.errors.generic`);
}

export const handleBEError = (errorMsg: string) => {
  console.error(errorMsg);
  const i18nKey = `be_errors.${errorMsg}`;
  if (exists(i18nKey)) {
    return t(i18nKey);
  }

  console.warn('[WARN] Error not mapped:', errorMsg);
  return t(`be_errors.generic`);
};
