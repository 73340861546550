import { OwnedNft } from 'alchemy-sdk';
import { ethers } from 'ethers';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { addCompany, addContract, addNft, addNftToContract } from '../../api/firebase';
import { formatIPFSUri } from '../../imports/utils/strings';
import { companyExistsOnDatabase, contractExistsOnDatabase } from '../../pages/home/nfts/query';
import { useAppSelector } from '../../redux/hooks';
import Button from '../button/Button';
import ModalLayout from '../layouts/ModalLayout';
type ExternalNftsModal = {
  children?: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { uid } = useAppSelector(({ user }) => ({ ...user }));

  const { externalNfts } = useModalProps('ExternalNftsModal');
  const [checked, setChecked] = useState<{ [key: string]: boolean }>({});

  const handleChecked = (address: string) => {
    setChecked({ ...checked, [address]: !checked[address] });
  };
  const { t } = useTranslation();

  const createOnDb = () => {
    externalNfts?.forEach((nft: OwnedNft) => {
      if (checked[nft.contract.address + '-' + nft.tokenId] && nft.contract.contractDeployer) {
        const alchemyContract = nft.contract;

        // controllo se esiste ed eventualmnte creo company
        const compAddress = ethers.utils.getAddress(nft.contract.contractDeployer);
        companyExistsOnDatabase(compAddress).then((res) => {
          if (!res) {
            const newCompany = {
              address: nft.contract.contractDeployer,
              bannerPic: '',
              bgColor: '',
              companyName: 'Unknown',
              cta: '',
              ctaLink: '',
              description: '',
              profilePic: '',
              projects: 0,
              textColor: '',
            };

            addCompany(newCompany, nft.contract.contractDeployer!);
          }
        });

        // creo nft da aggiungere
        const newNft = {
          attributes: nft.raw.metadata?.attributes ? nft.raw.metadata?.attributes : [],
          description: nft.raw.metadata?.description ? nft.raw.metadata.description : 'second',
          external_url: nft.raw.metadata?.external_url ? nft.raw.metadata.external_url : '',
          id: parseInt(nft.tokenId),
          image: nft.raw.metadata?.image ? nft.raw.metadata.image : '',
          name: nft.raw.metadata?.name ? nft.raw.metadata.name : 'test2',
          quantity: nft.raw.metadata?.quantity,
        };

        // controllo se il contratto esiste
        const address0 = ethers.utils.getAddress(nft.contract.address);

        contractExistsOnDatabase(address0).then((response) => {
          // non esiste? creo contratto
          if (!response) {
            const newContract = {
              address: address0,
              chain: 'Polygon',
              contractURI: 'unknown',
              createdAt: Math.floor(Date.now() / 1000),
              deployTx: 'unknown',
              description: alchemyContract?.openSeaMetadata?.description
                ? alchemyContract.openSeaMetadata.description
                : 'unknown',
              dropDate: null,
              error: '',
              expiration: 0,
              expirationFrom: 'contract',
              hasGame: false,
              id: alchemyContract.name + `${Math.floor(Date.now() / 1000)}`,
              image: alchemyContract?.openSeaMetadata?.imageUrl
                ? alchemyContract.openSeaMetadata.imageUrl
                : 'unknown',
              maxSupplyPerRarity: 'unknown',
              maxTokensPerUser: 'unknown',
              markleRoots: [],
              metadataUri: 'unknown',
              name: alchemyContract.name ? alchemyContract.name : 'unknown',
              owner: alchemyContract.contractDeployer,
              qrCodeDrop: null,
              status: 'created',
              symbol: 'unknown',
              tag: [],
              tokenBurn: 'unknown',
              transfer: '',
              transferAddresses: '',
              transferOwnership: 'unknown',
              type: alchemyContract.tokenType ? alchemyContract.tokenType : 'unknown',
              update: '',
              updateAddresses: '',
              updatedAt: Math.floor(Date.now() / 1000),
              useAsLogo: false,
              userId: 'EXTERNAL',
              workspace_id: 'EXTERNAL',
            };

            // aggiungo contratto
            addContract(newContract, newContract.id);
            // aggiungo l'nft al contratto
            addNftToContract(newNft, newContract.id);

            //   creo e aggiungo nft all'user
            const nftToVisualize = {
              contractAddress: newContract.address,
              contractId: newContract.id,
              createdAt: newContract.createdAt,
              id: newContract.id + '-' + nft.tokenId,
              quantity: nft.balance,
              status: 'success',
              tokenId: parseInt(nft.tokenId),
              transactionHash: '',
              updatedAt: new Date(nft.timeLastUpdated).getTime(),
            };

            addNft(nftToVisualize, nftToVisualize.id, uid);
          } else {
            // contratto esiste
            const contract = response[0];

            addNftToContract(newNft, contract.id);
            const nftToVisualize = {
              contractAddress: contract.address,
              contractId: contract.id,
              createdAt: contract.createdAt,
              id: contract.id + '-' + nft.tokenId,
              quantity: nft.balance,
              status: 'success',
              tokenId: parseInt(nft.tokenId),
              transactionHash: '',
              updatedAt: new Date(nft.timeLastUpdated).getTime(),
            };
            addNft(nftToVisualize, nftToVisualize.id, uid);
          }
        });
      }
    });
    setOpen(false);
  };

  return (
    <ModalLayout onClose={() => setOpen(false)} className="max-h-[70vh] max-w-[390px]">
      <div className="flex-col overflow-y-auto">
        <p className="mx-auto mb-2 text-center text-body-bold-16">{t('externalNftsModal.title')}</p>
        {externalNfts?.map((nft: any) => {
          if (nft.contract.contractDeployer) {
            return (
              <div key={`${nft.contract.address}-${nft.tokenId}`}>
                <div className="flex justify-around gap-6 ">
                  <div
                    className={`mb-2 max-w-[70%] rounded-md border-4  ${
                      checked[nft.contract.address + '-' + nft.tokenId]
                        ? 'rounded-md border-4  border-primary-500'
                        : 'border-grey-50'
                    }`}
                  >
                    <button onClick={() => handleChecked(nft.contract.address + '-' + nft.tokenId)}>
                      <div className="ml-1 text-left ">{nft.rawMetadata.name}</div>

                      <img
                        alt={nft.rawMetadata.name}
                        src={formatIPFSUri(nft?.rawMetadata?.image)}
                        className="w-[300px] p-1"
                      />
                    </button>
                  </div>

                  {/* <input
                  type="checkbox"
                  className="w-[20px] "
                  onChange={(e) => handleChecked(e, nft.contract.address + '-' + nft.tokenId)}
                ></input> */}
                </div>
              </div>
            );
          }
        })}
      </div>
      {externalNfts?.length && (
        <Button action={createOnDb} className="m-4 mt-7 max-w-[70%] p-3">
          {t('externalNftsModal.button')}
        </Button>
      )}
    </ModalLayout>
  );
};
const ExternalNftsModal = ({ children }: ExternalNftsModal) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useModalState();

  return (
    <Modal id="ExternalNftsModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default ExternalNftsModal;
