import { get, limitToLast, orderByChild, query, ref, set } from 'firebase/database';
import { PublicCompany } from '../contexts/FirestoreContext';
import { database } from '../imports/firebase';

export interface Message {
  _id: string;
  title: string;
  description: string;
  createdAt: number;
  expiresAt: number;
  dropDate: number;
  hide: boolean;
  read: boolean;
  image?: string;
}

export interface Messages {
  company: PublicCompany;
  messages: Message[];
}
// TODO: Optimize - Michele
export const getMessages = async (publicCompanies: PublicCompany[], uid: string) => {
  let messages = await Promise.all(
    publicCompanies.map(async (company) => {
      const queryMsgs = query(
        ref(database, `receivers/${uid}/${company.address}`),
        orderByChild('hide'),
        limitToLast(10)
      );

      const snap = await get(queryMsgs);
      const val = snap.val();

      // if (val == null) return;
      const welcomeMessages = await Promise.all(
        [''].map(async () => {
          const queryMsg = query(ref(database, `messages/${company.address}/w`));

          const msg = await get(queryMsg);
          const status = val?.w;
          return { _id: 'w', ...status, ...msg.val() } as Message;
        })
      );

      let messages: Message[] = [];

      if (val) {
        messages = await Promise.all(
          Object.keys(val)?.map(async (messageId: string) => {
            const queryMsg = query(ref(database, `messages/${company.address}/${messageId}`));

            const msg = await get(queryMsg);
            const status = val[messageId];
            return { _id: messageId, ...status, ...msg.val() } as Message;
          })
        );
      }
      const dateNow = Date.now();
      return {
        company,
        messages: messages
          .concat(welcomeMessages)
          .filter(
            (msg) =>
              msg.title &&
              !msg.hide &&
              (msg.dropDate < dateNow || !msg.dropDate) &&
              (msg.expiresAt > dateNow || !msg.expiresAt)
          ),
      };
    })
  );

  messages = messages.filter((msg) => msg != null);
  messages = messages.filter((msg) => !!msg?.messages.length);

  return messages as Messages[];
};

export const readMessage = async (uid: string, companyAddress: string, messageId: string) => {
  const queryMessageStatus = ref(database, `receivers/${uid}/${companyAddress}/${messageId}`);
  const messageStatusSnap = await get(queryMessageStatus);
  const messageStatus = messageStatusSnap.val();
  await set(messageStatusSnap.ref, { ...messageStatus, read: true });
};
