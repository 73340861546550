import { SVGProps } from 'react';

const IconPoll = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.fill}
    strokeWidth="1"
    strokeLinecap="square"
    strokeLinejoin="miter"
    color="#000000"
    transform="rotate(-90)"
  >
    <path d="M4 4V20" stroke={props.fill} /> <path d="M4 6H15V10H4" stroke={props.fill} />
    <path d="M4 10H19V14H4" stroke={props.fill} /> <path d="M4 14H12V18H4" stroke={props.fill} />
  </svg>
);

export default IconPoll;
