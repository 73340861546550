import { useQuery } from '@tanstack/react-query';
import {
  collection,
  DocumentChange,
  DocumentData,
  getDocs,
  query,
  QueryDocumentSnapshot,
  where,
} from 'firebase/firestore';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { useModal } from 'react-simple-modal-provider';
import {
  arrayBatch,
  getCompaniesFromContracts,
  getContractsWithNfts,
  updateUser,
} from '../api/firebase';
import useNftSubscriptions from '../hooks/useNftSubscription';
import { db } from '../imports/firebase';
import { Contract, Nft, NftAttributes } from '../imports/types';
import { findContractById, findNftFromContracts, getLastNftUpdate } from '../imports/utils/nfts';
import { loadCompanies } from '../redux/companies/companies.slice';
import { loadContracts, updateContractNft } from '../redux/contracts/contracts.slice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { updateUserData } from '../redux/user/user.slice';

export interface UsersNft {
  _id?: string;
  contractAddress: string;
  contractId: string;
  mint: boolean;
  status: 'success' | 'failed' | 'pending';
  tokenId: number;
  updatedAt: number;
  createdAt?: number;
  quantity: number;
  transfer?: boolean;
  transfersCount?: number;
  transactionHash?: string;
  hide?: boolean;
  favorite?: boolean;
  old_owners?: string[];
}

export interface ContractsNft {
  _id: string;
  id: number;
  name: string;
  description: string;
  quantity: number;
  external_url?: string;
  image: string;
  // attributes?: { trait_type: string; value: string; link?: string; type: string }[];
  attributes: NftAttributes[];
  updatedAt: number;
  contractNftUpdatedAt?: number;
}
export interface ContractNft {
  contractId: string;
  nfts: ContractsNft[];
}
export interface PublicCompany {
  website?: string;
  _id: string;
  address: string;
  companyName: string;
  bannerPic?: string;
  textColor?: string;
  bgColor?: string;
  cta?: string;
  ctaLink?: string;
  description?: string;
  profilePic?: string;
  privacyPolicy?: string;
  reserved: boolean;
  projects: number;
}

/**
 * Fetches all the contracts from the `contracts/` collection associated with a given list of NFTs
 * @notice `_id` is the snapshot id
 */
export const getContractsFromUsersNft = async (
  usersNfts: UsersNft[],
  contractsList: Contract[],
  contractsIds: string[]
): Promise<Array<Contract & { _id: string }>> => {
  const uniqueContracts = Array.from(
    new Set(usersNfts.map((nft) => nft.contractId as string))
  ).filter((id) => !contractsIds.includes(id));
  const batches = arrayBatch(uniqueContracts, 10); // let's optimize by doing query in batches!
  const contracts = (
    await Promise.all(
      batches.map(async (batch) => {
        const contractRefs = await getDocs(
          query(collection(db, 'contracts'), where('id', 'in', batch))
        );

        return contractRefs.docs.map((doc) => {
          const currentContract = findContractById(contractsList, doc.id) || {};
          return { ...currentContract, _id: doc.id, ...(doc.data() as Contract) };
        });
      })
    )
  ).flat();

  return contracts;
};

/**
 * @description Funzione per fetchare contratto e relativi NFTs, li unisce all'NFT dell'utente e
 * li salva in redux
 *
 * @param usersNfts
 * @param contractsList
 * @param companiesIds
 * @param dispatch
 */
const fetchNfts = async (
  usersNfts: UsersNft[],
  contractsList: Contract[],
  companiesIds: string[],
  dispatch: any
) => {
  const contractsIds = contractsList.map(({ id }) => id);

  // Array di id contratti non prensenti in redux
  const uniqueContractsIds = Array.from(
    new Set(
      usersNfts
        .filter(({ _id, contractId }) => {
          const contract = contractsList?.find(({ id }) => id == contractId);

          return (
            !contractsIds.includes(contractId) ||
            (contractsIds.includes(contractId) &&
              contract &&
              !contract.nfts?.some((nft: Nft) => nft._id === _id))
          );
        })
        .map(({ contractId }) => contractId)
    )
  );

  const batches = arrayBatch(uniqueContractsIds, 10); // let's optimize by doing query in batches!

  // Contratti presi dal DB che non ho in redux
  const contracts = (
    await Promise.all(
      batches.map(async (batch) => {
        const contractRefs = await getDocs(
          query(collection(db, 'contracts'), where('id', 'in', batch))
        );

        return contractRefs.docs.map((doc) => {
          const currentContract = findContractById(contractsList, doc.id) || {};
          return { ...currentContract, _id: doc.id, ...(doc.data() as Contract) };
        });
      })
    )
  ).flat();

  const contractsWithNfts = await getContractsWithNfts(usersNfts, contracts);
  dispatch(loadContracts(contractsWithNfts));
  /**
   * COMPANY/ where id (=address) is in Contract
   */
  const companies = await getCompaniesFromContracts(
    contractsWithNfts.filter(({ owner }: Contract) => !companiesIds.includes(owner))
  );
  dispatch(loadCompanies(companies));
};

const ContractsWrapper = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const { open: openTransferNftConfirmation } = useModal('TransferNftConfirmation');

  const { uid, companiesIds, contractsList, lastNewNftCheck, profile } = useAppSelector(
    ({ user, companies, contracts }) => ({
      uid: user.uid,
      companiesIds: companies.companiesIds,
      contractsList: contracts.list,
      lastNewNftCheck: user.lastNewNftCheck || 0,
      profile: user,
    })
  );

  const updateLastCheck = async () => {
    const lastCheckDate = Date.now();

    try {
      const { value, error } = await updateUser(
        {
          lastNewNftCheck: lastCheckDate,
        },
        profile
      );

      if (error) {
        console.error(error);
        return;
      }

      if (value !== null && value !== undefined) {
        dispatch(updateUserData({ ...value }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const {
    data: userNftDocs,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['users-nfts', uid],

    queryFn: () =>
      getDocs(
        query(
          collection(db, 'users', uid, 'nfts'),
          where('updatedAt', '>', getLastNftUpdate(contractsList))
        )
      ),
  });

  /**
   * useEffect che elabora i dati ottenuti dalla query sugli NFTs dell'utente
   */

  useEffect(() => {
    /**
     * Gestisce modifiche agli NFT locali. Aggiorna i dati locali se presenti.
     *
     * @param docs - Un array di oggetti DocumentChange che rappresentano gli NFT modificati.
     * @returns Un oggetto che contiene gli NFT aggiunti e lo stato degli NFT ('added' o 'modified').
     */
    const handleNftChanges = async (docs: DocumentChange<DocumentData>[]) => {
      const addedNfts: UsersNft[] = [];
      docs.forEach((changedNft) => {
        const userNft = { _id: changedNft?.doc.id, ...changedNft?.doc.data() } as UsersNft;
        // Aggiorna i dati dell'NFT locale se esistente
        const localNft = findNftFromContracts(
          contractsList,
          userNft.contractId,
          userNft.tokenId.toString()
        );
        if (localNft) {
          dispatch(
            updateContractNft({
              nftToUpdate: localNft,
              newData: {
                ...localNft,
                contractAddress: userNft.contractAddress,
                contractId: userNft.contractId,
                createdAt: userNft.createdAt,
                favorite: userNft.favorite,
                hide: userNft.hide,
                quantityUser: userNft.quantity,
                mint: userNft.mint,
                status: userNft.status,
                tokenId: userNft.tokenId,
                transactionHash: userNft.transactionHash,
                transfer: userNft.transfer,
                transfersCount: userNft.transfersCount,
                updatedAt: userNft.updatedAt,
              },
            })
          );
        }

        const isTokenReceived = userNft.old_owners && userNft.old_owners?.length > 0;
        // Se il tipo di change é added aggiunge a addedNfts
        /* aggiunto il userNft.updatedAt > lastNewNftCheck che permette di evitare l'apertura del modale al login (sono tutti added e in locale lo stato è ancora vuoto) */
        /* aggiunto isTokenReceived perchè se è un redeem non ci sono old_owners e il modale non deve aprirsi  */
        if (
          changedNft.type === 'added' &&
          changedNft.doc.data().status === 'success' &&
          userNft.updatedAt > lastNewNftCheck &&
          isTokenReceived
        ) {
          addedNfts.push(userNft);
        }

        // Se il tipo di change fa un update dei dati dell'utente per triggerare modifiche su redux
        if (changedNft.type === 'modified' && changedNft.doc.data().status === 'success') {
          updateUser({ lastNewNftCheck: Date.now() }, { uid }).then(({ value, error }) => {
            if (error) {
              console.error('saving on DB error: ', error);
            } else {
              dispatch(updateUserData({ ...value }));
            }
          });
        }
      });

      return { addedNfts };
    };

    const openModal = async (addedNfts: UsersNft[]) => {
      if (addedNfts && addedNfts.length > 0) {
        //check if the person is sending or receiving the nft by confronting the quantityUser of the addedNfts to the quantity of the nft in the contractsList
        const areNftsSent = addedNfts.filter((nft) => {
          const newNft = findNftFromContracts(
            contractsList,
            nft.contractId,
            nft.tokenId.toString()
          );
          if (newNft) {
            // per evitare di avere il modale di ricezione anche per chi invia
            if (nft.quantity > newNft.quantityUser) {
              return newNft;
            }
          } else {
            // se non trovo l'nft nel contratto vuol dire che non possiedo ancora quel token e quindi lo sto ricevendo
            return nft;
          }
        });

        const isSender = areNftsSent.length > 0 ? false : true;

        openTransferNftConfirmation({ addedNfts, isSender });
        updateLastCheck();
      }
    };

    /**
     * Recupera in contratti basandosi sugli Nft dell'utente.
     */
    const handleFetchContracts = async () => {
      const usersNfts = userNftDocs?.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) => doc.data() as UsersNft
      );
      if (usersNfts && usersNfts?.length > 0) {
        await fetchNfts(usersNfts, contractsList, companiesIds, dispatch).catch(console.error);
      }
    };

    const fun = async () => {
      if (userNftDocs != null && userNftDocs?.size > 0) {
        console.info('[Info] - Users Nft changed!');
        const { addedNfts } = await handleNftChanges(userNftDocs.docChanges());

        console.info('[Info] - Fetching contracts');
        await handleFetchContracts();
        await openModal(addedNfts);
      }
    };

    fun();
  }, [userNftDocs]);
  /**
   * Adding the contractsList dependancy allow the retrigger of the query
   * With the retrigger the lastNftUpdate gets update and the query itś not going to fetch
   * data modified by hand
   */
  useNftSubscriptions(uid, contractsList);

  if (error) {
    console.error(error);
  }

  return (
    <>
      <div style={{ display: isLoading ? 'none' : 'flex' }} className="h-full">
        {children}
      </div>
    </>
  );
};

const ContractsProvider = ({ children }: PropsWithChildren) => {
  const { uid } = useAppSelector((state) => state.user);

  if (!uid) {
    return <>{children}</>;
  }

  return <ContractsWrapper>{children}</ContractsWrapper>;
};

export default ContractsProvider;
