import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device, DeviceInfo, OperatingSystem } from '@capacitor/device';
import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logOut, updateUser } from './api/firebase';
import { StoresRedirect } from './components';
import BottomBar from './components/layouts/BottomBar';
import { AuthContext } from './contexts/AuthContext';
import { MiscContext } from './contexts/MiscContext';
import { useCheckAppleVersion } from './hooks/useCheckAppleVersion';
import useRedirect from './hooks/useRedirects';
import { IconLoading } from './icons';
import {
  APP_VERSION,
  MOBILE_BROWSER_ENABLE_ROUTES,
  PUBLIC_ROUTES,
  currentEnv,
  environments,
} from './imports/constants';
import { pushNotificationInit } from './imports/firebase';
import { enableRemoteConfig } from './imports/remoteConfig';
import { logOutCompanies } from './redux/companies/companies.slice';
import { logOutContracts } from './redux/contracts/contracts.slice';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { logOut as logOutAction, setFCM, updateUserData } from './redux/user/user.slice';

const App = () => {
  useCheckAppleVersion();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { check } = useRedirect();
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector(({ user, contracts }) => ({
    ...user,
    profile: user,
    contractList: contracts.list,
  }));
  // const state = useContext(FirestoreContext);
  // const { open: openTransferNftrConfirmation } = useModal('TransferNftConfirmation');
  const { insets } = useContext(MiscContext);
  const [deviceOperatingSystem, setDeviceOperatingSystem] = React.useState<OperatingSystem | null>(
    null
  );

  useEffect(() => {
    enableRemoteConfig();

    if (localStorage.getItem('version') !== APP_VERSION) {
      localStorage.setItem('version', APP_VERSION);

      if (profile) {
        logOut().then((value) => {
          if (value) {
            dispatch(logOutContracts());
            dispatch(logOutCompanies());
            dispatch(logOutAction());
          }
        });
      }
    }

    /**
     * DO NOT TOUCH THIS FUNCTION
     */
    const checkMobile = async () => {
      if (Capacitor.isNativePlatform() && ['ios', 'android'].includes(Capacitor.getPlatform())) {
        pushNotificationInit({
          cb: (value) => {
            dispatch(setFCM({ fcm: value }));
          },
          redirect: navigate,
        });

        let alreadyOpened = false;
        CapacitorApp.addListener('appUrlOpen', (data) => {
          alreadyOpened = true;
          if (
            data?.url
            // && (!needAction || needAction === 'conversion' || (!uid && needAction === 'unsetted'))
          )
            navigate(data.url.replace(process.env.REACT_APP_URL!, ''));
        });

        if (!alreadyOpened) {
          const data = await CapacitorApp.getLaunchUrl();
          if (
            data?.url
            // && (!needAction || needAction === 'conversion' || (!uid && needAction === 'unsetted'))
          )
            navigate(data.url.replace(process.env.REACT_APP_URL!, ''));
        }
      }
    };
    checkMobile();

    /**
     * Get the device operating system
     */
    const getDeviceOperatingSystem = async () => {
      const response: DeviceInfo = await Device.getInfo();
      setDeviceOperatingSystem(response.operatingSystem);
    };
    getDeviceOperatingSystem();
  }, []);

  const updateLastCheck = async () => {
    const lastCheckDate = Date.now();

    const { value } = await updateUser(
      {
        lastNewNftCheck: lastCheckDate,
      },
      profile
    );
    dispatch(updateUserData({ ...value }));
  };

  useEffect(() => {
    const isPublic = PUBLIC_ROUTES.some((route) => window.location.pathname.startsWith(route));
    if (location.pathname === '/') {
      navigate('/home');
    }
    if (user === null && !isPublic) {
      navigate('/welcome');
      return;
    }
    if (user) {
      check();
    }
  }, [user, location.pathname]);

  return (
    <React.Fragment>
      {(Capacitor.isNativePlatform() && ['ios', 'android'].includes(Capacitor.getPlatform())) ||
      MOBILE_BROWSER_ENABLE_ROUTES.some((route) => window.location.pathname.startsWith(route)) ||
      [environments.DEV, environments.LOCAL, environments.PREPROD, environments.DEMO].includes(
        currentEnv
      ) ? (
        <React.Fragment>
          {user === undefined ? (
            <div className="flex h-screen w-screen items-center justify-center">
              <IconLoading className="h-12 w-12 animate-spin" />
            </div>
          ) : (
            <React.Fragment>
              <main
                className="relative mx-auto h-screen w-full overflow-x-hidden"
                style={{
                  marginTop: 0,
                  paddingTop: insets.top || 0,
                }}
              >
                <Outlet />
                <BottomBar />
              </main>
              <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{ zIndex: 10000, marginTop: insets.top }}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <StoresRedirect os={deviceOperatingSystem} />
      )}
    </React.Fragment>
  );
};

export default App;
