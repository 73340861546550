import { InputHTMLAttributes, LegacyRef, ReactNode } from 'react';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: ReactNode;
  className?: string;
  formRef?: LegacyRef<HTMLInputElement>;
  error?: boolean;
  id?: string;
  checkSquareClass?: string;
  divClass?: string;
  checkDiv?: string;
  cy?: string;
}

const Checkbox = (props: CheckboxProps) => {
  const {
    className,
    label,
    formRef,
    error,
    cy,
    checkSquareClass,
    divClass,
    checkDiv,
    ...inputProps
  } = props;

  return (
    <div
      className={`flex w-full gap-2 space-x-[8px] ${divClass} ${error ? 'shadow-inputError' : ''}`}
    >
      <div className={`${checkDiv}`}>
        <input
          {...inputProps}
          type="checkbox"
          ref={formRef}
          data-cy={cy}
          className={`${checkSquareClass}`}
        />
      </div>
      <p className={className}>{label}</p>
    </div>
  );
};

export default Checkbox;
