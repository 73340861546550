import { useState } from 'react';
import { formatIPFSUri } from '../../imports/utils/strings';

interface NftImageProps {
  src: string;
  nftName: string;
}

export default function NftImage({ nftName, src }: NftImageProps) {
  const [isAvailable, setIsAvailable] = useState(true);

  return isAvailable ? (
    <img
      alt={nftName}
      src={formatIPFSUri(src)}
      width={258}
      height={145}
      // className="h-[145px] w-[258px] rounded-[10px] object-cover" // 16:9 Aspect Ratio
      className="h-[175px] w-[175px] rounded-[10px] border border-black object-fill dark:border-white" // 1:1 Aspect Ratio
      onError={() => setIsAvailable(false)}
    />
  ) : (
    <div className="h-[175px] w-[175px] animate-pulse rounded-[10px] border border-black object-fill dark:border-white" />
  );
}
