import { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import Button from '../button/Button';
import ModalLayout from '../layouts/ModalLayout';
interface PendingRedeemModalProps {
  children?: ReactNode;
}

const ModalBody = ({ setOpen }: any) => {
  const { goTo } = useModalProps('PendingRedeemModal');
  const { t } = useTranslation();

  return (
    <ModalLayout>
      <div className="w-full max-w-[600px] p-7">
        <p className="mx-auto mb-2 text-center text-body-medium-14">
          {t(`redeem.wait_for_pending`)}
        </p>
        <div className="mt-4 flex justify-center">
          <Button
            className="w-[90%] max-w-[400px]"
            type="secondary"
            action={() => {
              setOpen(false);
              goTo();
            }}
          >
            {t(`redeem.back_home`)}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};

const PendingRedeemModal = ({ children }: PendingRedeemModalProps) => {
  const [isOpen, setOpen] = useModalState();

  return (
    <Modal
      id="PendingRedeemModal"
      consumer={children}
      isOpen={isOpen}
      setOpen={setOpen}
      allowClickOutside={false}
    >
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default PendingRedeemModal;
