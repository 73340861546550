import { Capacitor } from '@capacitor/core';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import axios from 'axios';
import { User, getIdToken } from 'firebase/auth';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { BASE64_TYPE, backendEndpoint, paidIpfsGateway } from '../constants';
import { auth } from '../firebase';
import { StorageType } from '../types';
import { getFileFromStorage } from './notarizations';
import { formatIPFSUri } from './strings';

export const formatUri = async (uri: string, isPrivate: boolean, user: User | null | undefined) => {
  if (isPrivate && user) {
    const {
      data: { url },
    } = await axios.get(`${backendEndpoint}/content/${uri}`, {
      headers: { Authorization: await getIdToken(user) },
    });
    return url;
  } else {
    return formatIPFSUri(uri);
  }
};

/**
 * @description This function handle the image fetch and base64 conversion
 * @param url
 * @returns  {base64:string, ext:string} Object
 */
const imageUrlToBase64 = async (url: string) => {
  const response = await fetch(url, {
    method: 'GET',
  });
  const blob = await response.blob();

  const ext = blob.type.split('/')[1];

  /**
   * The FIRST part of condition is used to convert the octect-stream received
   * from S3 into a readable base64
   * It'll be need anymore when we'll fix the backend upload from buffer to Blob.
   * In order to load on S3 also the file type
   */
  if (ext === 'octet-stream') {
    const partialBase64 = Buffer.from(await blob.arrayBuffer()).toString('base64');
    const base64 = `${BASE64_TYPE[partialBase64.substring(0, 3)]}${partialBase64}`;

    return { base64, ext: /^data:(.+);base64,/.exec(base64)?.[1]?.split('/')?.[1] };
  } else {
    return new Promise((onSuccess, onError) => {
      try {
        const reader = new FileReader();
        reader.onload = function () {
          onSuccess({ base64: this.result, ext });
        };
        reader.readAsDataURL(blob);
      } catch (e) {
        onError(e);
      }
    });
  }
};

const getFileExternalUrl = async ({ uri, storage }: { uri: string; storage: StorageType }) => {
  if (uri && storage === 'bcode' && auth.currentUser) {
    const authTokenId = await getIdToken(auth.currentUser);
    const res = await getFileFromStorage(uri, authTokenId);
    return res.value.url;
  }

  if (uri && storage === 'ipfs') {
    return `${paidIpfsGateway}/${uri}`;
  }

  return '';
};

export const downloadWalletJson = async (wallet: {
  address: string;
  privateKey: string;
  mnemonic: string;
}) => {
  try {
    if (Capacitor.isNativePlatform() && ['ios', 'android'].includes(Capacitor.getPlatform())) {
      const result = await Filesystem.writeFile({
        path: `wallet-${Date.now()}.json`,
        data: JSON.stringify(wallet),
        // directory: Capacitor.getPlatform() === 'ios' ? Directory.Documents : Directory.Data,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
        recursive: true,
      });
      if (result.uri) {
        toast.success(t('account.download_wallet_notification.success') as string);
      } else {
        toast.error(t('account.download_wallet_notification.error') as string);
      }
    } else {
      const walletJson = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(wallet)
      )}`;
      const anchor = document.createElement('a');
      anchor.href = walletJson;
      anchor.download = 'wallet.json';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }
  } catch (e) {
    console.error('Unable to write file', e);
    toast.error(t('account.download_wallet_notification.error') as string);
  }
};

export const downloadFile = async ({
  uri,
  fileName,
  storage,
}: {
  uri: string;
  fileName: string;
  storage: StorageType;
}) => {
  try {
    if (Capacitor.isNativePlatform() && ['ios', 'android'].includes(Capacitor.getPlatform())) {
      const url = await getFileExternalUrl({ uri, storage });

      const { base64: data, ext } = (await imageUrlToBase64(url)) as {
        base64: string;
        ext: string;
      };

      const result = await Filesystem.writeFile({
        path: `${fileName}.${ext}`,
        data,
        // directory: Capacitor.getPlatform() === 'ios' ? Directory.Documents : Directory.Data,
        directory: Directory.Documents,
        recursive: true,
      });
      if (result.uri) {
        toast.success(t('download.success') as string);
      } else {
        toast.error(t('download.error') as string);
      }
    }
  } catch (e) {
    console.error('Unable to write file', e);
    toast.error(t('download.error') as string);
  }
};

export const downloadReport = async ({
  blob,
  fileName,
}: {
  blob: Blob | null;
  fileName: string;
}) => {
  try {
    if (!blob) {
      toast.error(t('download.error') as string);
      return;
    }
    if (Capacitor.isNativePlatform() && ['ios', 'android'].includes(Capacitor.getPlatform())) {
      const data = Buffer.from(await blob.arrayBuffer()).toString('base64');

      const result = await Filesystem.writeFile({
        path: `${fileName}.pdf`,
        data,
        // directory: Capacitor.getPlatform() === 'ios' ? Directory.Documents : Directory.Data,
        directory: Directory.Documents,
        recursive: true,
      });
      if (result.uri) {
        toast.success(t('download.success') as string);
      } else {
        toast.error(t('download.error') as string);
      }
    }
  } catch (e) {
    console.error('Unable to write file', e);
    toast.error(t('download.error') as string);
  }
};
