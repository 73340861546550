import { PropsWithChildren } from 'react';
import { useTheme } from '../../contexts/ThemeProvider';
import { IconClose } from '../../icons';

export interface ModalProps extends PropsWithChildren {
  onClose?: () => void;
  className?: string;
}
export default function ModalLayout(props: ModalProps) {
  const { getBaseIconColor } = useTheme();

  const iconColor = getBaseIconColor();

  return (
    <div className="fixed inset-0 z-[50] flex h-screen w-full justify-center overflow-y-auto backdrop-brightness-50">
      <div
        className={`relative mt-[120px] h-min w-[90%] rounded-[10px] bg-grey-300 dark:bg-grey-600 ${props.className} mb-12 flex flex-col items-center justify-center`}
      >
        {props.onClose && (
          <div className="flex w-full items-center justify-end">
            <IconClose role="button" onClick={props.onClose} className="m-4" stroke={iconColor} />
          </div>
        )}
        {props.children}
      </div>
    </div>
  );
}
