import { SVGProps } from 'react';

const IconEnvelope = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={33}
    height={33}
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.5625 7.10938H28.9375V24.375C28.9375 24.6444 28.8305 24.9027 28.64 25.0931C28.4495 25.2836 28.1912 25.3906 27.9219 25.3906H5.57812C5.30876 25.3906 5.05044 25.2836 4.85997 25.0931C4.6695 24.9027 4.5625 24.6444 4.5625 24.375V7.10938Z"
      stroke={props.stroke || 'white'}
      strokeWidth={1.875}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.9375 7.10938L16.75 18.2812L4.5625 7.10938"
      stroke={props.stroke || 'white'}
      strokeWidth={1.875}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconEnvelope;
