import { ethers } from 'ethers';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Modal, { useModalState } from 'react-simple-modal-provider';
import * as yup from 'yup';
import { completeSignIn } from '../../api/firebase';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { UserState, signIn } from '../../redux/user/user.slice';
import Button from '../button/Button';
import Form from '../form/Form';
import Input from '../input/Input';
import ModalLayout from '../layouts/ModalLayout';

type CreationModalProps = {
  children: ReactNode;
};

const SsoSignUpModal = ({ children }: CreationModalProps) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useModalState();

  const user = useAppSelector(({ user }: { user: UserState }) => user);
  const dispatch = useAppDispatch();
  const form = {
    initialValues: {
      pin: '',
      repeatPin: '',
    },
    validationSchema: yup.object({
      pin: yup
        .string()
        .min(6, t('form_validation.pin_length', { minChars: 6 }))
        .test('pin_check', t('form_validation.pin_must_be_digit'), (str) =>
          /^[0-9]+$/.test(str || '')
        )
        .required(t('form_validation.required')),

      repeatPin: yup.string().oneOf([yup.ref('pin'), null], t('form_validation.pin_dont_match')),
    }),
  };
  const submitPin = async (pin: string) => {
    const { value, error } = await completeSignIn({
      pin,
      userWallet: ethers.Wallet.fromMnemonic(user.wallet.mnemonic),
      user,
    });
    if (error) {
      toast.error(t(error) as string);
    } else {
      dispatch(signIn({ payload: value }));
      setOpen(false);
      toast.success(t('account.wallet_messages.signup_succeeded') as string);
    }
  };

  return (
    <Modal
      id="SsoSignupModal"
      consumer={children}
      isOpen={isOpen}
      setOpen={setOpen}
      allowClickOutside={false}
    >
      <ModalLayout
        onClose={() => {
          setOpen(false);
          // setPassworError('');
        }}
      >
        <div className="w-full max-w-[600px] p-7">
          <Form
            initialValues={form.initialValues}
            validationSchema={form.validationSchema}
            mode={'all'}
          >
            {({ register, handleSubmit, errors, isValid, watch }) => {
              const values = watch();

              return (
                <>
                  <p className="mb-4 text-body-medium-24">{t('ssoSignupModal.title')}</p>
                  <p className="mb-4 text-body-regular-16">{t('ssoSignupModal.subtitle')}</p>
                  <div className="mt-7 flex flex-col gap-6 ">
                    <Input
                      label={t('ssoSignupModal.pin')}
                      placeholder={t('ssoSignupModal.form_placeholders.pin')}
                      password
                      error={errors['pin']?.message}
                      {...register('pin')}
                      forceLight={true}
                      inputMode="numeric"
                    />

                    <Input
                      label={t('ssoSignupModal.repeat_pin')}
                      placeholder={t('ssoSignupModal.form_placeholders.repeat_pin')}
                      error={errors['repeatPin']?.message}
                      password
                      {...register('repeatPin')}
                      forceLight={true}
                      inputMode="numeric"
                    />
                  </div>

                  <Button
                    type="primary"
                    className="mt-[40px]"
                    // disabled={!isValid}
                    action={handleSubmit(submitPin)}
                  >
                    {t('ssoSignupModal.confirm') as string}
                  </Button>
                  <Button
                    className="mt-6 "
                    type="secondary"
                    action={() => {
                      setOpen(false);
                    }}
                  >
                    {t('ssoSignupModal.cancel') as string}
                  </Button>
                </>
              );
            }}
          </Form>
        </div>
      </ModalLayout>
    </Modal>
  );
};

export default SsoSignUpModal;
