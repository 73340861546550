import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Searchbar, Tutorial } from '..';
import { MessagesContext } from '../../contexts/MessagesContext';
import { MiscContext } from '../../contexts/MiscContext';
import { useTheme } from '../../contexts/ThemeProvider';
import { IconEnvelope } from '../../icons';
import { COLORS } from '../../imports/constants';
import { getUnreadMessages } from '../../imports/utils/messages';
import { useAppSelector } from '../../redux/hooks';

interface SearchbarHeader {
  onSearch?: (query: string) => void;
  handleFilter?: () => void;
  id?: string;
  setDateRange?: any;
  dateRange?: any;
  setFilterType?: any;
  setSortingType?: any;
}

export default function SearchbarHeader({
  handleFilter,
  onSearch,
  setDateRange,
  dateRange,
  setFilterType,
  setSortingType,
}: SearchbarHeader) {
  const { t } = useTranslation();
  const { getLogoImg, isThemeDark } = useTheme();
  const { messages } = useContext(MessagesContext);
  const { companyOptions } = useAppSelector(({ user }) => user);
  const { insets } = useContext(MiscContext);
  const [isOpen, setIsOpen] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const profile = useAppSelector((state) => state.user);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        // Cliccato al di fuori del div, chiudi il div
        setIsOpen(false);
        onSearch?.('');
      }
    };
    // Aggiungi il gestore di eventi al documento quando il componente si monta
    document.addEventListener('click', handleClickOutside);
    // Pulisci il gestore di eventi quando il componente si smonta
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [divRef]);

  return (
    <div
      className={`fixed inset-x-0 top-0 z-40 mx-auto w-full max-w-[768px] border-b border-grey-600 bg-white px-[30px] py-4 dark:bg-black ${
        (profile.tutorial.name === 'completeTutorial' && profile.tutorial.step === 1) ||
        (profile.tutorial.name === 'completeTutorial' && profile.tutorial.step === 2)
          ? 'pointer-events-none z-[100]'
          : ''
      }`}
      style={{ paddingTop: 16 + insets.top }}
      ref={divRef}
    >
      <div className="relative flex w-full justify-between">
        <Searchbar
          setFilterType={setFilterType}
          setSortingType={setSortingType}
          dateRange={dateRange}
          setDateRange={setDateRange}
          placeholder={t('research')}
          onChange={(event) => onSearch?.(event.target.value)}
          handleFilter={handleFilter}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        {!isOpen && (
          <img
            src={getLogoImg()}
            width={100}
            className="absolute left-1/2 z-10 mt-1 -translate-x-1/2"
          />
        )}
        <div className="flex w-[56px] items-center justify-center" id="icon-message">
          <Link to="/messages" className="relative">
            <IconEnvelope stroke={isThemeDark() ? COLORS.white : COLORS.black} />
            {getUnreadMessages(messages, companyOptions) > 0 && (
              <span className="absolute bottom-0 right-0 flex h-4 w-4 items-center justify-center rounded-full bg-primary-500 text-center text-[10px]">
                {String(getUnreadMessages(messages, companyOptions)).padStart(2, '0')}
              </span>
            )}
          </Link>
          <Tutorial
            tutorialName="completeTutorial"
            step={1}
            className="!pointer-events-auto left-2 top-20"
          />
          <Tutorial
            tutorialName="completeTutorial"
            step={2}
            className="!pointer-events-auto absolute -right-3 top-20"
            arrowPositionX="right-7"
          />
        </div>
      </div>
    </div>
  );
}
