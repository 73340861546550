import { IconWarning } from '../../icons';
import { COLORS } from '../../imports/constants';

type SettingsButtonProps = {
  label: string;
  icon?: JSX.Element;
  action?: () => void;
  className?: string;
  placeholder?: string;
  id?: string;
  cy?: string;
  warning?: boolean;
};

const SettingsButton = ({
  label,
  icon,
  action,
  className,
  id,
  cy,
  warning = false,
}: SettingsButtonProps) => {
  return (
    <button
      data-cy={cy}
      className={`mx-auto flex h-[50px] w-full max-w-[90vw] items-center rounded-[10px] bg-grey-300 font-sans text-body-regular-18 text-black hover:bg-grey-500 dark:bg-grey-600 dark:text-white ${className}`}
      onClick={action}
    >
      {icon}
      {label}
      {warning && <IconWarning stroke={COLORS.warning} />}
    </button>
  );
};

export default SettingsButton;
