import axios, { AxiosResponse } from 'axios';
import { getIdToken } from 'firebase/auth';
import { backendEndpoint } from '../constants';
import { auth } from '../firebase';
import {
  AnalyticsData,
  AnalyticsFunctionSubtype,
  AnalyticsInfoSubtype,
  AnalyticsType,
} from '../types';

export const setClickAnalytics = async ({
  type,
  subtype,
  contractId,
  nftId,
  user,
  companyId,
  name,
}: AnalyticsData): Promise<AxiosResponse<{ data: any }> | { data: any }> => {
  if (companyId && contractId && nftId) {
    const res = await axios.post(
      `${backendEndpoint}/analytics`,
      {
        subtype: subtype,
        type: type ? type : 'click',
        contractId: contractId,
        nftId: nftId,
        companyId: companyId,
        name: name,
      },
      {
        headers: { Authorization: await getIdToken(user) },
      }
    );
    return res;
  }
  return { data: { error: 'User not logged in' } };
};

export const getAnalyticsData = (
  type: AnalyticsType,
  subtype: AnalyticsInfoSubtype | AnalyticsFunctionSubtype,
  name: string,
  { companyId, nftId, contractId }: { companyId: string; nftId: string; contractId: string }
): AnalyticsData => {
  if (!companyId) return {} as AnalyticsData;
  const analyticsData = {
    companyId,
    type: type,
    subtype: subtype,
    nftId,
    contractId,
    user: auth.currentUser!,
    name: name,
  };
  return analyticsData;
};
