import { SVGProps } from 'react';

const IconWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1713_44419)">
      <path
        d="M85.6507 29.9999L19.6507 144C18.5993 145.821 18.0448 147.887 18.043 149.989C18.0411 152.092 18.5919 154.159 19.6401 155.982C20.6883 157.805 22.1972 159.32 24.0155 160.376C25.8339 161.433 27.8979 161.993 30.0007 162H162.001C164.104 161.993 166.168 161.433 167.986 160.376C169.804 159.32 171.313 157.805 172.361 155.982C173.41 154.159 173.96 152.092 173.958 149.989C173.957 147.887 173.402 145.821 172.351 144L106.351 29.9999C105.307 28.1765 103.8 26.661 101.983 25.6071C100.165 24.5531 98.1017 23.998 96.0007 23.998C93.8997 23.998 91.836 24.5531 90.0185 25.6071C88.201 26.661 86.6943 28.1765 85.6507 29.9999V29.9999Z"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96 78V108"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M96 144C100.971 144 105 139.971 105 135C105 130.029 100.971 126 96 126C91.0294 126 87 130.029 87 135C87 139.971 91.0294 144 96 144Z"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        fill={props.stroke ? props.stroke : '#4F4F4F'}
      />
    </g>
    <defs>
      <clipPath id="clip0_1713_44419">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconWarning;
