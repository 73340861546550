//@ts-ignore
import { BcodeSDK } from '@bcode-tech/bcode-sdk';

import {
  pablockMetaTransaction,
  pablockMultiSignFactory,
  pablockNft,
  pablockNotarization,
  pablockTokenAddress,
  rpcProvider,
  sdkApi,
  sdkEnv,
} from '../imports/constants';

const sdk = BcodeSDK.createLegacy({
  apiKey: sdkApi,
  config: {
    env: sdkEnv,
    debugMode: true,
    rpcProvider,
    bcodeContracts: {
      PABLOCK_TOKEN_ADDRESS: pablockTokenAddress,
      PABLOCK_META_TRANSACTION: pablockMetaTransaction,
      PABLOCK_NOTARIZATION: pablockNotarization,
      PABLOCK_NFT: pablockNft,
      PABLOCK_MULTISIGN_FACTORY: pablockMultiSignFactory,
    },
  },
});

export default sdk;
