import { ContractsNft, UsersNft } from '../../contexts/FirestoreContext';
import { Contract, Nft, NftAttribute } from '../types';

/**
 * @description creates the full Nft
 * @param contractNft
 * @param userNft
 * @returns Nft: Nft
 */
export const getFullNft = (contractNft: ContractsNft, userNft: UsersNft): Nft => {
  return {
    _id: contractNft._id,
    attributes: contractNft.attributes,
    contractAddress: userNft.contractAddress,
    contractId: userNft.contractId,
    createdAt: userNft.createdAt,
    description: contractNft.description,
    external_url: contractNft.external_url,
    favorite: userNft.favorite,
    hide: userNft.hide,
    image: contractNft.image,
    quantityContract: contractNft.quantity,
    quantityUser: userNft.quantity,
    mint: userNft.mint,
    name: contractNft.name,
    status: userNft.status,
    tokenId: userNft.tokenId,
    transactionHash: userNft.transactionHash,
    transfer: userNft.transfer,
    transfersCount: userNft.transfersCount,
    updatedAt: userNft.updatedAt,
    contractNftUpdatedAt: contractNft.updatedAt || 0,
  };
};

/**
 * @description returns the last nft update time
 * @param contractList
 * @returns lastUpdate: number
 */
export const getLastNftUpdate = (contractList: Contract[]): number => {
  let lastUpdate = 0;
  contractList.forEach((contract: Contract) => {
    contract?.nfts?.forEach((nft) => {
      if (nft?.updatedAt && nft.updatedAt > lastUpdate) lastUpdate = nft.updatedAt;
    });
  });
  return lastUpdate;
};

/**
 * @description Returns Nft's ordered attributes, ready for visualization
 * @param nftsAttributes: NftAttribute[]
 * @returns orderedAttr: NftAttribute[][]
 */
export const handleOrderAttributes = (nftAttributes: NftAttribute[]): NftAttribute[][] => {
  /* this is for aling documents */
  // const typeDocs = ['document', 'warranty_certificate', 'authenticity_certificate'];
  const orderedAttr: NftAttribute[][] = [];
  let partialArray: NftAttribute[] = [];
  nftAttributes?.forEach((attr: NftAttribute, i: number) => {
    if (
      /* this is for aling documents */
      // partialArray.length === 0 ||
      (nftAttributes?.[i - 1]?.type === attr?.type && attr?.type === 'partner') ||
      (nftAttributes?.[i - 1]?.type === attr?.type && attr?.type === 'image')
      /* this is for aling documents */
      /*  ||
      (typeDocs.includes(nftAttributes?.[i - 1]?.type) &&
        typeDocs.includes(attr?.type) &&
        partialArray.length === 1)
    ) */
    ) {
      partialArray.push(attr);
    } else {
      orderedAttr.push(partialArray);
      partialArray = [attr];
    }
    // eslint-disable-next-line no-unsafe-optional-chaining
    if (i === nftAttributes?.length - 1) {
      orderedAttr.push(partialArray);
    }
  });
  return orderedAttr;
};

export const findContractById = (contracts: Contract[], contractId?: string) => {
  if (!contractId) return;

  return contracts.find((contract) => contract.id === contractId);
};

export const findNftById = (contract?: Contract, nftId?: string | number) => {
  if (!contract || !nftId) return;

  const numberNftId = typeof nftId === 'string' ? parseInt(nftId) : nftId;

  const { nfts = [] } = contract;
  return nfts.find((nft) => nft.tokenId === numberNftId);
};

export const findNftFromContracts = (
  contracts: Contract[],
  contractId?: string,
  nftId?: string | number
) => {
  const contract = findContractById(contracts, contractId);

  if (!contract) return;

  const nft = findNftById(contract, nftId);
  return nft;
};
