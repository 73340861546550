import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import 'swiper/css';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/store';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router-dom';
import { ModalProvider } from 'react-simple-modal-provider';
import { AuthProvider } from './contexts/AuthContext';
import MessagesProvider from './contexts/MessagesContext';
import { MiscProvider } from './contexts/MiscContext';
import './i18n';
import { router } from './imports/routes';
import './styles/index.css';

import {
  AccountDeleteModal,
  AppStoreModal,
  ConversionModal,
  DriveRecoverModal,
  ExternalNftsModal,
  HideNftModal,
  ShowImageModal,
  ShowNftModal,
  SsoSignUpModal,
  TransferModal,
  TransferNftConfirmation,
  UnlockModal,
} from './components/modals';
import PendingRedeemModal from './components/modals/PendingRedeemModal';
import ContractsProvider from './contexts/ContractsProvider';
import { LoadingProvider } from './contexts/LoadingContext';
import { ThemeProvider } from './contexts/ThemeProvider';
import { IconLoading } from './icons';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <ThemeProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LoadingProvider>
          <AuthProvider>
            <QueryClientProvider client={queryClient}>
              <MessagesProvider>
                <MiscProvider>
                  <ModalProvider
                    value={[
                      AppStoreModal,
                      ConversionModal,
                      SsoSignUpModal,
                      TransferModal,
                      UnlockModal,
                      DriveRecoverModal,
                      HideNftModal,
                      ShowNftModal,
                      ExternalNftsModal,
                      TransferNftConfirmation,
                      ShowImageModal,
                      AccountDeleteModal,
                      PendingRedeemModal,
                    ]}
                  >
                    <ContractsProvider>
                      <Suspense
                        fallback={
                          <div className="flex h-screen w-screen items-center justify-center">
                            <IconLoading className="h-12 w-12 animate-spin" />
                          </div>
                        }
                      >
                        <RouterProvider router={router} />
                      </Suspense>
                    </ContractsProvider>
                  </ModalProvider>
                </MiscProvider>
              </MessagesProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </AuthProvider>
        </LoadingProvider>
      </PersistGate>
    </Provider>
  </ThemeProvider>
);

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store;
}
