import { useQuery } from '@tanstack/react-query';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { getMessages, Messages } from '../api/messages';

import { useAppSelector } from '../redux/hooks';
import { AuthContext } from './AuthContext';

interface ContextProps {
  messages: Messages[];
  refetchMessages: () => void;
}

const initialValues: ContextProps = {
  messages: [],
  refetchMessages: () => {},
};

export const MessagesContext = createContext<ContextProps>(initialValues);

const MessagesProvider = ({ children }: PropsWithChildren) => {
  const { user } = useContext(AuthContext);
  const { uid, publicCompanies, ownedCompanies } = useAppSelector(({ user, companies }) => ({
    uid: user.uid,
    publicCompanies: companies.list,
    ownedCompanies: companies.companiesIds,
  }));

  const fetch = () =>
    getMessages(
      publicCompanies.filter((company) => ownedCompanies.includes(company.address)),
      uid
    );

  const canQuery = !!user && uid !== null && publicCompanies.length > 0;
  const queryKey = ['messages', publicCompanies, uid];
  const {
    data: messages,
    error,
    refetch: refetchMessages,
  } = useQuery({
    queryKey,
    queryFn: fetch,
    enabled: canQuery,
  });
  if (error) console.error(error);

  const value = useMemo(() => ({ messages: messages ?? [], refetchMessages }), [messages]);

  return <MessagesContext.Provider value={value}>{children}</MessagesContext.Provider>;
};

export default MessagesProvider;
