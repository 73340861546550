import { SVGProps } from 'react';

const IconCancel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_748_2263)">
      <path
        d="M18.75 5.75L5.25 19.25"
        stroke={props.color || 'white'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 19.25L5.25 5.75"
        stroke={props.color || 'white'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_748_2263">
        <rect width={24} height={24} fill={props.color || 'white'} transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconCancel;
