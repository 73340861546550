import React from 'react';

interface IStepper {
  currentStep: number;
  stepLabels: string[];
  cy?: string;
}

const Stepper: React.FC<IStepper> = ({ currentStep, stepLabels, cy }) => {
  if (currentStep >= stepLabels.length) throw new Error('StepperError: active index out of bounds');

  const stepperPercentage = Math.ceil((100 / stepLabels.length) * (currentStep + 1));
  // const widthStepBar = `w-[${stepperPercentage}%]`;

  return (
    <div className="flex w-full flex-col space-y-[12px]">
      <p className="text-body-regular-16" data-cy={cy}>
        {stepLabels.at(currentStep)}
      </p>
      <div className="flex w-full items-center rounded-xl bg-white ">
        <figure
          className={`h-[4px]  rounded-xl bg-primary-500`}
          style={{ width: `${stepperPercentage}%` }}
        ></figure>
        {/* {stepLabels.map((_, i) => (
          <figure
            key={i}
            className={`h-[4px] w-[84px] grow bg-primary-500 transition-opacity duration-500 first:rounded-l-xl  last:rounded-r-xl ${
              currentStep >= i ? 'opacity-100' : ' opacity-0'
            }`}
          />
        ))} */}
      </div>
    </div>
  );
};

export default Stepper;
