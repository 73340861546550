import { SVGProps } from 'react';

const IconBack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={31}
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1000_12241)">
      <path
        d="M18.75 24.625L9.37498 15.25L18.75 5.875"
        stroke={props.stroke || 'white'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1000_12241">
        <rect width={29.9999} height={29.9999} fill="white" transform="translate(0 0.25)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconBack;
