import Phaser from 'phaser';
import { GAME } from '../imports/constants';

const VIEW_SIZE = 740;

const config: Phaser.Types.Core.GameConfig = {
  type: Phaser.AUTO,
  parent: 'gameDiv',
  width: VIEW_SIZE,
  height: VIEW_SIZE,
  antialias: true,
  scale: {
    width: VIEW_SIZE,
    height: VIEW_SIZE,
    mode: Phaser.Scale.ScaleModes.FIT,
    parent: 'gameDiv',
  },
  fps: {
    min: 60,
    target: 60,
    forceSetTimeOut: false,
    smoothStep: true,
  },
  backgroundColor: GAME.bgColor,
  physics: {
    default: 'arcade',
    arcade: {
      debug: false,
      fixedStep: true,
      fps: 60,
    },
  },
  roundPixels: true,
};

export default config;
