import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useSound from 'use-sound';
import { Particles } from '../../assets/animation';
import sound from '../../assets/sounds/ciao.mp3';
import { Button, Layout } from '../../components';
import GoBackHeader from '../../components/headers/GoBackHeader';
import config from '../../game/config';
import ChooseCharScene from '../../game/scenes/chooseCharScene';
import { IconLeaderboard, IconLoading } from '../../icons';
import { auth } from '../../imports/firebase';
import { Score } from '../../imports/types';
import { findContractById } from '../../imports/utils/nfts';
import { startGame, stopGame } from '../../redux/game/game.slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

export default function Game() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { start, user, contractsList, address } = useAppSelector(({ game, user, contracts }) => ({
    start: game.gameStart,
    user,
    contractsList: contracts.list,
    address: user.address || user.wallet.address,
  }));
  const { id: tokenId } = useParams();
  const [searchParams] = useSearchParams();
  const contract = searchParams.get('contract');
  const [game, setGame] = useState<Phaser.Game>();
  const [showLeaderboard, setshowLeaderboard] = useState(false);
  const contractDoc = findContractById(contractsList, contract ?? '');
  const {
    data: leaderboard,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['leaderboard'],
    queryFn: async () => {
      const firebaseToken = (await auth.currentUser?.getIdToken()) as string;
      const { data: leaderboard } = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/leaderboard?contract=${contractDoc?.id}&userAddress=${address}`,
        {
          headers: {
            authorization: firebaseToken,
          },
        }
      );
      return leaderboard;
    },
    enabled: false,
  });

  const destroyGame = () => {
    try {
      game?.scene?.destroy();
      game?.sound?.stopAll();
    } catch (e) {
      console.error(e);
    }
    navigate(-1);
  };

  const setLeaderboard = () => {
    if (!showLeaderboard) {
      refetch();
    }
    setshowLeaderboard(!showLeaderboard);
  };

  useEffect(() => {
    const fun = async () => {
      const firebaseToken = (await auth.currentUser?.getIdToken()) as string;
      const highscore = (
        await axios.get(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/highscore?contract=${contract}&address=${address}`,
          {
            headers: {
              authorization: firebaseToken,
            },
          }
        )
      ).data.score;

      const game = new Phaser.Game(
        Object.assign(config, {
          scene: [ChooseCharScene],
        })
      );

      setGame(game);
      // Start the game passing parameters

      game.scene.start('ChooseCharScene', {
        tokenId,
        contractId: contractDoc?.id,
        companyId: contractDoc?.owner,
        firebaseToken,
        address,
        name: user.name,
        highscore,
      });
    };

    if (start) {
      fun();
    }
  }, [start]);

  return (
    <Layout
      header={
        <GoBackHeader
          onBack={() => {
            if (game?.isRunning) destroyGame();
            else {
              navigate(-1);
            }
            // navigate(`home/companies/${contractDoc?._id}`);
          }}
        />
      }
      paddingBottom={false}
    >
      {start ? (
        <div className="flex h-screen w-full flex-col items-center justify-center pt-topBar">
          <div id="gameDiv" className="flex aspect-square w-full items-center justify-center" />
          <Button
            action={() => {
              game?.sound.stopAll();
              game?.scene.destroy();
              dispatch(stopGame());
            }}
            className="z-[2] mt-8 rounded bg-[#FFC000] px-4 py-2 font-bold text-white"
          >
            {t('game.back')}
          </Button>
        </div>
      ) : showLeaderboard ? (
        <Leaderboard
          currentUser={address}
          isFetching={isFetching}
          leaderboard={leaderboard || []}
          setLeaderboard={setLeaderboard}
        />
      ) : (
        <div
          className="relative m-0 flex h-screen flex-col items-center justify-center gap-3"
          style={{ height: 'calc(100vh - 10px)', overflow: 'hidden' }}
        >
          <Lottie
            loop={true}
            animationData={Particles}
            play
            style={{
              width: 1200,
              height: 1200,
              position: 'absolute',
              zIndex: 1,
            }}
          />
          <Button
            action={() => dispatch(startGame())}
            className="z-[2] rounded bg-[#FFC000] px-4 py-2 font-bold text-white"
          >
            {t('game.startGame')}
          </Button>
          <Button
            action={setLeaderboard}
            className="z-[2] rounded bg-[#FFC000] px-4 py-2 font-bold text-white"
          >
            {t('game.leaderboard')}
          </Button>
        </div>
      )}
    </Layout>
  );
}

function Leaderboard({
  isFetching,
  leaderboard,
  setLeaderboard,
  currentUser,
}: {
  isFetching: boolean;
  leaderboard: Score[];
  setLeaderboard: () => void;
  currentUser: string;
}) {
  const { t } = useTranslation();
  const [play] = useSound(sound);

  const changePos = () => {
    const { innerWidth: width, innerHeight: height } = window;

    const min = 10;

    const value = `bottom: ${Math.floor(
      Math.random() * (height - min + 1) + min
    ).toString()}px; right: ${Math.floor(Math.random() * (width - min + 1) + min).toString()}px`;

    document.getElementById('soundbtn')?.setAttribute('style', value);
  };

  const playSound = () => {
    play();
    changePos();
  };

  useEffect(() => {
    changePos();
  }, []);

  return (
    <div className="h-full w-full pt-topBar">
      {isFetching ? (
        <div className="flex h-full items-center justify-center">
          <IconLoading className="h-12 w-12 animate-spin" />
        </div>
      ) : (
        <div
          className={`flex w-full flex-col justify-between overflow-y-auto pb-10 ${
            !leaderboard.length && 'h-full'
          }`}
        >
          <>
            <IconLeaderboard className="w-full" />
            {leaderboard.length ? (
              <>
                <div className="grid grid-cols-8 place-items-center gap-6 border-b-2 p-2">
                  <div className="col-span-2 font-bold">{t('game.rank')}</div>

                  <div className="col-span-3 font-bold">{t('game.address')}</div>
                  <div className="col-span-3 font-bold">{t('game.score')}</div>
                </div>
                {leaderboard?.map((record, index) => (
                  <div
                    key={index}
                    className="grid h-16 grid-cols-8 place-items-center gap-6 border-b-2 p-2"
                  >
                    <div className="col-span-2">{`${index + 1}.`}</div>
                    <div
                      className={`col-span-3 ${
                        currentUser.toLowerCase() === record.user.toLowerCase()
                          ? 'underline decoration-solid'
                          : ''
                      }`}
                    >
                      {truncateText(record.user, {
                        maxLength: 10,
                        leftChars: 7,
                        rightChars: 3,
                        position: 'middle',
                      })}
                    </div>
                    <div className="col-span-3">{record.score}</div>
                  </div>
                ))}
              </>
            ) : (
              <div>
                <p className="my-10 h-full text-center">{t('game.no_leaderboard')}</p>
              </div>
            )}
          </>
          <Button
            action={setLeaderboard}
            className="mt-8 w-full rounded bg-[#FFC000] px-4 py-2 font-bold text-white"
          >
            {t('game.back')}
          </Button>
        </div>
      )}

      <button id="soundbtn" type="button" className="absolute h-10 w-10" onClick={playSound} />
    </div>
  );
}

export function truncateText(
  text: string,
  config: {
    maxLength: number;
    position?: 'middle' | 'end';
    leftChars?: number;
    rightChars?: number;
  }
) {
  return text.length > config.maxLength
    ? `${text.substring(
        0,
        config?.leftChars || Math.floor(config.maxLength / 2)
      )}...${text.substring(
        text.length - (typeof config?.rightChars === 'number' ? config?.rightChars : 3),
        text.length
      )}`
    : text;
}
