import { OperatingSystem } from '@capacitor/device';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AppleStore from '../../assets/images/apple-store-badge.png';
import GooglePlay from '../../assets/images/google-play-badge.png';
import { useTheme } from '../../contexts/ThemeProvider';
import { STORES } from '../../imports/constants';
import Layout from '../layouts/Layout';

interface StoresRedirectProps {
  os: OperatingSystem | null;
}
interface StoresImageProps {
  src: string;
  altText: string;
  storeLink: string;
}

const StoresRedirect = (platform: StoresRedirectProps) => {
  const { getLogoImg } = useTheme();
  const { t } = useTranslation();
  const [image, setImage] = useState<StoresImageProps | null>(null);

  useEffect(() => {
    if (platform.os === 'ios') {
      setImage({
        src: AppleStore,
        altText: 'Apple Store',
        storeLink: STORES.appStore,
      });
    } else {
      setImage({
        src: GooglePlay,
        altText: 'Google Play',
        storeLink: STORES.googlePlay,
      });
    }
  }, [platform.os]);

  return (
    <Layout paddingBottom={false}>
      <div className="flex flex-col items-center justify-center gap-4">
        <section className="relative flex w-full flex-col items-center justify-center pt-[8vh]">
          {/* ========== LOGO ========== */}
          <img src={getLogoImg()} className="mx-auto" />
          <span className="mt-5 w-full text-center text-body-regular-16">{t('auth.motto')}</span>
        </section>
        <section className="relative flex w-full flex-col items-center justify-center pt-[8vh]">
          <span className="mt-5 w-full text-center text-body-regular-14">
            {t('redirect_stores')}
          </span>
          {image?.storeLink && (
            <Link to={image?.storeLink}>
              <img src={image?.src} alt={image?.altText} className="scale-[0.5]" />
            </Link>
          )}
        </section>
      </div>
    </Layout>
  );
};

export default StoresRedirect;
