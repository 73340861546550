import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalState } from 'react-simple-modal-provider';
import { toast } from 'react-toastify';

import * as yup from 'yup';
import { completeSignIn } from '../../api/firebase';
import { decryptWallet } from '../../imports/utils/auth';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { UserState, signIn } from '../../redux/user/user.slice';
import Button from '../button/Button';
import Form from '../form/Form';
import Input from '../input/Input';
import ModalLayout from '../layouts/ModalLayout';

type ConversionModalProps = {
  children: ReactNode;
};

const ConversionModal = ({ children }: ConversionModalProps) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useModalState();

  const user = useAppSelector(({ user }: { user: UserState }) => user);
  const dispatch = useAppDispatch();
  const form = {
    initialValues: {
      password: '',
      pin: '',
      repeatPin: '',
    },
    validationSchema: yup.object({
      password: yup.string().required(t('form_validation.required')),
      pin: yup
        .string()
        .min(6, t('form_validation.pin_length', { minChars: 6 }))
        .test('pin_check', t('form_validation.pin_must_be_digit'), (str) =>
          /^[0-9]+$/.test(str || '')
        )
        .required(t('form_validation.required')),

      repeatPin: yup.string().oneOf([yup.ref('pin'), null], t('form_validation.pin_dont_match')),
    }),
  };
  const submitPin = async (oldPassword: string, pin: string) => {
    // wallet is a string here, but without toString() type breaks
    // we can fix, but we break every deconstruct in the app

    // wallet my be the encrypted wallet, since after SSO I have password
    // to decrypt it. So in the modal I ask for password, decrypt it
    // and complete Sign in
    let userWallet;
    try {
      userWallet = decryptWallet(user.wallet.toString(), oldPassword);
    } catch (e) {
      toast.error(t('account.wallet_messages.decrypt_error') as string);
    }
    if (userWallet) {
      const { value, error } = await completeSignIn({ pin, userWallet, user });
      if (error) {
        toast.error(t(error) as string);
      } else {
        dispatch(signIn({ profile: value.profile }));
        setOpen(false);
        toast.success(t('account.wallet_messages.convertion_succeeded') as string);
        // location.reload();
        location.replace('/home');
      }
    }
  };

  return (
    <Modal
      id="ConversionModal"
      consumer={children}
      isOpen={isOpen}
      setOpen={setOpen}
      allowClickOutside={false}
    >
      <ModalLayout>
        <div className="w-full max-w-[600px] overflow-y-auto p-7">
          <Form
            initialValues={form.initialValues}
            validationSchema={form.validationSchema}
            mode={'all'}
          >
            {({ register, handleSubmit, errors, isValid, watch }) => {
              const values = watch();

              return (
                <>
                  <p className="mb-4 text-body-medium-24">{t('conversionModal.title')}</p>
                  <p className="mb-4 text-body-regular-16">{t('conversionModal.subtitle')}</p>
                  <div className="mt-7 flex flex-col gap-6 overflow-y-hidden">
                    <Input
                      label={t('conversionModal.password')}
                      placeholder={t('auth.passwordPlaceholder')}
                      password
                      error={errors['password']?.message}
                      {...register('password')}
                      forceLight={true}
                    />
                    <Input
                      label={t('conversionModal.pin')}
                      placeholder={t('conversionModal.form_placeholders.pin')}
                      password
                      error={errors['pin']?.message}
                      {...register('pin')}
                      forceLight={true}
                      inputMode="numeric"
                    />
                    <Input
                      label={t('conversionModal.repeat_pin')}
                      placeholder={t('conversionModal.form_placeholders.repeat_pin')}
                      error={errors['repeatPin']?.message}
                      password
                      {...register('repeatPin')}
                      forceLight={true}
                      inputMode="numeric"
                    />
                  </div>

                  <Button
                    type="primary"
                    className="mt-[40px]"
                    action={handleSubmit((values) => submitPin(values.password, values.pin))}
                  >
                    {t('ssoSignupModal.confirm') as string}
                  </Button>
                  <Button
                    className="mt-6 "
                    type="secondary"
                    action={() => {
                      setOpen(false);
                    }}
                  >
                    {t('ssoSignupModal.cancel') as string}
                  </Button>
                </>
              );
            }}
          </Form>
        </div>
      </ModalLayout>
    </Modal>
  );
};

export default ConversionModal;
