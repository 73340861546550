import { useState } from 'react';
import { IconBack } from '../../icons';
export default function DetailItem({
  left,
  right,
  link,
  isCustomAttribute,
  className,
}: {
  left: string;
  right: string;
  link?: string;
  isCustomAttribute?: boolean;
  className?: string;
}) {
  const [showMore, setShowMore] = useState(false);
  const [overflowing, setOverflowing] = useState(false);
  const cssToHideMore = 'overflow-hidden text-ellipsis whitespace-nowrap';

  const handleOverflowing = (ref: any) => {
    if (!ref) return;
    const isOverflowing = ref.scrollWidth > ref.clientWidth;
    if (isOverflowing) {
      setOverflowing(true);
    }
  };

  const handleShomMore = () => {
    if (isCustomAttribute) {
      setShowMore(!showMore);
    }
  };
  return (
    <div className={`flex justify-between ${className}`}>
      <div
        className={`flex flex-col ${
          isCustomAttribute ? (showMore ? 'flex-wrap gap-y-0' : cssToHideMore) : 'whitespace-nowrap'
        } gap-2`}
      >
        {link ? (
          <a
            ref={handleOverflowing}
            className={` ${
              isCustomAttribute ? (showMore ? '' : cssToHideMore) : 'whitespace-nowrap'
            }   text-body-bold-underline-14 underline `}
            target="_blank"
            href={link}
          >
            {left}
          </a>
        ) : (
          <>
            <p
              ref={handleOverflowing}
              className={` ${
                isCustomAttribute
                  ? showMore
                    ? 'whitespace-break-spaces'
                    : cssToHideMore
                  : 'whitespace-nowrap'
              } ${link ? 'text-body-bold-underline-14 underline ' : 'text-body-bold-14'}`}
            >{`${left}:`}</p>
            <span
              ref={handleOverflowing}
              className={` ${
                isCustomAttribute
                  ? showMore
                    ? 'whitespace-break-spaces'
                    : cssToHideMore
                  : 'whitespace-nowrap'
              } ${link ? 'text-body-regular-14 underline' : 'text-body-regular-14'}`}
            >
              {right}
            </span>
          </>
        )}
        {/* <p
          ref={handleOverflowing}
          className={` ${
            isCustomAttribute ? (showMore ? '' : cssToHideMore) : 'whitespace-nowrap'
          }  text-body-regular-14 ${
            link ? 'text-body-bold-underline-14 underline ' : 'text-body-bold-14'
          }`}
        >{`${left}:`}</p>
        {link ? (
          <a
            ref={handleOverflowing}
            className={` ${
              isCustomAttribute ? (showMore ? '' : cssToHideMore) : 'whitespace-nowrap'
            }   ${link ? 'text-body-bold-underline-14 underline ' : 'text-body-bold-14'}`}
            target="_blank"
            href={link}
          >
            {right}
          </a>
        ) : (
          <span
            ref={handleOverflowing}
            className={` ${
              isCustomAttribute ? (showMore ? '' : cssToHideMore) : 'whitespace-nowrap'
            } ${link ? 'text-body-bold-underline-14 underline' : 'text-body-bold-14'}`}
          >
            {right}
          </span> */}
        {/* )} */}
      </div>
      {overflowing && isCustomAttribute && (
        <div className=" text-body-regular-12" onClick={handleShomMore}>
          {showMore ? (
            <IconBack width={20} height={20} style={{ transform: 'rotate(90deg)' }} />
          ) : (
            <IconBack width={20} height={20} style={{ transform: 'rotate(-90deg)' }} />
          )}
        </div>
      )}
    </div>
  );
}
