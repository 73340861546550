import {
  DocumentChange,
  DocumentData,
  collection,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useModal } from 'react-simple-modal-provider';
import { queryClient } from '..';
import { UsersNft } from '../contexts/FirestoreContext';
import { db } from '../imports/firebase';
import { Contract } from '../imports/types';
import { getLastNftUpdate } from '../imports/utils/nfts';
import { removeContractNft } from '../redux/contracts/contracts.slice';

function useNftSubscriptions(uid: string, contractsList: Contract[]) {
  const dispatch = useDispatch();
  const { open: openTransferNftrConfirmation } = useModal('TransferNftConfirmation');

  useEffect(() => {
    const unsub = subscribeToNfts(uid, contractsList);
    const deletedDocsUnsub = subscribeToDeletedDocs(uid);

    return () => {
      unsub();
      deletedDocsUnsub();
    };
  }, [uid, contractsList]);

  function subscribeToNfts(uid: string, contractsList: any[]) {
    return onSnapshot(
      query(
        collection(db, 'users', uid, 'nfts'),
        where('updatedAt', '>', getLastNftUpdate(contractsList))
      ),
      (docs) => {
        queryClient.setQueryData(['users-nfts', uid], docs);
      }
    );
  }

  function subscribeToDeletedDocs(uid: string) {
    return onSnapshot(query(collection(db, 'users', uid, 'nfts')), handleDeletedDocs);
  }

  function handleDeletedDocs(snapshot: any) {
    const deletedDocs: DocumentChange<DocumentData>[] = snapshot
      .docChanges()
      .filter((change: any) => change.type === 'removed');
    if (deletedDocs.length > 0) {
      const deletedDocData = deletedDocs.map((change: any) => change.doc.data() as UsersNft);
      const deletedNfts = [] as UsersNft[];
      deletedDocData.forEach((doc) => {
        deletedNfts.push(doc);
        dispatch(
          removeContractNft({
            contractId: doc.contractId,
            tokenId: String(doc.tokenId),
          })
        );
      });
      openTransferNftrConfirmation({ addedNfts: deletedNfts, isSender: true });
    }
  }
}

export default useNftSubscriptions;
