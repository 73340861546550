/* eslint-disable tailwindcss/enforces-negative-arbitrary-values */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeProvider';
import { IconBack, IconEnvelope, IconHome, IconScan } from '../../icons';
import { COLORS } from '../../imports/constants';
import { getRemoteWelcomeToken } from '../../imports/remoteConfig';
import { TutorialNames, TutorialSteps } from '../../imports/types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { updateUserData } from '../../redux/user/user.slice';
import Button from '../button/Button';

type TutorialTextWindowProp = {
  step: number;
  arrow?: 'top' | 'bottom' | 'no';
  arrowPositionX?: string;
  last?: boolean;
  className?: string;
  action?: () => void;
  tutorialName: TutorialNames;
  disableNext?: boolean;
};

const Tutorial = ({
  step,
  last,
  className,
  arrow,
  arrowPositionX,
  action,
  tutorialName,
  disableNext,
}: TutorialTextWindowProp) => {
  const { t } = useTranslation();
  const profile = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { isThemeDark } = useTheme();
  const iconStroke = isThemeDark() ? COLORS.white : COLORS.white;
  const textColor = isThemeDark() ? COLORS.grey600 : COLORS.grey600;
  const bgColor = isThemeDark() ? COLORS.grey100 : COLORS.grey100;
  const navigate = useNavigate();
  const tutorialSteps: TutorialSteps = {
    welcomeTutorial: {
      step1: { back: '', forward: '/settings' },
      step2: { back: '/home/nfts', forward: `/nfts/${getRemoteWelcomeToken()}/1` },
      step3: { back: '/settings', forward: `/nfts/${getRemoteWelcomeToken()}/1` },
      step4: { back: `/nfts/${getRemoteWelcomeToken()}/1`, forward: '/redeem' },
      step5: { back: `/nfts/${getRemoteWelcomeToken()}/1`, forward: '' },
    },
    completeTutorial: {
      step1: {
        back: '',
        forward: '/home/nfts',
        icon: <IconHome stroke={iconStroke} height={30} width={30} />,
      }, //home
      step2: {
        back: '/home/nfts',
        forward: '/redeem',
        icon: <IconEnvelope stroke={iconStroke} height={30} width={30} />,
      }, //messaging
      step3: {
        back: '/home/nfts',
        forward: '/profile',
        icon: <IconScan stroke={iconStroke} height={30} width={30} />,
      }, //redeem
      step4: { back: '/redeem', forward: '/profile' }, //profile
      step5: { back: '/profile', forward: '/profile' }, //download wallet
      step6: { back: '/profile', forward: '/settings' }, //legals
      step7: { back: '/profile', forward: '/settings' }, //settings
      step8: { back: '/settings', forward: '/settings' }, //privacy
      step9: { back: '/settings', forward: '/settings' }, //backup
      step10: { back: '/settings', forward: '/settings' }, //hidden
      step11: { back: '/settings', forward: '/settings' }, //faq
      step12: { back: '/settings', forward: '/settings' }, //assistenza
      step13: { back: '/settings', forward: '/settings' }, //tema
      step14: { back: '/settings', forward: '/settings' }, //delete
      step15: { back: '/settings', forward: '' }, //exit
    },
  };

  const handleAction = async (direction: 'forward' | 'back') => {
    // disabilita il pulsante per evitare di fare più volte il conteggio e sballare tutto
    setLoading(true);

    const goToStep = direction === 'forward' ? (last ? 0 : step + 1) : step - 1;
    const goToPage =
      direction === 'forward'
        ? tutorialSteps[tutorialName][`step${step}`].forward
        : tutorialSteps[tutorialName][`step${step}`].back;
    dispatch(
      updateUserData({
        profile: {
          ...profile,
          tutorial: {
            active: goToStep ? true : false,
            name: tutorialName,
            step: goToStep,
            page: goToPage,
          },
        },
      })
    );
    navigate(goToPage);
    //if needed, do a specific action
    if (action) {
      action();
    }
    setLoading(false);
  };

  return profile.tutorial.name === tutorialName && profile.tutorial.step === step ? (
    <div
      className={`pointer-events-auto absolute z-[100] flex w-[80vw] max-w-[90%] flex-col rounded-md p-4 opacity-100 ${className}`}
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      {arrow !== 'no' && (
        <div
          className={`absolute ${
            arrow === 'bottom' ? '-bottom-[8px]' : '-top-[8px]'
          } ${arrowPositionX} h-[20px] w-[20px] rotate-45 rounded-sm`}
          style={{ backgroundColor: bgColor }}
        ></div>
      )}
      <p className="z-10 mx-auto flex items-end gap-2 text-center text-body-semibold-16">
        {tutorialSteps[tutorialName][`step${step}`].icon && (
          <span className=" bg-primary-500">{tutorialSteps[tutorialName][`step${step}`].icon}</span>
        )}

        <span>{t(`tutorial.${tutorialName}.step${step}.title`)}</span>
      </p>
      <p className="z-10 mt-2 text-body-medium-14">
        {t(`tutorial.${tutorialName}.step${step}.content`)}
      </p>
      <div className="mt-4 flex w-full justify-between">
        {step !== 1 ? (
          <Button action={() => handleAction('back')} className="ml-0 !h-8 !w-8 rounded-md">
            <IconBack
              width={30}
              height={24}
              stroke={iconStroke}
              onClick={() => handleAction('back')}
              className={`${loading ? 'pointer-events-none' : ''}`}
            />
          </Button>
        ) : (
          <div></div>
        )}

        {last ? (
          <Button
            action={() => handleAction('forward')}
            className="mr-0 !h-8 w-auto !max-w-[40%] rounded-md"
            disabled={disableNext}
          >
            {t('tutorial.button.end')}
          </Button>
        ) : (
          <Button
            action={() => handleAction('forward')}
            className="mr-0 !h-8 !w-8  rounded-md"
            disabled={disableNext}
          >
            <IconBack
              width={30}
              height={30}
              stroke={iconStroke}
              style={{ transform: 'rotate(180deg)' }}
              className={`${loading ? 'pointer-events-none' : ''}`}
            />
          </Button>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Tutorial;
