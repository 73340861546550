import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModal, useModalProps, useModalState } from 'react-simple-modal-provider';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { decryptWallet } from '../../imports/utils/auth';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { UserState, signIn } from '../../redux/user/user.slice';
import Button from '../button/Button';
import Form from '../form/Form';
import Input from '../input/Input';
import ModalLayout from '../layouts/ModalLayout';
interface UnlockModalProps {
  children?: ReactNode;
}

const ModalBody = ({ setOpen }: any) => {
  const { goBack } = useModalProps('UnlockModal');
  const { open } = useModal('DriveRecoverModal');

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { wallet, ssoWallet } = useAppSelector(({ user }: { user: UserState }) => user);

  const form = {
    initialValues: {
      pin: '',
    },
    validationSchema: yup.object({
      pin: yup
        .string()
        .min(6, t('form_validation.pin_length', { minChars: 6 }))
        .test('pin_check', t('form_validation.pin_must_be_digit'), (str) =>
          /^[0-9]+$/.test(str || '')
        )
        .required(t('form_validation.required')),
    }),
  };
  const submitPin = async ({ pin }: { pin: string }) => {
    // wallet is a string here, but without toString() type breaks
    // we can fix, but we break every deconstruct in the app
    // wallet my be the encrypted wallet, since after SSO I have password
    // to decrypt it. So in the modal I ask for password, decrypt it
    // and complete Sign in
    let userWallet;

    try {
      userWallet = decryptWallet(ssoWallet || '', pin);
    } catch (e) {
      toast.error(t('account.wallet_messages.decrypt_error') as string);
    }
    if (userWallet) {
      dispatch(
        signIn({
          profile: {
            wallet: {
              address: userWallet?.address,
              privateKey: userWallet?.privateKey,
              mnemonic: userWallet?.mnemonic.phrase,
            },
            needAction: '',
          },
        })
      );
      setOpen(false);
    }
  };
  return (
    <ModalLayout>
      <div className="w-full max-w-[600px] p-7">
        <Form
          initialValues={form.initialValues}
          validationSchema={form.validationSchema}
          mode={'all'}
        >
          {({ register, handleSubmit, errors, isValid, watch }) => {
            const values = watch();

            return (
              <>
                <p className="mb-4 text-body-medium-24">{t('unlockModal.title')}</p>

                <div className="mt-7 flex flex-col ">
                  <Input
                    label={t('unlockModal.pin')}
                    placeholder={t('unlockModal.form_placeholders.pin')}
                    error={errors['pin']?.message}
                    password
                    {...register('pin')}
                    forceLight={true}
                    inputMode="numeric"
                  />
                  <div className={'mt-2 flex justify-end'}>
                    <button
                      type="button"
                      onClick={() => {
                        // setOpen(false);
                        open({});
                      }}
                      className="text-body-medium-16 underline"
                    >
                      {t('unlockModal.forgot_pin') as string}
                    </button>
                  </div>
                  <Button
                    type="primary"
                    className=" mt-14"
                    // disabled={!isValid && !isLoading}
                    action={handleSubmit(submitPin)}
                  >
                    {t('ssoSignupModal.confirm') as string}
                  </Button>
                  {goBack && (
                    <Button
                      type="secondary"
                      className="mt-4 "
                      // disabled={!isValid && !isLoading}
                      action={() => {
                        goBack();
                        setOpen(false);
                      }}
                    >
                      {t('ssoSignupModal.goBack') as string}
                    </Button>
                  )}
                </div>
              </>
            );
          }}
        </Form>
      </div>
    </ModalLayout>
  );
};

const UnlockModal = ({ children }: UnlockModalProps) => {
  const [isOpen, setOpen] = useModalState();

  return (
    <Modal
      id="UnlockModal"
      consumer={children}
      isOpen={isOpen}
      setOpen={setOpen}
      allowClickOutside={false}
    >
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default UnlockModal;
