// ThemeContext.tsx
import { ReactNode, createContext, useContext, useState } from 'react';
import LogoDark from '../assets/images/logoDark.svg';
import LogoLight from '../assets/images/logoLight.svg';
import { COLORS, THEMES } from '../imports/constants';

export type ThemeKeys = 'dark' | 'light' | 'system';

interface ThemeContextProps {
  theme: 'dark' | 'light';
  themeKey: ThemeKeys;
  changeTheme: (themeKey: ThemeKeys) => void;
  isThemeDark: () => boolean;
  isThemeLight: () => boolean;
  getLogoImg: () => string;
  getBaseIconColor: () => string;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

const getThemeByKey = (themeKey: ThemeKeys): 'dark' | 'light' => {
  switch (themeKey) {
    case 'dark':
      return 'dark';
    case 'light':
      return 'light';
    case 'system':
      const userPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      return userPrefersDark ? 'dark' : 'light';
    default:
      return 'dark';
  }
};

const getInitialTheme = (): 'dark' | 'light' => {
  const savedTheme = localStorage.getItem('theme') as ThemeKeys;

  return getThemeByKey(savedTheme);
};

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setTheme] = useState<'dark' | 'light'>(getInitialTheme);
  const themeKey = localStorage.getItem('theme') as ThemeKeys;

  document.documentElement.classList.toggle('dark', theme === THEMES.DARK);

  const isThemeLight = (): boolean => theme === THEMES.LIGHT;

  const isThemeDark = (): boolean => theme === THEMES.DARK;

  const changeTheme = (themeKey: 'dark' | 'light' | 'system'): void => {
    const theme = getThemeByKey(themeKey);

    setTheme(theme);
    localStorage.setItem('theme', themeKey);
  };

  // Get the right logo according to the theme
  const getLogoImg = (): string => (isThemeLight() ? LogoDark : LogoLight);

  // Get the right hexadecimal color for simple icon according to the theme
  const getBaseIconColor = (): string => (isThemeDark() ? COLORS.white : COLORS.black);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        themeKey,
        changeTheme,
        isThemeDark,
        isThemeLight,
        getLogoImg,
        getBaseIconColor,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextProps => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
