import { SVGProps } from 'react';

const IconLeftArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Layer_1"
    enableBackground="new 0 0 24 24"
    height={25}
    viewBox="0 0 24 24"
    width={25}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill={props.fill || 'white'}
      d="m20.9 11.6c0-.1-.1-.2-.2-.3l-4-4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l2.3 2.3h-13.6c-.6 0-1 .4-1 1s.4 1 1 1h13.6l-2.3 2.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l4-4c.1-.1.2-.2.2-.3s.1-.2.1-.4c0-.1 0-.3-.1-.4z"
    />
  </svg>
);

export default IconLeftArrow;
