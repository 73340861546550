import { SVGProps } from 'react';

const IconSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.5938 22.188C19.0303 22.188 23.4375 17.5709 23.4375 11.8755C23.4375 6.18005 19.0303 1.56299 13.5938 1.56299C8.1572 1.56299 3.75 6.18005 3.75 11.8755C3.75 17.5709 8.1572 22.188 13.5938 22.188Z"
      stroke={props.stroke || 'white'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5547 23.2114L26.25 28.9067"
      stroke={props.stroke || 'white'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconSearch;
