import { SVGProps } from 'react';

const IconTransfer = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_969_10743)">
      <path
        d="M7.5 15.2159H19.5C19.6989 15.2159 19.8897 15.1369 20.0303 14.9963C20.171 14.8556 20.25 14.6649 20.25 14.4659V4.71594C20.25 4.51703 20.171 4.32626 20.0303 4.18561C19.8897 4.04496 19.6989 3.96594 19.5 3.96594H9C8.80109 3.96594 8.61032 4.04496 8.46967 4.18561C8.32902 4.32626 8.25 4.51703 8.25 4.71594V5.46594"
        stroke={props.fill}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 12.9659L7.5 15.2159L9.75 17.4659"
        stroke={props.fill}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 9.21594H4.5C4.30109 9.21594 4.11032 9.29496 3.96967 9.43561C3.82902 9.57626 3.75 9.76703 3.75 9.96594V19.7159C3.75 19.9149 3.82902 20.1056 3.96967 20.2463C4.11032 20.3869 4.30109 20.4659 4.5 20.4659H15C15.1989 20.4659 15.3897 20.3869 15.5303 20.2463C15.671 20.1056 15.75 19.9149 15.75 19.7159V18.9659"
        stroke={props.fill}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 11.4659L16.5 9.21594L14.25 6.96594"
        stroke={props.fill}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_969_10743">
        <rect width={24} height={24} fill="white" transform="translate(0 0.215942)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconTransfer;
